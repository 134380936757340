import React, { lazy } from "react";

import { SystemError } from "@pages/errors";
import ServiceApplyRouteGuard from "@routes/guards/ServiceApplyRouteGuard";

const Intro = lazy(() => import("@pages/serviceApply/Intro"));
const SalesType = lazy(() => import("@pages/serviceApply/SalesType"));
const Auth = lazy(() => import("@pages/serviceApply/Auth"));
const ServiceAgreement = lazy(
  () => import("@pages/serviceApply/ServiceAgreement"),
);
const CheckLicenseInfo = lazy(
  () => import("@pages/serviceApply/LicenseVerify/CheckLicenseInfo"),
);
const UploadLicenseImage = lazy(
  () => import("@pages/serviceApply/LicenseVerify/UploadLicenseImage"),
);
const VerifyLicenseInfo = lazy(
  () => import("@pages/serviceApply/LicenseVerify/VerifyLicenseInfo"),
);
const VerifyLicenseResult = lazy(
  () => import("@pages/serviceApply/LicenseVerify/Result"),
);
const Submit = lazy(() => import("@pages/serviceApply/Submit"));

export const serviceApplyRouters = {
  path: "/service-apply",
  errorElement: <SystemError />,
  element: <ServiceApplyRouteGuard />,
  children: [
    {
      index: true,
      Component: Intro,
    },
    {
      path: "intro",
      Component: Intro,
    },
    {
      path: "sales-type",
      Component: SalesType,
    },
    {
      path: "auth",
      Component: Auth,
    },
    {
      path: "license-verify",
      children: [
        { index: true, Component: UploadLicenseImage },
        { path: "upload", Component: UploadLicenseImage },
        { path: "check", Component: CheckLicenseInfo },
        { path: "verify", Component: VerifyLicenseInfo },
        { path: "result", Component: VerifyLicenseResult },
      ],
    },
    {
      path: "service-agreement",
      Component: ServiceAgreement,
    },
    {
      path: "screening",
      children: [
        {
          path: "restart",
        },
        {
          path: "incomplete",
        },
        {
          path: "expired",
        },
        {
          path: "admin-rejected",
        },
        {
          path: "credit-rejected",
        },
        {
          path: "retry",
        },
      ],
    },
    // --------- 대부 ---------
    {
      path: "gateway",
    },
    {
      path: "credit-agreement",
    },
    {
      path: "identification",
    },
    {
      path: "deposit-account",
    },
    {
      path: "withdrawal-account-guide",
    },
    {
      path: "withdrawal-account",
    },
    {
      path: "delivery-account",
    },
    {
      path: "delivery-agency",
    },
    {
      path: "card-terminal",
    },
    {
      path: "terms-agreement",
    },
    {
      path: "submit",
      Component: Submit,
    },
  ],
};
