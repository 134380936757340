import classNames from "classnames";
import { forwardRef, ForwardedRef } from "react";
import React from "react";
import "@ui/styles/index.scss";
import styles from "./IconButton.module.scss";
import { IconButtonProps } from "./IconButton.types";
import Icon from "@ui/components/atoms/Icon/Icon";
import { getIconSize } from "@ui/components/atoms/IconButton/utils";

/**
 * `IconButton` 은 Icon 을 버튼으로 사용하는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <IconButton
 *    icon={CheckIcon}
 *    color="content-secondary"
 *    size="md"
 *    onClick={onClick}
 * />
 * ```
 */
export const IconButton = forwardRef<SVGSVGElement, IconButtonProps>(
  function IconButton(
    {
      className,
      children,
      as,
      icon,
      size = "md",
      color,
      disabled,
      onClick,
      ...rest
    }: IconButtonProps,
    forwardedRef: ForwardedRef<SVGSVGElement>,
  ) {
    const BaseComponent = as ?? "button";
    const iconSize = getIconSize(size);

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        type={"button"}
        className={classNames(
          styles.IconButton,
          styles[`size-${size}`],
          disabled && styles[`disabled`],
          "earlybird-icon-button",
          className,
        )}
        onClick={onClick}
        role="button"
      >
        <Icon icon={icon} color={color} size={iconSize} />
      </BaseComponent>
    );
  },
);

export default IconButton;
