import { toast, ToastOptions, IconProps, Slide } from "react-toastify";
import React from "react";
import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";
import { StyledToastContainer } from "@components/atoms/Toast/index.styled";
import { ColorsTypes } from "@/@types/ThemesType";
import { ToastOptionProps, ToastTags } from "@components/atoms/Toast/type";

export interface ToastProps {
  className?: string;
}

/** 토스트 옵션 */
const option: ToastOptions<ToastOptionProps> = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
  theme: "colored",
};

/** Toast 커스텀 아이콘 */
export const CustomIcon = (props: IconProps) => {
  switch (props.type) {
    case "success":
      return (
        <Icon tag={IconTags.MESSAGE} color={ColorsTypes.CONTENT_ON_COLOR} />
      );
    case "error":
      return (
        <Icon
          tag={IconTags.ERROR_MESSAGE}
          color={ColorsTypes.CONTENT_NEGATIVE}
        />
      );
    default:
      return (
        <Icon tag={IconTags.MESSAGE} color={ColorsTypes.CONTENT_ON_COLOR} />
      );
  }
};

/** Toast Container */
export const Toast: React.FC<ToastProps> = ({ className }) => {
  return (
    <StyledToastContainer
      className={[
        ...(Array.isArray(className) ? className : [className]),
        "earlypay-toast-container",
      ].join(" ")}
      transition={Slide}
      newestOnTop
      autoClose={0}
      icon={CustomIcon}
    ></StyledToastContainer>
  );
};

/** Toast 호출 함수 */
/** 1. 호출 함수의 props 중 tag 값이 없거나 TOAST 일 경우, Toast 컴포넌트를 return 합니다. */
/** 2. 호출 함수의 props 중 tag 값이 SNACK_BAR 일 경우, SnackBar 컴포넌트를 return 합니다. */
const successToast = (text: string, tag?: ToastTags) =>
  toast.success(text, {
    ...option,
    closeButton: tag === ToastTags.SNACK_BAR,
    autoClose: tag === ToastTags.SNACK_BAR ? 0 : 2000,
  });
const errorToast = (text: string, tag?: ToastTags) =>
  toast.error(text, {
    ...option,
    closeButton: tag === ToastTags.SNACK_BAR,
    autoClose: tag === ToastTags.SNACK_BAR ? 0 : 2000,
  });

export { successToast, errorToast };
export default Toast;
