import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { IconSizes, IconTags } from "../Icon/type";
import { ColorsTypes } from "@/@types/ThemesType";

interface CheckBoxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange }) => {
  const handleClickCheckBox = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(!checked);
  };

  return (
    <Wrapper onClick={handleClickCheckBox}>
      <ActiveCheckBox
        tag={IconTags.SQUARE_CHECK_SOLID}
        size={IconSizes.MD}
        color={ColorsTypes.CONTENT_HIGHLIGHT}
        checked={checked}
      />
      <InActiveCheckBox
        checked={checked}
        tag={IconTags.SQUARE}
        size={IconSizes.MD}
      />
    </Wrapper>
  );
};

export default CheckBox;

const Wrapper = styled.button`
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const ActiveCheckBox = styled(Icon)`
  opacity: ${({ checked }: { checked: boolean }) => (checked ? 1 : 0)};
  transition: opacity 0.3s ease;
  position: absolute;
`;

const InActiveCheckBox = styled(Icon)`
  opacity: ${({ checked }: { checked: boolean }) => (checked ? 0 : 1)};
  transition: opacity 0.3s ease;
  position: absolute;
`;
