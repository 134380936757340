import { getPixelValue } from "@earlypay/shared/src/utils/types";
import "@ui/styles/index.scss";
import {
  changeColorToClassName,
  changeRadiusToClassName,
} from "@ui/utils/className";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./Box.module.scss";
import { BoxProps } from "./Box.types";

/**
 * `Box` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Box
 *   width={50}
 *   height={50}
 *   padding={20}
 *   margin={12}
 *   bg={"bg-cta-primary"}
 * >
 *   <Text>{ ... }</Text>
 * </Box>
 * ```
 */
export const Box = forwardRef<HTMLElement, BoxProps>(function Box(
  {
    className,
    children,
    as,
    display = "block",
    width = "fit-content",
    height = "fit-content",
    margin,
    padding,
    bg,
    center = false,
    border = false,
    radius,
    shadow,
    style,
    ...rest
  }: BoxProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";
  const p = getPixelValue(padding);
  const m = getPixelValue(margin);
  const w = getPixelValue(width);
  const h = getPixelValue(height);

  const currentStyle = {
    width: w,
    height: h,
    margin: m,
    padding: p,
  };

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.Box,
        center && styles.center,
        display && styles[`display-${display}`],
        shadow && styles[`shadow-${shadow}`],
        radius && changeRadiusToClassName(radius),
        bg && changeColorToClassName(bg),
        "earlybird-box",
        className,
      )}
      style={{ ...style, ...currentStyle }}
    >
      {children}
    </BaseComponent>
  );
});

export default Box;
