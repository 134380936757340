import {
  PlatformCode,
  PlatformCodeTypes,
  PlatformNames,
} from "@earlypay/shared";
import styled from "styled-components";

import { Box, HStack, Text } from "@earlybird/ui";

export interface PlatformChipListProps {
  handleClickChip: (selectedList: string[]) => void;
  selectedPlatformList: string[];
}

export const PlatformChipList = ({
  handleClickChip,
  selectedPlatformList,
}: PlatformChipListProps) => {
  const handleClickPlatformChip = (platform: PlatformCodeTypes) => {
    if (
      selectedPlatformList.length <= 1 &&
      selectedPlatformList.includes(platform)
    )
      return;

    const updateList = selectedPlatformList.includes(platform)
      ? selectedPlatformList.filter(item => item !== platform)
      : [...selectedPlatformList, platform];

    if (updateList.includes("ALL")) {
      return handleClickChip(
        Object.keys(PlatformCode).filter(item => item !== platform),
      );
    }
    handleClickChip(updateList);
  };

  return (
    <HStack center spacing={3}>
      {Object.keys(PlatformCode).map((item: PlatformCodeTypes) => {
        const isSelected =
          selectedPlatformList.includes(item) ||
          selectedPlatformList.includes("ALL");

        return (
          <ChipWrapper
            key={item}
            type={"button"}
            onClick={() => handleClickPlatformChip(item)}
          >
            <Box
              bg={isSelected ? "bg-informative-light" : "bg-secondary"}
              radius={"circle"}
              padding={"6px 12px"}
              center
            >
              <Text
                typo={"body-3"}
                color={isSelected ? "content-highlight" : "content-tertiary"}
                bold
              >
                {PlatformNames[item]}
              </Text>
            </Box>
          </ChipWrapper>
        );
      })}
    </HStack>
  );
};

const ChipWrapper = styled.button`
  border: none;
  background: transparent;
`;
