import { findKeyByValue } from "@earlypay/shared/src/utils/types";
import { BankCode, BankCodeTypes } from "@earlypay/shared/typings";
import { upperSnakeCaseToKebab } from "@ui/utils/namingCase";

export const getBankImgName = (code: BankCodeTypes) => {
  const name = findKeyByValue(BankCode, code);

  if (!upperSnakeCaseToKebab(name)) {
    return null;
  }

  return `/brand/logo-bank-${upperSnakeCaseToKebab(name)}.svg`;
};
