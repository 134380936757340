/** 개인정보 처리 방침 */
export const PRIVACY_POLICY_TERM =
  "https://app.catchsecu.com/document/P/702da9c1780f2dc";

export const SERVICE_TERM = "https://earlypay.kr/terms/A000";

/** 얼리페이로 지급된 누적 금액 */
export const ACCUMULATED_AMOUNT_MONEY = 2600;

export const FAQ_TITLE_LIST = ["정산", "서비스", "회원가입"];

export const EARLYPAY_REVIEW_YOUTUBE =
  "https://www.youtube.com/watch?v=71n_ANTSQMo";

export const KAKAO_BG_COLOR = "#FEE500";

/** 얼리페이 최대 넓이 (랜딩페이지 제외) */
export const SCREEN_MAX_WIDTH = 500;

export const WEEKDAY = ["일", "월", "화", "수", "목", "금", "토"];

export const HEADER_HEIGHT = 56;

export const KAKAO_AUTH_URL = "https://kauth.kakao.com/oauth/authorize";

export const EARLYPAY_SERVICE_GUIDE = "https://bit.ly/3M0PyWk";
