import {
  StackAlignTypes,
  StackDirectionsTypes,
  StackJustifyTypes,
} from "./type";

export const changeDirectionToStyles = (
  direction: StackDirectionsTypes | string,
): string => {
  switch (direction) {
    case StackDirectionsTypes.ROW:
      return "row";
    case StackDirectionsTypes.COL:
      return "column";
    case StackDirectionsTypes.ROW_REVERSE:
      return "row-reverse";
    default:
      return "row";
  }
};

export const changeAlignToStyles = (
  align: StackAlignTypes | string,
): string => {
  switch (align) {
    case StackAlignTypes.START:
      return "flex-start";
    case StackAlignTypes.END:
      return "flex-end";
    case StackAlignTypes.CENTER:
      return "center";
    case StackAlignTypes.BETWEEN:
      return "space-between";
    case StackAlignTypes.AROUND:
      return "space-around";
    default:
      return "flex-start";
  }
};

export const changeJustifyToStyles = (
  justify: StackJustifyTypes | string,
): string => {
  switch (justify) {
    case StackJustifyTypes.START:
      return "flex-start";
    case StackJustifyTypes.END:
      return "flex-end";
    case StackJustifyTypes.CENTER:
      return "center";
    case StackJustifyTypes.BETWEEN:
      return "space-between";
    case StackJustifyTypes.STRETCH:
      return "stretch";
    default:
      return "flex-start";
  }
};
