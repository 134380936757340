import React, { ReactElement } from "react";
import { StyledText } from "./index.styled";
import themes from "@styles/themes";
import { ColorsTypes, FontTypes, FontWeightTypes } from "@/@types/ThemesType";
import {
  changeStringToWeight,
  changeTagToAs,
} from "@components/atoms/Text/utils";

export interface TextProps {
  className?: string | string[];
  color?: ColorsTypes;
  tag?: FontTypes;
  weight?: FontWeightTypes;
  center?: boolean;
  as?: string;
  underlined?: boolean;
  styles?: React.CSSProperties;
  children: string | ReactElement | React.ReactNode;
  bold?: boolean;
  light?: boolean;
  link?: boolean;
  li?: boolean;
}

const Text = React.forwardRef<HTMLButtonElement, TextProps>(
  (
    {
      className,
      children = "레이블",
      tag = FontTypes.BODY_1,
      weight = FontWeightTypes.REGULAR,
      color = ColorsTypes.CONTENT_PRIMARY,
      underlined = false,
      center = false,
      as,
      link,
      bold,
      light,
      styles,
      li,
      ...otherProps
    },
    ref,
  ) => {
    const fontWeight = bold
      ? changeStringToWeight("bold")
      : light
      ? changeStringToWeight("light")
      : weight;
    const fontStyles = themes.typography[tag][fontWeight];
    const colorStyles = `${themes.colors[color]}`;

    return (
      <StyledText
        className={[
          ...(Array.isArray(className) ? className : [className]),
          "earlypay-text",
        ].join(" ")}
        ref={ref}
        center={center}
        underlined={underlined}
        link={link}
        color={colorStyles}
        style={{ ...fontStyles, ...styles }}
        as={as ? as : li ? "li" : changeTagToAs(tag)}
        li={li}
        {...otherProps}
      >
        {children}
      </StyledText>
    );
  },
);

Text.displayName = "Text";

export default Text;
