// Semantic Color [ LIGHT ]
// Semantic Color 는 primitive Color 를 참고하여 만들어진 UI 컴포넌트에 사용하는 의미론적 컬러 시스템입니다.

import { primitivesColors } from "./primitivesColors";

export const lightColors = {
  /** Core */
  BG_PRIMARY: primitivesColors["NEUTRAL_WHITE"],
  BG_SECONDARY: primitivesColors["NEUTRAL_10"],
  BG_TERTIARY: primitivesColors["NEUTRAL_20"],
  BG_PRIMARY_INVERSE: primitivesColors["NEUTRAL_BLACK"],
  BG_SECONDARY_INVERSE: primitivesColors["NEUTRAL_80"],
  BG_TERTIARY_INVERSE: primitivesColors["NEUTRAL_80"],

  /** Core Extention */
  BG_HIGHLIGHT: primitivesColors["EARLY_BLUE_50"],
  BG_NEGATIVE: primitivesColors["RED_50"],
  BG_WARNING: primitivesColors["YELLOW_50"],
  BG_POSITIVE: primitivesColors["GREEN_50"],
  BG_DISABLED: primitivesColors["NEUTRAL_25"],
  BG_HIGHLIGHT_LIGHT: primitivesColors["EARLY_BLUE_10"],
  BG_NEGATIVE_LIGHT: primitivesColors["RED_10"],
  BG_WARNING_LIGHT: primitivesColors["YELLOW_10"],
  BG_POSITIVE_LIGHT: primitivesColors["GREEN_10"],
  BG_OVERLAY: primitivesColors["NEUTRAL_BLACK_ALPHA_50"],
  BG_ALWAYS_BLACK: primitivesColors["NEUTRAL_BLACK"],
  BG_ALWAYS_WHITE: primitivesColors["NEUTRAL_WHITE"],

  /** Core */
  CONTENT_PRIMARY: primitivesColors["NEUTRAL_BLACK"],
  CONTENT_SECONDARY: primitivesColors["NEUTRAL_70"],
  CONTENT_TERTIARY: primitivesColors["NEUTRAL_50"],

  /** Core Extention */
  CONTENT_HIGHLIGHT: primitivesColors["EARLY_BLUE_50"],
  CONTENT_ON_COLOR: primitivesColors["NEUTRAL_WHITE"],
  CONTENT_NEGATIVE: primitivesColors["RED_50"],
  CONTENT_WARNING: primitivesColors["YELLOW_50"],
  CONTENT_POSITIVE: primitivesColors["GREEN_50"],
  CONTENT_DISABLED: primitivesColors["NEUTRAL_40"],

  /** Core */
  BORDER_DEFAULT: primitivesColors["NEUTRAL_20"],
  BORDER_SELECTED: primitivesColors["NEUTRAL_90"],
  BORDER_DEFAULT_INVERSE: primitivesColors["NEUTRAL_70"],
  BORDER_SELECTED_INVERSE: primitivesColors["NEUTRAL_20"],

  /** Core Extention */
  BORDER_HIGHLIGHT: primitivesColors["EARLY_BLUE_50"],
  BORDER_NEGATIVE: primitivesColors["RED_50"],
  BORDER_WARNING: primitivesColors["YELLOW_50"],
  BORDER_POSITIVE: primitivesColors["GREEN_50"],
} as const;
