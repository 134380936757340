import React from "react";

import Terms from "@pages/Terms";
import IntegratedTerm from "@pages/Terms/IntegratedTerm";
import Policy from "@pages/Terms/Policy";
import { Admin } from "@pages/admin";
import { Forbidden, NotFound, SystemError } from "@pages/errors";

import PublicRouteGuard from "../guards/PublicRouteGuard";

export const publicRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <PublicRouteGuard />,
  children: [
    {
      path: "/terms",
      children: [
        // ---------- 레거시 ----------
        {
          path: ":policy",
          Component: Policy,
        },
        {
          path: "A010",
          Component: IntegratedTerm,
        },
        // ---------- 최신 ----------
        {
          path: "EP02",
          Component: Terms,
        },
        {
          path: "EP03",
          Component: Terms,
        },
      ],
    },
    {
      path: "/admin",
      Component: Admin,
    },
    // error
    { path: "404", Component: NotFound },
    { path: "403", Component: Forbidden },
    { path: "500", Component: SystemError },
    { path: "*", Component: NotFound },
  ],
};
