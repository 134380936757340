import { useEffect } from "react";

import {
  PlatformCode,
  ServerError,
  getErrorResponse,
  getFormattedDateWithDash,
  getMonth,
  getYear,
  getYesterday,
} from "@earlypay/shared";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { DatePickerHeader, VStack, useToast } from "@earlybird/ui";

import {
  useFetchDailySalesDetail,
  useFetchDailySalesSummary,
} from "@/apis/hooks";
import SalesDetailList from "@/components/features/calendar/SalesDetailList";
import { SalesDetailOverview } from "@/components/features/calendar/SalesDetailOverview";
import { Header, Meta } from "@/components/layouts";
import { useCalendar } from "@/hooks/useCalendar";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { applicationState } from "@/recoil/application/atom";

export const Detail = () => {
  const application = useRecoilValue(applicationState);
  const { addToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const newSearchParams = new URLSearchParams(location.search);
  const { selectedDate, startDate, selectedPlatform } = useCalendar();
  const selectedPlatformList = selectedPlatform.toLocaleUpperCase().split(",");

  const platformParams =
    selectedPlatformList[0] === "ALL"
      ? Object.keys(PlatformCode)
      : selectedPlatformList;

  const { data: salesSummary, isFetching: isFetchingSalesSummary } =
    useFetchDailySalesSummary(application.store.id, {
      platform: platformParams,
      date: getFormattedDateWithDash(selectedDate),
    });

  /** Infinite Scroll 구현을 통해 건별 매출 데이터를 일정 개수씩 가져옵니다. */
  const {
    data: dailySalesDetailData,
    isLoading: isDailySalesDetailLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    cancelCount,
    approvalCount,
  } = useFetchDailySalesDetail(application.store.id, {
    date: getFormattedDateWithDash(selectedDate),
    platform: platformParams,
  });

  /** target observer 요소를 감지하여 storeList 의 다음 페이지 업데이트 */
  const { setTarget } = useInfiniteScroll({
    hasNextPage,
    fetchNextPage,
  });

  const handleNavigateDate = (date: Date) => {
    newSearchParams.set("selectedDate", getFormattedDateWithDash(date));
    navigate(`${location.pathname}?${newSearchParams}`, {
      state: location.state?.from,
    });
  };

  const handleGoToBack = () => {
    const prevPath = location.state?.from;

    if (!prevPath) {
      newSearchParams.set("currentYear", `${getYear(selectedDate)}`);
      newSearchParams.set("currentMonth", `${getMonth(selectedDate)}`);
      newSearchParams.delete("platform");
      return navigate(`/calendar?${newSearchParams}`);
    }

    return navigate(prevPath);
  };

  const handleClickPlatformFilter = (selectedList: string[]) => {
    newSearchParams.set("platform", selectedList.join(",").toLowerCase());
    navigate(`${location.pathname}?${newSearchParams}`, {
      replace: true,
      state: { from: location.state?.from },
    });
  };

  useEffect(() => {
    if (isError) {
      const errorResponse = getErrorResponse(error as ServerError);
      const code =
        errorResponse && "code" in errorResponse && errorResponse.code;
      code === "invalid-date-range" &&
        addToast("조회 가능한 일자가 아니에요.", "negative");
    }
  }, [isError]);

  return (
    <>
      <Meta title="얼리페이 | 캘린더" name={"캘린더 상세"} />

      <Header
        hasChat={false}
        hasRightButton={false}
        handleClickLeftButton={handleGoToBack}
        contents={
          <DatePickerHeader
            displayMode={"day"}
            currentDate={selectedDate}
            handleNavigateDate={handleNavigateDate}
            startDate={startDate}
            endDate={getYesterday()}
          />
        }
      />

      <VStack>
        {/** 매출 요약 정보 */}
        <SalesDetailOverview
          isLoading={isFetchingSalesSummary}
          salesList={salesSummary}
          selectedPlatformList={selectedPlatformList}
          handleClickPlatformFilter={handleClickPlatformFilter}
        />

        <Divider />

        {/** 매출 상세 정보 */}
        <Wrapper>
          <SalesDetailList
            isLoading={isDailySalesDetailLoading}
            salesList={dailySalesDetailData}
            cancelCount={cancelCount}
            approvalCount={approvalCount}
          />

          {/** 스크롤이 닿는 영역 */}
          <div ref={setTarget} style={{ height: 1, width: "100%" }} />
        </Wrapper>
      </VStack>
    </>
  );
};

export default Detail;

const Divider = styled.hr`
  background-color: var(--earlybird-color-border-tertiary);
  width: 100%;
  height: 16px;
  border: none;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`;
