import { useQuery } from "@tanstack/react-query";
import { getApplication } from "@apis/endpoints/applications";
import { applicationsQueryKeys } from "./queryKeys";
import { getCookie } from "@utils/cookie";
import { useRecoilValue } from "recoil";
import { applicationState } from "@/recoil/application/atom";

export const useFetchApplication = () => {
  const accessToken = getCookie("earlypay-token");
  const defaultValue = useRecoilValue(applicationState);

  return useQuery({
    queryKey: applicationsQueryKeys.fetchApplication().queryKey,
    queryFn: () => getApplication(),
    enabled: !!accessToken,
    placeholderData: defaultValue,
    retry: 1,
  });
};
