import { ButtonSizes, ButtonTypes } from "./type";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import themes from "@styles/themes";

export const changeTagToStyleObject = (
  tag: ButtonTypes,
  disabled?: boolean,
): {
  labelColor: ColorsTypes;
  backgroundColor: string;
  border: string;
} => {
  if (disabled) {
    return {
      labelColor: ColorsTypes.CONTENT_DISABLED,
      backgroundColor: themes.colors.BG_DISABLED,
      border: `solid 1px ${themes.colors.BG_DISABLED}`,
    };
  }

  switch (tag) {
    case ButtonTypes.PRIMARY:
      return {
        labelColor: ColorsTypes.CONTENT_ON_COLOR,
        backgroundColor: themes.colors.BG_HIGHLIGHT,
        border: `solid 1px ${themes.colors.BG_HIGHLIGHT}`,
      };
    case ButtonTypes.SECONDARY:
      return {
        labelColor: ColorsTypes.CONTENT_HIGHLIGHT,
        backgroundColor: themes.colors.BG_HIGHLIGHT_LIGHT,
        border: `solid 1px ${themes.colors.BG_HIGHLIGHT_LIGHT}`,
      };
    case ButtonTypes.OUTLINED:
      return {
        labelColor: ColorsTypes.CONTENT_SECONDARY,
        backgroundColor: themes.colors.BG_PRIMARY,
        border: `solid 1.4px ${themes.colors.BORDER_DEFAULT}`,
      };
    case ButtonTypes.SELECTED:
      return {
        labelColor: ColorsTypes.CONTENT_PRIMARY,
        backgroundColor: themes.colors.BG_PRIMARY,
        border: `solid 1px ${themes.colors.BORDER_SELECTED}`,
      };
    case ButtonTypes.GHOST:
      return {
        labelColor: ColorsTypes.CONTENT_TERTIARY,
        backgroundColor: "rgba( 255, 255, 255, 0 )",
        border: `solid 1px rgba( 255, 255, 255, 0 )`,
      };
    case ButtonTypes.NEGATIVE:
      return {
        labelColor: ColorsTypes.BG_NEGATIVE,
        backgroundColor: themes.colors.CONTENT_ON_COLOR,
        border: `solid 1px ${themes.colors.CONTENT_ON_COLOR}`,
      };
    case ButtonTypes.DISABLED:
      return {
        labelColor: ColorsTypes.CONTENT_DISABLED,
        backgroundColor: themes.colors.BG_DISABLED,
        border: `solid 1px ${themes.colors.BG_DISABLED}`,
      };
    case ButtonTypes.SOLID:
      return {
        labelColor: ColorsTypes.CONTENT_SECONDARY,
        backgroundColor: themes.colors.BG_SECONDARY,
        border: `solid 1px ${themes.colors.BG_SECONDARY}`,
      };
    default:
      return {
        labelColor: ColorsTypes.CONTENT_PRIMARY,
        backgroundColor: themes.colors.BG_PRIMARY,
        border: `solid 1px ${ColorsTypes.BG_PRIMARY}`,
      };
  }
};

export const changeSizeToStyleObject = (
  size: ButtonSizes,
): {
  rounded: number;
  labelTag: FontTypes;
  paddingVertical: number;
} => {
  switch (size) {
    case ButtonSizes.XXL:
      return {
        rounded: 12,
        labelTag: FontTypes.SUBTITLE_2,
        paddingVertical: 14,
      };
    case ButtonSizes.XL:
      return {
        rounded: 10,
        labelTag: FontTypes.BODY_1,
        paddingVertical: 10,
      };
    case ButtonSizes.LG:
      return {
        rounded: 9,
        labelTag: FontTypes.BODY_1,
        paddingVertical: 8,
      };
    case ButtonSizes.MD:
      return {
        rounded: 8,
        labelTag: FontTypes.BODY_2,
        paddingVertical: 8,
      };
    case ButtonSizes.SM:
      return {
        rounded: 6,
        labelTag: FontTypes.BODY_3,
        paddingVertical: 8,
      };
    case ButtonSizes.XS:
      return {
        rounded: 8,
        labelTag: FontTypes.BODY_3,
        paddingVertical: 12,
      };
    default:
      return {
        rounded: 12,
        labelTag: FontTypes.SUBTITLE_2,
        paddingVertical: 14,
      };
  }
};
