import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ChevronRightIcon, InvoiceSolidIcon } from "@earlybird/icons";
import { Box, HStack, Icon, Spinner, Text, VStack } from "@earlybird/ui";

import { useFetchStoreDailySales } from "@/apis/hooks";

interface SaleStats {
  amountSales: number;
  numSales: number;
  date: string;
  perPlatforms: {
    amountSales: number;
    platform: "CARD" | "BAEMIN" | "YOGIYO" | "COUPANG" | "ETC";
    numSales: number;
    delta: {
      amount_sales: number;
      numSales: number;
    };
  }[];
}

// interface StoreDaily {
//   summary: {
//     amountSales: number;
//     numSales: number;
//   };
//   salesStats: SaleStats[];
// }

interface StoreStatusType {
  history: {
    amount: number;
    date: string;
    isDeposited: boolean;
  }[];
  latest: {
    results: {
      amount: number;
      date: string;
      isDeposited: boolean;
    }[];
    status: "CHECKING_SALES" | "WAITING_FOR_FIRST_DEPOSIT" | "SENT";
  };
  scrapingProgress: {
    baemin: boolean;
    card: boolean;
    coupang: boolean;
    yogiyo: boolean;
  };
}

const PreviousDateSales = ({
  storeId,
  storeStatus,
  storeStatusLoading,
}: {
  storeId: number;
  storeStatus: StoreStatusType;
  storeStatusLoading: boolean;
}) => {
  const navigate = useNavigate();

  const isBeforeNoon = new Date().getHours() < 12;

  const findDate = ({ dayOffset }: { dayOffset: number }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    return paramDate;
  };

  const startDate = findDate({ dayOffset: -3 });
  const endDate = findDate({ dayOffset: -1 });
  const { data: storeSale, isLoading } = useFetchStoreDailySales(
    storeId,
    startDate,
    endDate,
  );

  const isDeliveryScraping =
    !storeStatus?.scrapingProgress.baemin ||
    !storeStatus?.scrapingProgress.yogiyo ||
    !storeStatus?.scrapingProgress.coupang;
  const findDateSale = ({
    dayOffset,
    platform,
  }: {
    dayOffset: number;
    platform?: "CARD" | "BAEMIN" | "YOGIYO" | "COUPANG" | "ETC";
  }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    const findDate = storeSale?.salesStats?.find(
      (item: SaleStats) => item.date === paramDate,
    );

    const platformSale = findDate?.perPlatforms?.find(
      (item: SaleStats["perPlatforms"][number]) => item.platform === platform,
    );

    return {
      amountSales: findDate?.amountSales,
      platformSale,
      platformDelta: platformSale?.delta.numSales,
      month,
      day,
    };
  };

  if (isLoading || storeStatusLoading)
    return (
      <Box
        width="100%"
        height="180px"
        radius={"xl"}
        shadow={"md"}
        center
        bg="bg-primary"
      >
        <Spinner />
      </Box>
    );

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box
        padding="16px"
        width="100%"
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/calendar?selectedDate=${findDate({ dayOffset: -1 })}`)
        }
      >
        <VStack spacing={6}>
          <HStack justify={"between"} align={"center"}>
            <HStack spacing={2} width="auto">
              <Icon
                icon={InvoiceSolidIcon}
                color="content-primary"
                size={"md"}
              />
              <Text typo="body-2" weight="semi-bold" color="content-primary">
                어제 매출
              </Text>
            </HStack>
            <HStack align="center" width="auto">
              <Text
                typo="subtitle-2"
                weight="semi-bold"
                color="content-primary"
              >
                {isBeforeNoon ||
                !storeStatus?.scrapingProgress.card ||
                isDeliveryScraping
                  ? "수집 중이에요"
                  : `${
                      Number(
                        findDateSale({ dayOffset: -1 })?.amountSales,
                      ).toLocaleString("ko-KR") ?? 0
                    }원`}
              </Text>
              {!isBeforeNoon && (
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="md"
                />
              )}
            </HStack>
          </HStack>
          {!isBeforeNoon && (
            <HStack justify="between">
              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    배달의민족
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.baemin
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "BAEMIN" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "BAEMIN" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.baemin}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    요기요
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.yogiyo
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "YOGIYO" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "YOGIYO" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.yogiyo}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    쿠팡이츠
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.coupang
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "COUPANG" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "COUPANG" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.coupang}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    카드
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.card
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "CARD" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "CARD" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.card}
                />
              </VStack>
            </HStack>
          )}
        </VStack>
      </Box>
      <Divider />
      <Box padding="16px" width="100%">
        <VStack spacing={4}>
          <Box
            padding="4px 0"
            width="100%"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/calendar?selectedDate=${findDate({ dayOffset: -2 })}`)
            }
          >
            <HStack justify={"between"}>
              <Text typo="body-2" weight="regular" color="content-secondary">
                {`${findDateSale({ dayOffset: -2 }).month}월 ${
                  findDateSale({ dayOffset: -2 }).day
                }일`}{" "}
                매출
              </Text>
              <HStack align="center" width="auto">
                <Text typo="body-2" weight="regular" color="content-secondary">
                  {findDateSale({ dayOffset: -2 }).amountSales?.toLocaleString(
                    "ko-KR",
                  ) ?? 0}
                  원
                </Text>
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="sm"
                />
              </HStack>
            </HStack>
          </Box>
          <Box
            padding="4px 0"
            width="100%"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/calendar?selectedDate=${findDate({ dayOffset: -3 })}`)
            }
          >
            <HStack justify={"between"}>
              <Text typo="body-2" weight="regular" color="content-secondary">
                {`${findDateSale({ dayOffset: -3 }).month}월 ${
                  findDateSale({ dayOffset: -3 }).day
                }일`}{" "}
                매출
              </Text>
              <HStack align="center" width="auto">
                <Text typo="body-2" weight="regular" color="content-secondary">
                  {findDateSale({ dayOffset: -3 }).amountSales?.toLocaleString(
                    "ko-KR",
                  ) ?? 0}
                  원
                </Text>
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="sm"
                />
              </HStack>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

const CountDelta = ({
  deltaCount,
  scrapping,
}: {
  deltaCount: number;
  scrapping: boolean;
}) => {
  const bgColor =
    scrapping === false
      ? "bg-positive-light"
      : deltaCount < 0
      ? "bg-informative-light"
      : "bg-negative-light";
  const textColor =
    scrapping === false
      ? "content-positive"
      : deltaCount < 0
      ? "content-highlight"
      : "content-negative";
  const textContent =
    scrapping === false
      ? "수집 중"
      : `${deltaCount > 0 ? `+${deltaCount}건` : `${deltaCount}건`}`;

  if (scrapping && deltaCount === 0) return null;
  return (
    <Box bg={bgColor} radius="md" padding="0 6px" display="flex">
      <Text color={textColor} typo="caption-1" weight="regular">
        {textContent}
        {/* {deltaCount > 0 && "+"}
        {deltaCount}건 */}
      </Text>
    </Box>
  );
};

export default PreviousDateSales;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaeaea;
`;
