import React from "react";
import styled from "styled-components";
import Button from "@components/atoms/Button";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { IconTags } from "@components/atoms/Icon/type";
import IconButton from "@components/atoms/IconButton";
import {
  BottomSheetProps,
  ButtonQty,
  ButtonStates,
  HeaderType,
  States,
} from "@components/molecules/BottomSheet/type";
import { ButtonTypes } from "@components/atoms/Button/type";
import { fadeInMotion, slideUpModalMotion } from "@styles/animation";
import Interaction from "@components/atoms/Interaction";

export const BottomSheet: React.FC<BottomSheetProps> = ({
  states = States.FIT,
  headerType = HeaderType.HANDLEBAR_ONLY,
  buttonQty = ButtonQty.SINGLE,
  buttonStates = ButtonStates.ACTIVE,
  primaryButtonType = ButtonTypes.PRIMARY,
  secondaryButtonType = ButtonTypes.OUTLINED,
  hasBackground = true,
  hasButton = true,
  prefixText,
  onClickPrefixText,
  primaryButtonText,
  secondaryButtonText,
  headerText,
  children,
  onClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
  isLoading,
}) => {
  const primaryButtonTag =
    buttonStates === ButtonStates.INACTIVE
      ? ButtonTypes.DISABLED
      : primaryButtonType;
  const secondaryButtonTag =
    buttonStates === ButtonStates.INACTIVE
      ? ButtonTypes.DISABLED
      : buttonQty === ButtonQty.DUO_VERTICAL
      ? ButtonTypes.GHOST
      : secondaryButtonType;
  const isDisabled = buttonStates === ButtonStates.INACTIVE;

  /** PrimaryButton 을 클릭하였을 경우 호출하는 이벤트입니다.
   *  클릭 함수가 없을 경우, 바텀시트를 종료하는 이벤트를 호출합니다.
   */
  const handleClickPrimaryButton = () => {
    onClickPrimaryButton ? onClickPrimaryButton() : onClose();
  };

  /** SecondaryButton 을 클릭하였을 경우 호출하는 이벤트입니다.
   *  클릭 함수가 없을 경우, 바텀시트를 종료하는 이벤트를 호출합니다.
   */
  const handleClickSecondaryButton = () => {
    onClickSecondaryButton ? onClickSecondaryButton() : onClose();
  };

  return (
    <>
      <Background onClick={onClose} hasBackground={hasBackground} />
      <Wrapper states={states}>
        <InnerWrapper>
          {/** headerType 에 따라 헤더바를 보여줍니다. */}
          {headerType === HeaderType.HANDLEBAR_ONLY && <HeaderBar />}
          <ContentsWrapper>
            {headerType === HeaderType.HANDLEBAR_ONLY && (
              <HeaderBarWrapper>
                <Text bold tag={FontTypes.SUBTITLE_2}>
                  {headerText}
                </Text>
                {prefixText && (
                  <Interaction>
                    <PrefixTextButton onClick={onClickPrefixText}>
                      <Text color={ColorsTypes.CONTENT_HIGHLIGHT}>
                        {prefixText}
                      </Text>
                    </PrefixTextButton>
                  </Interaction>
                )}
              </HeaderBarWrapper>
            )}
            {/** headerType 에 따라 타이틀+아이콘 영역을 보여줍니다. */}
            {headerType === HeaderType.TEXT_ICON && (
              <HeaderWrapper>
                <Text bold tag={FontTypes.SUBTITLE_2}>
                  {headerText}
                </Text>
                <IconButton icon={IconTags.CLOSE_MARK} onClick={onClose} />
              </HeaderWrapper>
            )}

            {/** 콘텐츠 영역 */}
            {children}
          </ContentsWrapper>
        </InnerWrapper>

        {/** 버튼 영역 */}
        {hasButton && (
          <ButtonWrapper buttonQty={buttonQty}>
            {buttonQty !== ButtonQty.SINGLE && (
              <SecondaryButton
                onClick={handleClickSecondaryButton}
                tag={secondaryButtonTag}
                isDisabled={isDisabled}
                isLoading={isLoading}
              >
                {secondaryButtonText}
              </SecondaryButton>
            )}
            <PrimaryButton
              onClick={handleClickPrimaryButton}
              tag={primaryButtonTag}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {primaryButtonText}
            </PrimaryButton>
          </ButtonWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default BottomSheet;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ hasBackground }: { hasBackground: boolean }) =>
    hasBackground ? "rgba(1, 1, 1, 0.5)" : "rgba(1, 1, 1, 0)"};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 80;

  ${fadeInMotion};
`;

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${({ states }: { states: States }) =>
    states === States.FULL ? "calc(100vh - 46px)" : "fit-content"};
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  border-radius: 12px 12px 0 0;
  background: #fefefe;
  z-index: 90;

  ${slideUpModalMotion};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 8px;
  flex-direction: ${({ buttonQty }: { buttonQty: ButtonQty }) =>
    buttonQty === ButtonQty.DUO_HORIZONTAL ? "row" : "column-reverse"};
`;

const PrimaryButton = styled(Button)``;

const SecondaryButton = styled(Button)``;

const HeaderBar = styled.div`
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: #c6c6c6;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderBarWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
`;

const PrefixTextButton = styled.button`
  background: transparent;
`;
