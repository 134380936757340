import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import themes from "@styles/themes";

export const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 10px !important;
    padding: 12px;
    height: fit-content !important;
    min-height: fit-content !important;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    height: fit-content;

    > div {
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .Toastify__toast--success {
    background-color: ${themes.colors.BG_PRIMARY_INVERSE} !important;

    > div,
    button {
      color: ${themes.colors.CONTENT_ON_COLOR};
    }
  }
  .Toastify__toast--error {
    background-color: ${themes.colors.BG_NEGATIVE_LIGHT} !important;

    > div,
    button {
      color: ${themes.colors.CONTENT_NEGATIVE};
    }
  }
  .Toastify__progress-bar {
    width: 0;
    height: 0;
  }
  .Toastify__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
`;
