import React from "react";

import { changeSizeToNumber } from "@components/atoms/IconButton/utils";
import Interaction from "@components/atoms/Interaction";

import { ColorsTypes } from "@/@types/ThemesType";

import Icon from "../Icon";
import { IconTags } from "../Icon/type";
import { IconButtonWrapper } from "./index.styled";
import { IconButtonSizes } from "./type";

export interface IconButtonProps {
  size?: IconButtonSizes;
  icon: IconTags;
  color?: ColorsTypes;
  onClick: () => void;
  className?: string | string[];
}

export const IconButton: React.FC<IconButtonProps> = ({
  size = IconButtonSizes.MD,
  icon,
  color = ColorsTypes.CONTENT_PRIMARY,
  onClick,
  className,
}) => {
  return (
    <Interaction>
      <IconButtonWrapper
        className={[
          ...(Array.isArray(className) ? className : [className]),
          "earlypay-icon-button",
        ].join(" ")}
        onClick={onClick}
        size={size}
        type={"button"}
      >
        <Icon
          tag={icon}
          width={changeSizeToNumber(size)}
          height={changeSizeToNumber(size)}
          color={color}
        />
      </IconButtonWrapper>
    </Interaction>
  );
};

export default IconButton;
