import { IconTags } from "@components/atoms/Icon/type";

export const validPaths = [
  "/",
  "/home",
  "/calendar",
  "/settlement",
  "/my",
  "/deposits",
];

export const bottomNavItems = [
  {
    label: "홈",
    paths: ["/", "/home"],
    icon: IconTags.HOME_SOLID,
  },
  {
    label: "캘린더",
    paths: ["/calendar"],
    icon: IconTags.CALENDAR_SOLID,
  },
  {
    label: "정산내역",
    paths: ["/settlement", "/deposits"],
    icon: IconTags.INVOICE_SOLID,
  },
  {
    label: "마이페이지",
    paths: ["/my"],
    icon: IconTags.USER_SOLID,
  },
];
