import React from "react";

import { appStatusState } from "@recoil/appStatus/atoms";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Container, ErrorScreen, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

const SystemError = () => {
  const appStatus = useRecoilValue(appStatusState);
  const navigate = useNavigate();

  const handleGoToPrevious = () => {
    navigate(-1);
  };

  return (
    <>
      <Meta title={`System Error`} />
      <Container>
        <Header hasLeftButton={false} hasRightButton={appStatus.isLoggedIn} />
        <PageContainer>
          <ErrorScreen type={"500"} handleGoToPrevious={handleGoToPrevious} />
        </PageContainer>
      </Container>
    </>
  );
};

export default SystemError;
