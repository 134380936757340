export const IconTags = {
  ARROW_RIGHT: "ARROW_RIGHT",
  EARLYPAY_WORDMARK: "EARLYPAY_WORDMARK",
  EARLYPAY_SYMBOL: "EARLYPAY_SYMBOL",
  CHEVRON_RIGHT: "CHEVRON_RIGHT",
  CHEVRON_LEFT: "CHEVRON_LEFT",
  CHEVRON_UP: "CHEVRON_UP",
  CHEVRON_DOWN: "CHEVRON_DOWN",
  EARLYPAY_LOGO: "EARLYPAY_LOGO",
  MESSAGE: "MESSAGE",
  ARROW_LEFT: "ARROW_LEFT",
  TOOLTIP_POINTER: "TOOLTIP_POINTER",
  CLOSE_MARK: "CLOSE_MARK",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  KAKAO_LOGO: "KAKAO_LOGO",
  WARNING: "WARNING",
  WARNING_FILL: "WARNING_FILL",
  CAMERA: "CAMERA",
  GALLERY: "GALLERY",
  HOME_SOLID: "HOME_SOLID",
  CALENDAR_SOLID: "CALENDAR_SOLID",
  USER_SOLID: "USER_SOLID",
  INVOICE_SOLID: "INVOICE_SOLID",
  HEADPHONES: "HEADPHONES",
  LOGO_EARLYPAY_SIGNATURE: "LOGO_EARLYPAY_SIGNATURE",
  CHEVRON_DOWN_SOLID: "CHEVRON_DOWN_SOLID",
  CIRCLE_QUESTION_MARK_SOLID: "CIRCLE_QUESTION_MARK_SOLID",
  SQUARE: "SQUARE",
  SQUARE_CHECK_SOLID: "SQUARE_CHECK_SOLID",
  SNOWFLAKE: "SNOWFLAKE",
  RELOAD: "RELOAD",
  DIAGONAL_ARROWS: "DIAGONAL_ARROWS",
} as const;
export type IconTags = (typeof IconTags)[keyof typeof IconTags];

export const IconSizes = {
  XXXL: "XXXL",
  XXL: "XXL",
  XL: "XL",
  LG: "LG",
  MD: "MD",
  SM: "SM",
  XS: "XS",
} as const;
export type IconSizes = (typeof IconSizes)[keyof typeof IconSizes];
