import React, { useEffect, useState } from "react";

import { applicationState } from "@recoil/application/atom";
import { Chart, registerables } from "chart.js";
import { subDays } from "date-fns";
import { Line } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import { useRecoilValue } from "recoil";

import { Box, Text, VStack } from "@earlybird/ui";

import { getSalesSummary } from "@apis/endpoints";

import { getFormattedDateWithDash } from "@utils/date";

import type { SalesInterface } from "@/@types/saleType";
import { formatPlatformSaleData } from "@/utils/formatEarlypay";

import { options } from "./chartOption";

Chart.register(...registerables);

const SaleChart = () => {
  const application = useRecoilValue(applicationState);

  const today = new Date().getDay();
  const lastWeekSunday = subDays(new Date(), today + 7);
  const lastWeekSaturday = subDays(new Date(), today + 1);
  const thisWeekSunday = subDays(new Date(), today);
  const thisWeekToday = subDays(new Date(), 0);

  const [thisWeekSales, setThisWeekSales] = useState([]);
  const [lastWeekSales, setLastWeekSales] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const calculateAllSale = (saleData: SalesInterface) => {
    const cardTotalSales = Object.values(saleData.card).map(
      data => data.totalSales,
    );
    const baeminTotalSales = Object.values(saleData.baemin).map(
      data => data.totalSales,
    );
    const baeminTotalMeetCardSales = Object.values(saleData.baemin).map(
      data => data.meetCard,
    );
    const yogiyoTotalSales = Object.values(saleData.yogiyo).map(
      data => data.totalSales,
    );
    const yogiyoTotalMeetCardSales = Object.values(saleData.yogiyo).map(
      data => data.meetCard,
    );

    const totalSales = cardTotalSales.map((sale, index) => {
      return (
        sale +
        baeminTotalSales[index] +
        yogiyoTotalSales[index] -
        baeminTotalMeetCardSales[index] -
        yogiyoTotalMeetCardSales[index]
      );
    });

    return totalSales;
  };

  const getWeekGraphData = async (
    lastWeekSunday: string,
    lastWeekSaturday: string,
    thisWeekSunday: string,
    thisWeekToday: string,
  ) => {
    setIsLoading(true);

    try {
      const lastWeekSaleData = await getSalesSummary({
        start_date: lastWeekSunday,
        end_date: lastWeekSaturday,
        store_id: application.store.id,
      });

      const currentWeekSaleData = await getSalesSummary({
        start_date: thisWeekSunday,
        end_date: thisWeekToday,
        store_id: application.store.id,
      });
      const lastWeekSale = formatPlatformSaleData(
        lastWeekSunday,
        lastWeekSaturday,
        lastWeekSaleData,
      );
      const currentWeekSale = formatPlatformSaleData(
        thisWeekSunday,
        thisWeekToday,
        currentWeekSaleData,
      );

      const lastWeekTotalSale = calculateAllSale(lastWeekSale);
      const currentWeekTotalSale = calculateAllSale(currentWeekSale);

      setLastWeekSales([...lastWeekTotalSale]);
      setThisWeekSales([...currentWeekTotalSale]);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWeekGraphData(
      getFormattedDateWithDash(lastWeekSunday),
      getFormattedDateWithDash(lastWeekSaturday),
      getFormattedDateWithDash(thisWeekSunday),
      getFormattedDateWithDash(thisWeekToday),
    );
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = {
    labels: ["일", "월", "화", "수", "목", "금", "토"],
    borderColor: "#fff",
    datasets: [
      {
        type: "line",
        label: "이번주 매출",
        borderColor: "#5165cc",
        backgroundColor: "rgba(170, 197, 255, 0.3)",
        fontColor: "#fff",
        borderWidth: 1,
        data: [...thisWeekSales],
        fill: true,
      },
      {
        type: "line",
        label: "저번주 매출",
        borderColor: "#aaa",
        backgroundColor: "rgba(170, 170, 170, 0.1)",
        borderWidth: 1,
        fill: true,
        data: [...lastWeekSales],
        borderDash: [5, 5],
      },
    ],
  };

  if (isLoading) {
    return (
      <Box width={"100%"}>
        <Skeleton
          containerClassName={"flex-1"}
          width={"100%"}
          height={375}
          borderRadius={12}
        />
      </Box>
    );
  }

  return (
    <VStack spacing={2}>
      <Text typo={"body-3"}>주간매출 비교</Text>
      <Line data={data} options={options} height={"230%"} />
    </VStack>
  );
};

export default SaleChart;
