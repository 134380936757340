//TODO: 약관 apis 개편 이후, 서버에서 약관 정보를 가져올 예정입니다.
import Container from "@components/layouts/Container";
import styled from "styled-components";
import React from "react";

export const IntegratedTerm = () => {
  const html = `
<h3 style="font-size: 20px; font-weight: bold;">얼리페이 통합 서비스 이용약관</h3>
<br/>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제1조 (목적)</b></p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5;">본 약관은 주식회사 얼리페이(이하 회사라 합니다)가 제휴사와 함께 회원의 통합적 회원 서비스 이용 등을 위한 얼리페이계정통합서비스를 회원에게 제공함에 있어, 회사 및 제휴사와 회원 간의 권리와 의무 및 책임 사항과 서비스 이용조건 및 절차 등 기본적 사항을 규정함을 목적으로 합니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제2조 (용어의 정의)</b></p>
<p style="line-height: 1.5;">① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">사이트라 함은 회사가 회원에게 서비스를 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 아래의 웹사이트를 말합니다.</li>
    <li style="line-height: 1.5;">회원이라 함은 본 약관에 동의하는 등 회사가 정하는 절차에 따라 서비스 이용 신청을 하고 이에 대한 회사의 승낙을 받아 본 약관에 따른 서비스를 이용하는 자를 말합니다.</li>
    <li style="line-height: 1.5;">서비스라 함은 회사와 제휴사가 회원에게 제공하는 통합맞춤관리 서비스 등의 서비스를 말하며, 구체적인 내용은 제10조에 정한 바와 같습니다.</li>
    <li style="line-height: 1.5;">제휴사라 함은 얼리페이와 서비스 운영에 관하여 제휴계약을 체결하고 서비스를 공동 제공하기로 합의한 회사를 말하며 얼리페이대부㈜ 를 포함합니다. 추후 제휴사는 회사 및 제휴사의 사정에 따라 추가 또는 변경될 수 있으며, 얼리페이 사이트 등을 통하여 고지합니다.</li>
</ol>
<p style="line-height: 1.5;">② 본 약관에 사용된 용어 중 본 조에서 정하지 않은 부분은 회사의 다른 약관, 운영정책 또는 관련 법령에서 정한 바에 따르며, 그 외에는 일반 관례에 따릅니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제3조 (약관의 효력 및 변경)</b></p>
<p style="line-height: 1.5;">① 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 화면에 게시하거나 기타의 방법으로 명시하고, 회원의 요청이 있는 경우 전자문서의 전송(전자우편을 이용한 전송을 포함합니다), 모사전송, 우편 또는 직접 교부의 방식으로 본 약관의 사본을 회원에게 교부합니다.</p>
<p style="line-height: 1.5;">② 회사는 약관 규제에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</p>
<p style="line-height: 1.5;">③ 회사가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 그 개정약관을 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만 회원에게 불리하거나 중요한 사항을 변경하는 경우에는 적용일자 30일 이상의 유예기간을 두고 공지하며, 공지 외에 일정기간 회원의 휴대폰번호, 로그인 시 동의창, SNS(카카오알림톡 등) 등의 전자적 수단을 통해 따라 명확히 통지합니다.</p>
<p style="line-height: 1.5;">④ 회사가 전 항에 따라 개정약관을 공지 또는 통지하면서 회원에게 개정약관 적용일자 전까지 의사표시를 하지 않으면 개정약관에 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도, 회원이 명시적으로 거부의사를 표시하지 않으면 회원이 개정약관에 동의한 것으로 봅니다.</p>
<p style="line-height: 1.5;">⑤ 회원이 개정약관의 내용에 동의하지 않는 경우 회사는 해당 회원에 대하여 개정약관의 내용을 적용할 수 없으며, 이 경우 회사는 회원의 서비스 이용을 제한할 수 있습니다. 또한 회사가 개정약관에 동의하지 않은 회원에게 기존 약관을 적용할 수 없는 경우 회사는 해당 회원과의 이용계약을 해지할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제4조 (약관 외 준칙)</b></p>
<p style="line-height: 1.5;">① 본 약관에 명시되지 않은 사항은 회사의 다른 약관, 운영정책, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 등 관련 법령의 규정 또는 일반 관례에 따릅니다.</p>
<p style="line-height: 1.5;">② 본 약관과 관련하여 회사의 운영정책 변경, 관련 법령의 제/개정 또는 공공기관의 고시나 지침, 가이드 등에 의하여 회사가 공지사항 등을 통해 공지하는 내용도 본 약관의 일부를 구성합니다.</p>
<p style="line-height: 1.5;">③ 본 약관은 통합 계정으로 이용 가능한 개별서비스에 모두 적용됩니다. 다만 본 약관의 내용과 개별 서비스 약관의 내용이 서로 다른 경우, 개별 서비스 약관이 우선 적용되며 본 약관은 보충적으로 적용됩니다. 다만, 통합 계정과 관련한 고유의 사항에 관하여는 본 약관이 개별 서비스 약관보다 우선하여 적용됩니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제5조 (통지)</b></p>
<p style="line-height: 1.5;">① 회사가 회원에게 개별 통지하는 경우, 회사는 회원이 등록한 E-mail 주소 또는 SMS, 카카오톡(친구톡,알림톡) 등으로 할 수 있습니다.</p>
<p style="line-height: 1.5;">② 제1항에도 불구하고 회사가 불특정 다수의 회원에게 통지하는 경우 서비스 화면에 해당 사실을 게시함으로써 개별 통지에 갈음할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제6조 (서비스의 변경, 중단 또는 종료)</b></p>
<p style="line-height: 1.5;">① 회사는 운영상, 경영상, 기술상의 필요 또는 제휴사 등과의 제휴 종료에 따라 서비스의 전부 또는 일부 내용을 변경하거나 서비스를 종료할 수 있으며, 서비스의 내용, 이용방법 등에 대하여 변경이 있는 경우에는 변경사유, 변경내용 및 제공일자 등을 변경 7일 전에, 서비스를 종료하는 경우에는 30일 전에 해당 사실을 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.</p>
<p style="line-height: 1.5;">② 회사는 다음 각 호에 해당하는 경우 통합 계정서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">통합 계정용 설비의 보수 등 공사의 장기화로 인한 부득이한 경우</li>
    <li style="line-height: 1.5;">회원이 회사의 영업활동을 방해하는 경우</li>
    <li style="line-height: 1.5;">회원이 서비스를 이용할 수 있는 신용 및 매출 조건을 충족하지 못한 경우</li>
    <li style="line-height: 1.5;">경영상의 판단, 관계사와의 계약 종료, 정부의 명령이나 지도 기타 규제, 그 밖의 급격한 영업환경의 변화 등 회사의 제반 사정으로 통합 계정 서비스의 제공을 일시적으로 중단하는 것이 필요하다는 경영상의 판단이 내려진 경우</li>
    <li style="line-height: 1.5;">기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>
</ol>
<p style="line-height: 1.5;">③ 회사는 천재지변, 전쟁, 폭동, 테러, 해킹, DDOS 등 불가항력적 사유로 서비스가 중단된 경우 즉시 이러한 사실을 공지하되, 만일 정보통신설비의 작동불능 등의 불가피한 사유로 인해 즉시 공지가 불가능한 경우에는 이러한 사정이 해소된 이후 즉시 이러한 사실을 공지합니다.</p>
<p style="line-height: 1.5;">④ 회사는 본 약관 및 관련 법령에 특별한 규정이 없는 한 서비스의 변경, 중단, 내용 수정 또는 종료로 인하여 회원이 입은 손해에 대해 아무런 책임을 지지 않습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제7조 (이용계약의 성립)</b></p>
<p style="line-height: 1.5;">① 서비스 이용계약은 회원이 되고자 하는 자가 회사가 정한 가입절차와 방법에 따라 서비스 이용신청을 하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
<p style="line-height: 1.5;">② 회원이 서비스 이용을 위해서는 회사가 요청하는 정보를 정확하게 제공하여야 합니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제8조 (이용계약 신청의 승낙과 제한 등)</b></p>
<p style="line-height: 1.5;">① 회사는 회원이 되고자 하는 자가 서비스 이용신청 시 본 약관에 동의하고, 회사가 정한 가입 절차와 방법을 준수하는 경우 그 이용신청에 대하여 승낙하는 것을 원칙으로 합니다.</p>
<p style="line-height: 1.5;">② 회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생하는 경우, 이용신청에 대한 승낙을 거부할 수 있습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">만 19세 미만의 자가 이용신청을 하는 경우</li>
    <li style="line-height: 1.5;">기재 내용에 허위, 기재누락, 오기 등이 있는 경우</li>
    <li style="line-height: 1.5;">회사로부터 이용정지 당한 회원이 그 이용정지 기간 중에 이용계약을 임의 해지하고 재 이용신청을 하는 경우</li>
    <li style="line-height: 1.5;">얼리페이 회원이 아니거나 서비스 이용제한 등의 조치를 당한 경우</li>
    <li style="line-height: 1.5;">과거에 본 약관 위반 등의 사유로 서비스 이용계약이 해지 당한 경력이 있는 경우</li>
    <li style="line-height: 1.5;">기타 관련 법령, 본 약관 또는 회사의 다른 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</li>
</ol>
<p style="line-height: 1.5;">③ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</p>
<p style="line-height: 1.5;">④ 본 조 제2항과 제3항에 따라 이용계약 신청의 승낙을 하지 아니하거나 유보한 경우 회사는 원칙적으로 이를 이용신청자에게 알립니다.</p>
<p style="line-height: 1.5;">⑤ 이용계약의 성립 시기는 회사가 이용신청 완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
<br/>
<p style="line-height: 1.5; font-weight: bold;"><b>제9조 (이용계약의 해지 등)</b></p>
<p style="line-height: 1.5;">① 회원은 언제든지 회사에 이용계약 해지의 의사를 통지함으로써 이용계약을 해지할 수 있습니다. 단, 회원은 해지의사를 통지하기 전에 회사가 제공하는 서비스를 이용하여 진행중인 제반 거래 행위를 완결하여 더 이상 서비스를 통하여 처리되어야 할 채권 채무 관계가 남지 않도록 하여야 하며, 이러한 조치를 취하지 아니하고 서비스의 이용 해지 의사표시를 함으로써 발생하는 불이익에 대하여는 회원이 그 책임을 부담하여야 합니다. 회사가 필요하다고 판단하는 경우, 이와 같은 채권 채무 관계가 모두 해소될 때까지 해지의 효력 발생시기를 연기할 수 있습니다.</p>
<p style="line-height: 1.5;">② 회원이 임의 해지, 재가입 등을 반복적으로 행함으로써 회사가 제공하는 혜택이나 경제적 이익을 비통상적인 방법으로 악용할 우려가 있다고 회사가 판단하는 경우, 회사는 재가입 또는 해지를 제한할 수 있습니다.</p>
<p style="line-height: 1.5;">③ 전 항의 심사 목적 등을 위하여 회사는 해지 시 관계법령이 정한 기간동안 동안 해당 회원의 성명, 해지일자, 해지사유 등 필요한 정보를 보관할 수 있으며, 회원이 재가입을 한 경우 회사가 제공하는 혜택이나 경제적 이익 제한 등 해지 전과 동일한 상태에서 서비스 제공을 개시할 수 있습니다.</p>
<p style="line-height: 1.5;">④ 본 약관에 따라 이용계약이 해지된 경우 회사는 회원에게 부가적으로 제공한 각종 혜택을 무효화하거나 회수할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제10조 (서비스의 내용)</b></p>
<p style="line-height: 1.5;">① 회사가 회원에게 제공하는 서비스의 내용은 다음 각 호와 같으며, 제휴사와 제휴한 계약 내용에 따라 구체적인 서비스의 내용에 차이가 있을 수 있습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">통합맞춤관리서비스: 회원정보 변경, 회원 인증, 가입한 서비스를 통해 매출 거래 내역 등을 통합 관리하고, 이를 기반으로 회원이 회사 및 제휴사의 개인맞춤 UI&middot;컨텐츠 등 개인화서비스를 유기적으로 사용할 수 있도록 하는 서비스</li>
    <li style="line-height: 1.5;">기타 이에 부수하거나 신규로 추가되는 서비스</li>
</ol>
<p style="line-height: 1.5;">② 서비스의 내용은 제휴사와 회사의 사정, 관련 법령, 정부처의 가이드라인 등에 따라 수시로 변경될 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제11조 (통합맞춤관리서비스)</b></p>
<p style="line-height: 1.5;">① 회원은 아래 각 호와 같은 통합맞춤관리서비스를 이용할 수 있습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">회원 본인의 정보를 회사 또는 제휴사에서 편리하게 관리할 수 있도록 회원 인증, 회원정보 변경, 회원 가입 및 탈퇴, 서비스 설정 및 이용기록 등을 통합적으로 조회&middot;관리할 수 있도록 하는 서비스 및 제반 서비스</li>
    <li style="line-height: 1.5;">회사 및 제휴사에서 회원이 각 가입&middot;이용한 금융상품 또는 서비스의 내역 (금융거래내역,신용 평가내역, 카드 매출내역,배달 매출 내역 등 포함)을 통합적으로 조회&middot;관리하며 상호유기적으로 연계된 사용자 경험을 제공하는 서비스 및 제반 서비스</li>
    <li style="line-height: 1.5;">위 각호의 정보를 바탕으로 제휴사의 금융상품, 회사의 금융플랫폼 서비스 및 UI&middot;컨텐츠 등을 개인맞춤형으로 제공하는 서비스 및 제반 서비스(다만 광고에 해당하는 것은 제외)</li>
</ol>
<p style="line-height: 1.5;">② 회원이 통합계정의 회원정보를 기재, 수정, 삭제한 경우, 해당 회원정보는 통합맞춤관리서비스 제공을 위해 회사 및 제휴사에 공유되며, 회사 및 제휴사는 서비스 제공을 위하여 회원정보를 통합하여 관리하고 이용할 수 있습니다.</p>
<p style="line-height: 1.5;">③ 회원이 회사 및 제휴사에서 이용한 서비스의 구체적인 내역(금융거래내역, 신용 평가내역,카드 매출내역,배달 매출 내역 등 포함)은 서비스 제공을 위해 회사 및 제휴사에 공유되며, 회사 및 제휴사는 서비스 제공을 위하여 회원의 동의를 받아 개인(신용)정보를 수집하고 통합할 수 있습니다. 다만, 회원의 개인(신용)정보 전송요구권 행사에 의하여 회사가 수집한 개인(신용)정보에 대해서는 회사의 개인(신용)정보 수집 이용약관에 따릅니다</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제12조 (회사의 의무)</b></p>
<p style="line-height: 1.5;">① 회사는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</p>
<p style="line-height: 1.5;">② 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</p>
<p style="line-height: 1.5;">③ 회사는 서비스의 운영주체로 서비스에 관한 제반 업무에 대하여 일체의 책임을 부담합니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제13조 (회원의 의무)</b></p>
<p style="line-height: 1.5;">① 회원은 관련 법령, 약관, 서비스 이용안내, 서비스 상에 공지한 주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 합니다.</p>
<p style="line-height: 1.5;">② 회원은 자신의 개인정보, 계정정보(아이디, 비밀번호, 접근매체, 인증수단) 등을 제3자에게 제공하지 아니하며, 해당 정보가 유출되지 않도록 관리하여야 합니다. 또한 회원은 자신의 명의가 도용되거나 부정하게 사용된 것을 인지한 경우 즉시 그 사실을 회사에 통보하여야 합니다.</p>
<p style="line-height: 1.5;">③ 회원은 회사가 서비스를 안전하고 원활하게 제공할 수 있도록 회사에 협조하여야 하며, 회사가 회원의 본 약관 위반행위를 발견하여 회원에게 해당 위반행위에 대하여 소명을 요청할 경우 회원은 회사의 요청에 적극 응하여야 합니다.</p>
<p style="line-height: 1.5;">④ 회원은 서비스와 관련하여 정확한 정보를 입력하여야 하며, 회원은 자신이 입력한 정보와 관련하여 발생하는 제반 문제에 대한 모든 책임을 부담합니다.</p>
<p style="line-height: 1.5;">⑤ 회원은 서비스의 신뢰성을 제고하고 안전한 서비스 이용을 위하여 아래 각 호에 정한 행위를 하여서는 안 됩니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">회사 또는 제휴사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위</li>
    <li style="line-height: 1.5;">서비스를 이용하여 영업활동을 하는 행위</li>
    <li style="line-height: 1.5;">회사 또는 제휴사의 정책에 위반하여 서비스의 이용권한, 기타 본 약관상 지위를 타인에게 양도 또는 증여하거나 담보로 제공하는 행위</li>
    <li style="line-height: 1.5;">제3자의 명의, 로그인 정보, 계좌정보 등 제3자의 정보를 도용하여 회사 또는 제휴사가 제공하는 서비스를 이용하는 행위</li>
    <li style="line-height: 1.5;">회사 또는 제휴사가 게시한 정보의 무단 변경 또는 회사 또는 제휴사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시하는 행위</li>
    <li style="line-height: 1.5;">회사 또는 제휴사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사 또는 제휴사의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 및 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하는 행위</li>
    <li style="line-height: 1.5;">회사, 제휴사 또는 제3자의 저작권 등 지식재산권에 대한 침해행위</li>
    <li style="line-height: 1.5;">회사, 제휴사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
    <li style="line-height: 1.5;">기타 불법적이거나 부당한 행위</li>
</ol>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제14조 (서비스 이용제한 등)</b></p>
<p style="line-height: 1.5;">① 회사는 아래 각 호의 어느 하나에 해당하는 사유가 발생하는 경우 회원의 서비스 이용 제한, 중단, 계약해지, 손해배상청구 등의 조치를 취할 수 있습니다. 이 경우 발생하는 모든 책임은 회원이 부담하며, 회사는 필요한 경우 회원의 금지행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.</p>
<ol style="padding-left: 40px; padding-top: 10px; padding-bottom: 10px;">
    <li style="line-height: 1.5;">본 약관 또는 약관 외 준칙을 위반하는 경우</li>
    <li style="line-height: 1.5;">얼리페이 회원 자격이 정지되거나 상실되는 경우</li>
    <li style="line-height: 1.5;">회사가 의도하지 않은 방법으로 서비스를 이용하거나 회사에 손실을 끼치는 경우</li>
    <li style="line-height: 1.5;">서비스의 시스템을 무단으로 침범한 경우</li>
    <li style="line-height: 1.5;">회원이 제3자 명의 등을 무단으로 이용하는 경우</li>
    <li style="line-height: 1.5;">이전에 회원 자격을 상실한 적이 있는 경우(다만, 자격 상실 이후 회사가 재가입을 승낙한 경우에는 예외)</li>
    <li style="line-height: 1.5;">기타 회사가 정한 요건을 충족하지 않은 경우</li>
</ol>
<p style="line-height: 1.5;">② 회사는 제1항의 경우 회원이 해당 사유를 소명하거나 거래 상대방의 양해가 있었음을 소명하는 등 회사가 정하는 기준을 충족하는 경우 조치를 해소할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제15조 (손해배상)</b></p>
<p style="line-height: 1.5;">① 회원은 서비스 이용과 관련하여 회사가 관련 법령 또는 본 약관을 위반하여 회원에게 손해를 발생시킨 경우에는 회사에게 손해배상을 청구할 수 있습니다.</p>
<p style="line-height: 1.5;">② 회원이 본 약관의 규정을 위반함으로 인하여 회사의 영업활동에 손해가 발생하게 되는 경우 해당 회원은 회사에 그 손해를 배상해야 할 책임이 있습니다.</p>
<p style="line-height: 1.5;">③ 서비스를 이용하면서 불법행위를 하거나 본 약관의 규정을 위반하는 행위를 한 회원으로 말미암아 회사가 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 해당 회원은 그로 인하여 회사에 발생한 손해를 배상해야 합니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제16조 (회사의 면책)</b></p>
<p style="line-height: 1.5;">① 서비스를 통해 회원에게 제시되는 각종 혜택, 상품정보 제공 서비스 등은 확정적인 내용이 아니며, 이를 제공하는 자와 관련 계약 체결시 달라질 수 있습니다.</p>
<p style="line-height: 1.5;">② 서비스를 통해 회원에게 제공되거나 노출되는 혜택, 상품정보, 회원 맞춤&middot;연계형 서비스 또는 컨텐츠 중 제휴사 또는 제3자 제공 또는 노출한 것은 해당 제휴사 또는 제3자가 회원에게 제공하는 것으로, 회사는 회사의 귀책사유가 없는 한 이와 관련된 법적 분쟁에 개입하거나 책임을 지지 아니합니다.</p>
<p style="line-height: 1.5;">③ 회사는 회원의 귀책사유로 인한 서비스의 이용 장애에 대하여 책임을 지지 않습니다.</p>
<p style="line-height: 1.5;">④ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 발생한 분쟁에 대해서는 개입할 의무가 없으며, 이로 인한 회원의 손해를 배상할 책임을 부담하지 않습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제17조 (정보의 제공 및 광고의 게재)</b></p>
<p style="line-height: 1.5;">① 회사 및 제휴사는 서비스를 운영하면서 서비스 이용에 필요한 정보를 얼리페이 홈페이지 등에 게재하거나 얼리페이 이메일(E-mail), 서신우편, 또는 단문/장문 문자메시지(SMS/MMS) 등의 방법으로 회원에게 전송할 수 있습니다.</p>
<p style="line-height: 1.5;">② 회사는 서비스를 운영하면서 회사, 제휴사 또는 회사와 제휴한 다른 회사와 관련된 각종 광고를 얼리페이 홈페이지 등에 게재하거나, 이메일(E-mail), 서신우편, 단문/장문 문자메시지(SMS/MMS), 또는 텔레마케팅(TM) 등의 방법으로 회원에게 전송할 수 있습니다. 다만, 회원은 관련 법령에 따라 언제든지 수신거절을 할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제18조 (개인(신용)정보의 보호)</b></p>
<p style="line-height: 1.5;">① 회사는 관련 법령이 정하는 바에 따라 회원의 개인(신용)정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.</p>
<p style="line-height: 1.5;">② 회사는 회원이 회사 및 제휴사의 서비스를 원활하고 유기적으로 이용하도록 하기 위하여 회원의 동의를 받아 여러가지 경로로 수집된 회원의 개인(신용)정보 또는 행태정보를 수집, 통합 또는 제휴사에게 제공할 수 있고, 이를 바탕으로 서비스를 제공할 수 있습니다.</p>
<p style="line-height: 1.5;">③ 회사는 서비스와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보를 특정 회원의 정보를 구별할 수 없는 통계자료로 작성하여 사용할 수 있습니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제19조 (분쟁처리 담당자 및 이의신청)</b></p>
<p style="line-height: 1.5;">① 회원은 본 건 서비스 이용과 관련하여 회사에 이의가 있는 경우 관련 법령에 따라 서면 또는 인터넷 등을 통하여 회사에 이의를 제기할 수 있습니다.</p>
<p style="line-height: 1.5;">가. 전화 1522-6911</p>
<p style="line-height: 1.5;">나. 이메일 <a href="mailto:contact@earlypay.co.kr">contact@earlypay.co.kr</a></p>
<p style="line-height: 1.5;">② 회원은 제1항에 따라 서면 또는 인터넷 등을 이용하여 회사의 본점이나 영업점에 분쟁처리를 신청할 수 있으며, 회사는 신청을 받은 날로부터 30일 이내에 이에 대한 조사 또는 처리 결과를 회원에게 안내합니다.</p>
<p style="line-height: 1.5;"><br></p>
<p style="line-height: 1.5; font-weight: bold;"><b>제20조 (준거법 및 재판관할)</b></p>
<p style="line-height: 1.5;">① 본 약관과 관련된 사항에 대하여는 대한민국 법률을 준거법으로 합니다.</p>
<p style="line-height: 1.5;">② 회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</p>
<div class="page" title="Page 3">
    <div class="layoutArea" style="line-height: 1.5;"><br></div>
</div>
<p><br></p>
`;

  return (
    <Container>
      <Term>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Term>
    </Container>
  );
};

export default IntegratedTerm;

const Term = styled.div`
  padding: 40px;
  overflow: scroll;
  height: 100vh;
`;
