import { PlatformCode } from "@earlypay/shared";
import Skeleton from "react-loading-skeleton";

import { CircleISolidIcon } from "@earlybird/icons";
import { Box, HStack, Icon, Text, VStack } from "@earlybird/ui";

import { changeNumberToMoney } from "@/utils/types";

import { PlatformChipList } from "../PlatformChipList";

export interface SalesDetailOverviewProps {
  selectedPlatformList: string[];
  salesList: any;
  isLoading: boolean;
  handleClickPlatformFilter: (list: string[]) => void;
}

export const SalesDetailOverview = ({
  selectedPlatformList,
  salesList,
  isLoading,
  handleClickPlatformFilter,
}: SalesDetailOverviewProps) => {
  const isCard = selectedPlatformList.includes(PlatformCode.CARD);
  const canceledAmount = salesList?.canceledAmount || 0;
  const totalAmount = salesList?.totalAmount || 0;
  const meetCardAmount = salesList?.meetCardAmount || 0;

  const RenderedSalesDetailEntry = ({
    amount,
    label,
    isCanceled,
  }: {
    amount: number;
    label: string;
    isCanceled?: boolean;
  }) => (
    <HStack align={"center"} justify={"between"}>
      <Text color={"content-secondary"}>{label}</Text>
      {isLoading ? (
        <Skeleton baseColor={"#EAEAEA"} width={140} height={24} />
      ) : (
        <Text
          className={isCanceled ? "canceled-amount" : ""}
          color={"content-secondary"}
          bold
        >
          {changeNumberToMoney(amount)}
        </Text>
      )}
    </HStack>
  );

  return (
    <VStack padding={"20px 20px 32px 20px"} spacing={9} center>
      <PlatformChipList
        selectedPlatformList={selectedPlatformList}
        handleClickChip={handleClickPlatformFilter}
      />
      {isLoading ? (
        <Skeleton width={140} height={38} />
      ) : (
        <Text typo={"title-2"} bold>
          {changeNumberToMoney(totalAmount)}
        </Text>
      )}

      <VStack spacing={3}>
        <Box
          bg={"bg-secondary"}
          width={"100%"}
          radius={"lg"}
          padding={"12px 16px"}
        >
          <VStack spacing={2}>
            <RenderedSalesDetailEntry
              label={"결제 금액"}
              amount={totalAmount}
            />
            {!!meetCardAmount && (
              <RenderedSalesDetailEntry
                label={"ㄴ 만나서 카드 결제"}
                amount={meetCardAmount}
              />
            )}
            <RenderedSalesDetailEntry
              label={"취소 금액"}
              amount={canceledAmount}
              isCanceled
            />
          </VStack>
        </Box>
        {!!meetCardAmount && (
          <HStack align={"center"} spacing={2} padding={"0 2px"}>
            <Icon
              size={"xs"}
              icon={CircleISolidIcon}
              color={"content-highlight"}
            />
            <Text typo={"body-3"} color={"content-highlight"} bold>
              만나서 카드 결제는 카드 매출에 중복 집계될 수 있어요.
            </Text>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default SalesDetailOverview;
