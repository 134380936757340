export const ImageTypes = {
  MAIN_DEPOSITORS: "MAIN_DEPOSITORS",
  SATISFYING: "SATISFYING",
  SOSO: "SOSO",
  UNSATISFYING: "UNSATISFYING",
  UNFILL_SATISFYING: "UNFILL_SATISFYING",
  UNFILL_SOSO: "UNFILL_SOSO",
  UNFILL_UNSATISFYING: "UNFILL_UNSATISFYING",
  LANDING_FEATURE_1: "LANDING_FEATURE_1",
  LANDING_FEATURE_2: "LANDING_FEATURE_2",
  LANDING_FEATURE_3: "LANDING_FEATURE_3",
  LANDING_FEATURE_4: "LANDING_FEATURE_4",
  SERVICE_APPLY_INTRO_1: "SERVICE_APPLY_INTRO_1",
  SERVICE_APPLY_INTRO_2: "SERVICE_APPLY_INTRO_2",
  SERVICE_APPLY_INTRO_3: "SERVICE_APPLY_INTRO_3",
  SERVICE_APPLY_CARD: "SERVICE_APPLY_CARD",
  SERVICE_APPLY_DELIVERY: "SERVICE_APPLY_DELIVERY",
  SERVICE_APPLY_DELIVERY_CARD: "SERVICE_APPLY_DELIVERY_CARD",
  SERVICE_APPLY_AUTH: "SERVICE_APPLY_AUTH",
  SERVICE_APPLY_IDENTIFICATION: "SERVICE_APPLY_IDENTIFICATION",
  IDENTIFICATION_ONBOARDING_1: "IDENTIFICATION_ONBOARDING_1",
  IDENTIFICATION_ONBOARDING_2: "IDENTIFICATION_ONBOARDING_2",
  IDENTIFICATION_ONBOARDING_3: "IDENTIFICATION_ONBOARDING_3",
  IDENTIFICATION_ONBOARDING_4: "IDENTIFICATION_ONBOARDING_4",
  SETTLEMENT_BAEMIN: "SETTLEMENT_BAEMIN",
  SETTLEMENT_BAEMIN_ONE: "SETTLEMENT_BAEMIN_ONE",
  SETTLEMENT_CARD_ANGLE: "SETTLEMENT_CARD_ANGLE",
  SETTLEMENT_COUPANG_EATS: "SETTLEMENT_COUPANG_EATS",
  SETTLEMENT_YOGIYO: "SETTLEMENT_YOGIYO",
  SETTLEMENT_ETC: "SETTLEMENT_ETC",
  SETTLEMENT_LOGO_BC: "SETTLEMENT_LOGO_BC",
  SETTLEMENT_LOGO_HANA: "SETTLEMENT_LOGO_HANA",
  SETTLEMENT_LOGO_HYUNDAI: "SETTLEMENT_LOGO_HYUNDAI",
  SETTLEMENT_LOGO_KB: "SETTLEMENT_LOGO_KB",
  SETTLEMENT_LOGO_NH: "SETTLEMENT_LOGO_NH",
  SETTLEMENT_LOGO_LOTTE: "SETTLEMENT_LOGO_LOTTE",
  SETTLEMENT_LOGO_SAMSUNG: "SETTLEMENT_LOGO_SAMSUNG",
  SETTLEMENT_LOGO_SHINHAN: "SETTLEMENT_LOGO_SHINHAN",
  SETTLEMENT_LOGO_WOORI: "SETTLEMENT_LOGO_WOORI",
  SETTLEMENT_MONEYBAG_GREEN: "SETTLEMENT_MONEYBAG_GREEN",
  SETTLEMENT_MONEYBAG_WARNING: "SETTLEMENT_MONEYBAG_WARNING",
  SETTLEMENT_RECEIPT_EARLYPAY: "SETTLEMENT_RECEIPT_EARLYPAY",
  SETTLEMENT_RECEIPT_MONEYBAG: "SETTLEMENT_RECEIPT_MONEYBAG",
  SETTLEMENT_SPEAKER: "SETTLEMENT_SPEAKER",
  SETTLEMENT_EMPTY_PAPER: "SETTLEMENT_EMPTY_PAPER",
  SETTLEMENT_QUESTION_MARK_IN_CIRCLE: "SETTLEMENT_QUESTION_MARK_IN_CIRCLE",
  BUSINESS_LICENSE: "BUSINESS_LICENSE",
  LICENSE_VERIFY_GUIDE_1: "LICENSE_VERIFY_GUIDE_1",
  LICENSE_VERIFY_GUIDE_2: "LICENSE_VERIFY_GUIDE_2",
  LICENSE_VERIFY_GUIDE_3: "LICENSE_VERIFY_GUIDE_3",
  LICENSE_VERIFY_GUIDE_4: "LICENSE_VERIFY_GUIDE_4",
  CARD_READER: "CARD_READER",
} as const;
export type ImageTypes = (typeof ImageTypes)[keyof typeof ImageTypes];
