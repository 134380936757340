import React from "react";

import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import Text from "@components/atoms/Text";

import { ColorsTypes } from "@/@types/ThemesType";

import { LandingBannerStack, LandingBannerWrapper } from "./index.styled";

export interface TopBannerProps {
  handleClickTrialButton: (title?: string, position?: string) => void;
}

const TopBanner = (props: TopBannerProps) => {
  return (
    <LandingBannerWrapper
      onClick={() =>
        props.handleClickTrialButton(
          "지금 이용 신청 시, 한 달 간 무료 혜택",
          "배너/하단 Floating 버튼",
        )
      }
    >
      <LandingBannerStack>
        <Text tag="SUBTITLE_2" weight="SEMI_BOLD" color="CONTENT_ON_COLOR">
          지금 이용 신청 시, 한 달 간 무료 혜택
        </Text>
        <Text tag="BODY_2" color="CONTENT_ON_COLOR">
          한 달 무료 이용하기
        </Text>
      </LandingBannerStack>
      <Icon
        tag={IconTags.ARROW_RIGHT}
        color={ColorsTypes.CONTENT_ON_COLOR}
        size={IconSizes.SM}
        styles={{ marginLeft: 2 }}
      />
    </LandingBannerWrapper>
  );
};

export default TopBanner;
