import styled from "styled-components";
import themes from "../../../styles/themes";

export const LandingHeader = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${themes.colors.BG_PRIMARY};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
  border-bottom: solid 0.25px ${themes.colors.BORDER_DEFAULT};
  position: sticky;
  top: 0;
  z-index: 9999;

  @media screen and (max-width: 1279px) {
    padding: 0 17.33px;
    height: 52px;

    .earlypay-landing-header-logo {
      width: 107px !important;
      height: 24px !important;
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoStack = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;

  .header-earlypay-symbol {
    width: 26.667px;
    height: 18.37px;

    @media screen and (max-width: 1279px) {
      width: 20px;
      height: 13.78px;
    }
  }

  .header-earlypay-wordmark {
    width: 100.44px;
    height: 21.33px;
    margin-left: 8px;

    @media screen and (max-width: 1279px) {
      width: 75.33px;
      height: 16px;
      margin-left: 4px;
    }
  }
`;

export const CustomButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .header-trial-button {
    @media screen and (max-width: 1279px) {
      display: none;
    }
  }
`;
