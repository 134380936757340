import React, { CSSProperties, forwardRef } from "react";
import {
  StackAlignTypes,
  StackDirectionsTypes,
  StackJustifyTypes,
} from "./type";
import { StyledStack } from "./index.styled";
import {
  changeAlignToStyles,
  changeDirectionToStyles,
  changeJustifyToStyles,
} from "@components/atoms/Stack/utils";

export interface StackProps {
  children: React.ReactNode;
  direction?: StackDirectionsTypes | string;
  spacing?: number;
  align?: StackAlignTypes | string;
  justify?: StackJustifyTypes | string;
  height?: string | number;
  width?: string | number;
  center?: boolean;
  styles?: CSSProperties;
  className?: string | Array<string>;
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      children,
      direction = StackDirectionsTypes.COL,
      spacing = 0,
      center = false,
      align = StackAlignTypes.START,
      justify = StackJustifyTypes.START,
      height,
      width,
      styles,
      className,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <StyledStack
        className={[
          ...(Array.isArray(className) ? className : [className]),
          "earlypay-stack",
        ].join(" ")}
        ref={ref}
        center={center}
        direction={changeDirectionToStyles(direction)}
        align={changeAlignToStyles(align)}
        justify={changeJustifyToStyles(justify)}
        spacing={spacing}
        height={height}
        width={width}
        styles={styles}
        {...otherProps}
      >
        {children}
      </StyledStack>
    );
  },
);

Stack.displayName = "Stack";

export default Stack;
