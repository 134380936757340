import { messageDummyDataProps } from "./type";

/** TODO: FAQ 데이터는 백엔드 API에서 호출하여 연동 필요 */
export const messageDummyData: messageDummyDataProps = {
  정산: {
    0: {
      label: "선정산 서비스는 무엇인가요?",
      content: `‘선정산 서비스’는 사업장에서 발생된 전일 배달앱 3사(배달의민족, 요기요, 쿠팡이츠), 카드사 매출을 익일 입금해 주는 서비스입니다.\n이용 시 신용도에 영향이 없으며 사장님의 현금 흐름을 개선해 주는 서비스입니다.`,
    },
    1: {
      label: "서비스 이용료는 얼마인가요?",
      content: `일 2,000원(VAT 별도)가 발생합니다.\n따로 자동 이체, 결제 카드 등록 필요 없이 지금 받으시는 정산금에서 일 2,000원을 차감하고 지급됩니다.`,
    },
    2: {
      label: "세금계산서도 발행되나요?",
      content: `매달 초, 얼리페이가 서비스 이용료(일 2,000원)에 대한 전자 세금계산서를 자동 발행됩니다.`,
    },
    3: {
      label: "선정산은 몇 시에 입금되나요?",
      content: `전일 매출의 선정산금은 다음날 오후 1시 이전에 받을 수 있습니다.`,
    },
  },
  서비스: {
    0: {
      label: "어떤 서비스를 제공하나요?",
      content: `소상공인 사업장 경영을 위한 3가지 솔루션을 제공합니다.\n
1. 선정산 서비스 - 사업장에서 발생한 배달 앱(배달의민족, 요기요) 및 카드사 매출을 다음 날에 입금해 드립니다.\n
2. 매출 통합 관리 서비스 - 사업장에서 발생한 배달 앱 및 카드사 매출 내역을 한 눈에 볼 수 있습니다.\n
3. 영업 배상책임 보험 - 한화 손해보험의 5만원 상당 영업 배상책임 보험(시설, 음식) 상품을 지원해 드립니다.`,
    },
    1: {
      label: "'매출 통합 관리 서비스’는 무엇인가요?",
      content: `‘매출 통합 관리 서비스’는 전일 발생한 배달 앱(배달의민족, 요기요) 및 카드사 매출 정보를 한 눈에 볼 수 있는 서비스입니다.\n
1. 사업장에서 발생한 모든 배달 앱(배달의민족, 요기요) 및 카드 매출 정보를 한 눈에 파악할 수 있습니다.\n
2. 카드 매출의 누락 및 미입금, 중복 결제, 고의 취소, 전산 오류 등을 확인하여, 매출 관리를 도와드립니다.\n
3. 카드사로부터 결제 대금 보류 및 누락 금액을 확인하여, 사장님의 소중한 매출을 지켜드립니다.`,
    },
    2: {
      label: "대출 기록 또는 신용 점수에 불이익은 없나요?",
      content:
        "가입 및 이용 시 신용 점수 하락 및 대출 기록이 전혀 발생하지 않습니다.",
    },
    3: {
      label: "주말(토,일)과 공휴일에도 선정산 되나요?",
      content: "주말, 공휴일 등 상관없이 매일 365일 정산됩니다.",
    },
    4: {
      label: "선정산은 언제부터 받을 수 있나요?",
      content: `서비스 가입 후 영업일 기준 2~3일 이후부터 선정산이 시작됩니다.\n
단, 각 플랫폼별(배달 앱, 카드사 등) 매출이 회수 통장(경남은행)으로 정상 입금이 확인된 시점부터 선정산이 시작되며, 따라서 모든 매출을 정산 받기까지는 다소 시간이 소요될 수 있습니다.`,
    },
    5: {
      label: "서비스 가입에 필요한 것은 무엇인가요?",
      content: `원활한 가입을 위해 3가지 서류가 필요합니다.\n
1. 신분증\n
2. 사업자등록증\n
3. 경남은행 통장 사본 (BNK 경남은행 모바일 APP으로 간편 개설 가능)\n
경남은행 통장은 선지급된 매출을 얼리페이가 회수하기 위한 목적으로 활용되며, 선정산금은 사장님의 주거래 통장(또는 입금 희망 통장)으로 받을 수 있습니다.\n
3가지 서류 준비 후 얼리페이 홈페이지<a href="https://earlypay.kr" target="_blank" rel="noreferrer">(www.earlypay.kr)</a>로 접속하여, 카카오톡 간편 가입을 통해 5분만에 가입할 수 있습니다.`,
    },
  },
  회원가입: {
    0: {
      label: "서비스 가입에 필요한 것은 무엇인가요?",
      content: `원활한 가입을 위해 3가지 서류가 필요합니다.\n
1. 신분증\n
2. 사업자등록증\n
3. 경남은행 통장 사본 (BNK 경남은행 모바일 APP으로 간편 개설 가능)\n
경남은행 통장은 선지급된 매출을 얼리페이가 회수하기 위한 목적으로 활용되며, 선정산금은 사장님의 주거래 통장(또는 입금 희망 통장)으로 받을 수 있습니다.\n
3가지 서류 준비 후 얼리페이 홈페이지<a href="https://earlypay.kr" target="_blank" rel="noreferrer">(www.earlypay.kr)</a>로 접속하여, 카카오톡 간편 가입을 통해 5분만에 가입할 수 있습니다.`,
    },
    1: {
      label: "왜 경남은행 계좌가 필요한가요?",
      content: `경남은행 통장은 선지급된 매출을 얼리페이가 회수하기 위한 목적으로 활용되며, 선정산금은 사장님의 주거래 통장(또는 입금 희망 통장)으로 받을 수 있습니다.\n
선정산 지급 및 회수 방식에 대한 설명은 해당 링크<a href="https://bit.ly/3uujxjX" target="_blank" rel="noreferrer">(https://bit.ly/3uujxjX)</a>에서 확인할 수 있습니다.`,
    },
    2: {
      label: "꼭 경남은행만 가능한가요?",
      content: `경남은행과 제휴를 통해 사장님의 매출 중복 회수 방지 등 더 안전한 서비스를 제공하고 있습니다.\n
현재는 회수 계좌로 타 은행 등록이 불가하며, 더 편리한 서비스 제공을 위해 타 은행과의 제휴를 준비하고 있습니다.`,
    },
    3: {
      label: "서비스 가입 조건이 어떻게 되나요?",
      content: `카드 매출이 발생 되는 소상공인 누구나 신청 가능합니다. 단, 내부 심사에 따라 가입이나 서비스가 제한될 수 있습니다.`,
    },
    4: {
      label: "대표자 명의로만 가입할 수 있나요?",
      content: `사업자등록증의 대표자 명의인 핸드폰으로만 가입 가능합니다.`,
    },
  },
};
