import { TooltipDirectionTypes } from "./type";

export const changeDirectionToWrapper = (direction: TooltipDirectionTypes) => {
  switch (direction) {
    case TooltipDirectionTypes.TOP:
      return {
        top: "0%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        flexDirection: "column-reverse",
      };
    case TooltipDirectionTypes.LEFT:
      return {
        top: "50%",
        left: "0%",
        transform: "translate(calc(-100% + 4px), -50%)",
        flexDirection: "row-reverse",
      };
    case TooltipDirectionTypes.BOTTOM:
      return {
        top: "0%",
        left: "50%",
        transform: "translate(-50%, calc(100% - 4px))",
        flexDirection: "column",
      };
    case TooltipDirectionTypes.RIGHT:
      return {
        top: "50%",
        right: "0%",
        transform: "translate(calc(100% - 4px), -50%)",
        flexDirection: "row",
      };
    default:
      return {
        top: "0%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        flexDirection: "column-reverse",
      };
  }
};

export const changeDirectionToPointer = (direction: TooltipDirectionTypes) => {
  switch (direction) {
    case TooltipDirectionTypes.TOP:
      return {
        transform: "rotate(180deg)",
      };
    case TooltipDirectionTypes.LEFT:
      return {
        transform: "rotate(90deg)",
        marginLeft: "-4px",
      };
    case TooltipDirectionTypes.BOTTOM:
      return {
        transform: "rotate(0deg)",
      };
    case TooltipDirectionTypes.RIGHT:
      return {
        transform: "rotate(270deg)",
        marginRight: "-4px",
      };
    default:
      return {
        transform: "rotate(180deg)",
      };
  }
};
