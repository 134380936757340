import { getPixelValue } from "@earlypay/shared/src/utils/types";
import "@ui/styles/index.scss";
import { changeSpacingToClassName } from "@ui/utils/className";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./PageContainer.module.scss";
import { PageContainerProps } from "./PageContainer.types";

/**
 * `PageContainer` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <PageContainer
 *   buttonType={"icon"}
 *   onBack={handleGoToBack}
 * >
 * 타이틀
 * </PageContainer>
 * ```
 */
export const PageContainer = forwardRef<HTMLElement, PageContainerProps>(
  function PageContainer(
    {
      className,
      as,
      children,
      width,
      height = "100%",
      padding = 20,
      margin,
      spacing,
      style,
      ...rest
    }: PageContainerProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const p = getPixelValue(padding);
    const m = getPixelValue(margin);
    const w = getPixelValue(width);
    const h = getPixelValue(height);

    const defaultStyle = {
      width: w,
      height: h,
      margin: m,
      padding: p,
    };

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.PageContainer,
          "earlybird-page-container",
          changeSpacingToClassName(spacing),
          className,
        )}
        style={{ ...style, ...defaultStyle }}
        {...rest}
      >
        {children}
      </BaseComponent>
    );
  },
);

export default PageContainer;
