import {
  DailyPlatformSalesSummaryParams,
  DailySalesDetailParams,
  DailySalesParams,
  DailySalesSummaryParams,
  SummarySalesParams,
} from "@earlypay/shared";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import {
  getDailyPlatformSalesSummary,
  getDailySales,
  getDailySalesDetail,
  getDailySalesSummary,
  getPlatformSales,
  getPlatformSalesSummary,
} from "@apis/endpoints";

import { salesQueryKeys } from "./queryKeys";

export const useFetchPlatformSales = (params: SummarySalesParams) => {
  const {
    data: fetchingData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: salesQueryKeys.fetchPlatformSales(params).queryKey,
    queryFn: ({ pageParam = 1 }) => {
      return getPlatformSales(params, pageParam);
    },
    select: data => ({
      pages: [
        {
          result: data?.pages?.flatMap(page => page.data),
          count: data?.pages[0]?.count || 0,
        },
      ],
      pageParams: data?.pageParams || [],
    }),
    getNextPageParam: lastPage => {
      if (lastPage?.next) {
        const match = lastPage.next.match(/[?&]page=(\d+)/);
        return match ? Number(match[1]) : null;
      }
      // 마지막 페이지일 경우 페이지 업데이트를 진행하지 않습니다.
      return null;
    },
    retry: 0,
    initialPageParam: 1,
  });

  const data = fetchingData?.pages[0].result;
  const totalCount = fetchingData?.pages[0].count || 0;

  return { data, totalCount, isLoading, fetchNextPage, hasNextPage };
};

export const useFetchPlatformSalesSummary = (params: SummarySalesParams) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchPlatformSalesSummary(params).queryKey,
    queryFn: () => getPlatformSalesSummary(params),
    retry: 0,
  });
};

export const useFetchSalesSummary = (params: SummarySalesParams) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchPlatformSalesSummary(params).queryKey,
    queryFn: () => getPlatformSalesSummary(params),
    retry: 0,
  });
};

// 특정 기간 동안의 일일 매출 정보를 가져오는 React Query fetch 함수입니다.
export const useFetchDailySales = (
  storeId: number,
  params: DailySalesParams,
) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchDailySales(params).queryKey,
    queryFn: () => getDailySales(storeId, params),
    retry: 0,
    staleTime: 10 * (60 * 1000), // 10 mins
    gcTime: 5 * (60 * 1000), // 5 mins
  });
};

// 특정 날짜에 따른 플랫폼 연동 상태와 대응하는 매출 정보를 가져오는 React Query fetch 함수입니다.
export const useFetchDailyPlatformsSalesSummary = (
  storeId: number,
  params: DailyPlatformSalesSummaryParams,
) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchDailyPlatformSales(params).queryKey,
    queryFn: () => getDailyPlatformSalesSummary(storeId, params),
    retry: 0,
    staleTime: 5 * (60 * 1000), // 5 mins
    gcTime: 1 * (60 * 1000), // 1 mins
  });
};

// 특정 날짜에 따른 일일 매출 요약 정보를 가져오는 React Query fetch 함수입니다.
export const useFetchDailySalesSummary = (
  storeId: number,
  params: DailySalesSummaryParams,
) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchDailySalesSummary(params).queryKey,
    queryFn: () => getDailySalesSummary(storeId, params),
    retry: 0,
    staleTime: 10 * (60 * 1000), // 10 mins
    gcTime: 5 * (60 * 1000), // 5 mins
  });
};

// 특정 날짜에 따른 일일 매출 상세 정보를 가져오는 React Query fetch 함수입니다.
// Infinite Scroll 구현을 통해 건별 매출 데이터를 일정 개수씩 가져옵니다.
export const useFetchDailySalesDetail = (
  storeId: number,
  params: DailySalesDetailParams,
) => {
  const {
    data: fetchingData,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: salesQueryKeys.fetchDailySalesDetail(params).queryKey,
    queryFn: ({ pageParam = 1 }) => {
      return getDailySalesDetail(storeId, params, pageParam);
    },
    select: data => ({
      pages: [
        {
          sales: data?.pages?.flatMap(page => page.sales),
          approvalCount: data?.pages[0]?.approvalCount || 0,
          cancelCount: data?.pages[0]?.cancelCount || 0,
        },
      ],
      pageParams: data?.pageParams || [],
    }),
    getNextPageParam: lastPage => {
      if (lastPage?.nextPage) {
        return lastPage?.nextPage;
      }
      // 마지막 페이지일 경우 페이지 업데이트를 진행하지 않습니다.
      return null;
    },
    retry: 0,
    initialPageParam: 1,
    staleTime: 10 * (60 * 1000), // 10 mins
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  const data = fetchingData?.pages[0]?.sales;
  const approvalCount = fetchingData?.pages[0].approvalCount || 0;
  const cancelCount = fetchingData?.pages[0].cancelCount || 0;

  return {
    data,
    cancelCount,
    approvalCount,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isError,
    error,
  };
};
