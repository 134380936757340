import React, { useEffect, useState } from "react";

// apis
// entryRouter
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// style
import styled from "styled-components";

import Container from "@components/layouts/Container";

import { getTermList } from "@apis/endpoints/policies";
import { instance } from "@apis/instance";

const Policy = () => {
  const navigate = useNavigate();
  const policy = useParams<{ policy: string }>();

  // set FAQ
  const [terms, setTerms] = useState([]);

  // FAQ를 가져와 저장합니다
  useEffect(() => {
    // 얼리페이 대부 약관은 404로 라우팅합니다.
    const earlypayTermsList = ["A000", "A001", "A002", "A003", "A004", "A010"];
    if (!earlypayTermsList.includes(policy.policy)) {
      navigate("/404");
      return;
    }

    const createFaq = async () => {
      Object.assign(instance.defaults, {
        headers: { authorization: "" },
      });
      const data = await getTermList({ type: policy.policy });
      setTerms(data);
    };
    createFaq();
  }, []);

  return (
    <Container>
      {terms && (
        <Term>
          <h1 style={{ fontWeight: "bold", marginBottom: "20px" }}>
            {terms[0]?.title}
          </h1>
          <div dangerouslySetInnerHTML={{ __html: terms[0]?.content }} />
        </Term>
      )}
    </Container>
  );
};

export default Policy;

const Term = styled.div`
  padding: 20px;
  overflow: scroll;
  height: 100vh;

  .se-text-paragraph {
    padding: 6px 0;
    line-height: 1 !important;
  }
`;
