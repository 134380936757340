import { atom } from "recoil";
import { v1 } from "uuid";

export const applicationState = atom({
  key: `ApplicationState/${v1()}`,
  default: {
    id: 0,
    approvedAt: "",
    isSubmittedDocuments: false,
    isSalesConnected: false,
    isHanaCapital: false,
    stage: "SALES_TYPE",
    status: "NEW",
    statusChangedAt: null,
    title: null,
    serviceType: "AL",
    businessRegistrationNumber: null,
    openingDate: null,
    address: null,
    detailAddress: null,
    user: {
      id: 0,
      name: "",
      phone: "",
      birthdate: "",
      gender: "",
      email: "",
      isCertified: false,
      isForeigner: false,
      recommenderCode: "",
      created: "",
      inflowPath: "",
      inflowMedium: "",
      inflowCampaign: "",
    },
    store: {
      id: 0,
      title: "",
      businessRegistrationNumber: "",
      openingDate: "",
      serviceType: "",
      address: "",
      detailAddress: "",
      postCode: "",
    },
    documents: {
      imageIdentification: "",
      imageBusinessRegistration: "",
      imageServiceAgreement: "",
      imageLoanServiceAgreement: "",
      imageKnbankAccount: "",
    },
    withdrawalAccount: {
      bank: "039",
      account: "",
      isArsAuthenticated: false,
    },
    depositAccount: {
      bank: "",
      account: "",
    },
    contactableTime: [""],
    isDeliveryAgentSkipped: false,
    isVanImageSkipped: false,
  },
});
