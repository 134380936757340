import styled from "styled-components";
import { ThemeType } from "@/@types/ThemesType";
import themes from "@styles/themes";

export const LandingBannerWrapper = styled.div<ThemeType>`
  width: 100%;
  background-color: ${themes.colors.BG_HIGHLIGHT};
  position: sticky;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const LandingBannerStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
