import styled from "styled-components";
import themes from "@styles/themes";

export const PriceWrapper = styled.div`
  width: 100%;
  background-color: ${themes.colors.BG_SECONDARY};
  padding: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1279px) {
    padding: 64px 16px;

    .earlypay-landing-title {
      font-size: 28px !important;
      line-height: 34px !important;
    }

    .earlypay-landing-content-text {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
`;

export const PriceTitleStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
