import React from "react";

import { BillingCode } from "@earlypay/shared/typings";
import { changeDepositorToCode } from "@pages/settlement/utils";

import {
  CardSalesBottomSheet,
  UnderDepositBottomSheet,
} from "@components/features/settlement/SettlementBottomSheet";
import DeliverySalesBottomSheet from "@components/features/settlement/SettlementBottomSheet/DeliverySalesBottomSheet";

import useSettlement from "@hooks/useSettlement";

import { useFetchCardSales, useFetchTotalSales } from "@apis/hooks";

/** 각 입금사 별로 항목을 클릭하면 해당 세부 내역이 담긴 바텀 시트를 보여줍니다. */
export const DepositorsBottomSheets: React.FC = () => {
  const { filterParams, depositorParams, startDateParams, endDateParams } =
    useSettlement();
  const depositor = changeDepositorToCode(depositorParams);
  const billingCode = filterParams;

  const { data: salesData, isLoading: isTotalSalesLoading } =
    useFetchTotalSales(
      {
        startDate: startDateParams,
        endDate: endDateParams,
      },
      depositor,
    );
  const { data: cardData, isLoading: isCardSalesLoading } = useFetchCardSales({
    startDate: startDateParams,
    endDate: endDateParams,
  });

  return (
    <>
      {(billingCode === BillingCode.CARD.TOTAL_SALES ||
        billingCode === BillingCode[depositor]["CARD_SALES"]) && (
        <CardSalesBottomSheet data={cardData} isLoading={isCardSalesLoading} />
      )}
      {billingCode === BillingCode[depositor]["TOTAL_SALES"] && (
        <DeliverySalesBottomSheet
          depositor={depositor}
          data={salesData}
          isLoading={isTotalSalesLoading}
        />
      )}
      {(billingCode === BillingCode[depositor]["UNDER_DEPOSIT"] ||
        billingCode === BillingCode.ETC.ETC_UNDER_DEPOSIT) && (
        <UnderDepositBottomSheet depositor={depositor} />
      )}
    </>
  );
};

export default DepositorsBottomSheets;
