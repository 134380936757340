import React from "react";

import themes from "@styles/themes";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import { ThemeProvider } from "styled-components";

import * as serviceWorkerRegistration from "@utils/serviceWorkerRegistration";

import App from "./App";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider
    theme={{
      ...themes,
      colors: themes.colors,
    }}
  >
    <App />
  </ThemeProvider>,
);

serviceWorkerRegistration.register();
