import React, { CSSProperties, useRef, useState } from "react";
import { ImageTypes } from "./type";
import { changeStringToImage } from "./utils";
import {
  ImageWrapper,
  LoadingImage,
  StyledImage,
} from "@components/atoms/Image/index.styled";

export interface ImageProps {
  tag?: ImageTypes;
  src?: string;
  alt?: string;
  onLoad?: () => void;
  onError?: () => void;
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  objectFit?: string;
  isLoading?: boolean;
  className?: string | Array<string>;
  styles?: CSSProperties;
}

export const Image: React.FC<ImageProps> = ({
  tag,
  src,
  alt = "얼리페이 이미지",
  onLoad,
  isLoading = true,
  onError,
  width = "auto",
  height = "auto",
  maxWidth,
  minHeight,
  objectFit = "contain",
  className,
  styles,
  ...otherProps
}) => {
  const imgRef = useRef<HTMLImageElement>();
  const [loaded, setLoaded] = useState(isLoading);

  const handleImageLoad = () => {
    setLoaded(false);
    onLoad && onLoad();
  };

  return (
    <ImageWrapper
      width={width}
      height={height}
      onLoad={handleImageLoad}
      onError={onError}
      maxWidth={maxWidth}
      minHeight={minHeight}
    >
      <StyledImage
        className={[
          ...(Array.isArray(className) ? className : [className]),
          "earlypay-image",
        ].join(" ")}
        ref={imgRef}
        src={tag ? changeStringToImage(tag) : src}
        alt={alt}
        width={width}
        height={height}
        onLoad={handleImageLoad}
        onError={onError}
        maxWidth={maxWidth}
        minHeight={minHeight}
        objectFit={objectFit}
        styles={styles}
        cssMotion={!loaded}
        {...otherProps}
      />
      <LoadingImage
        cssMotion={loaded}
        width={imgRef.current?.width ?? width}
        height={imgRef.current?.height ?? height}
        maxWidth={maxWidth}
        minHeight={minHeight}
        objectFit={objectFit}
        {...otherProps}
      />
    </ImageWrapper>
  );
};

export default Image;
