import React from "react";

import { appStatusState } from "@recoil/appStatus/atoms";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Container, ErrorScreen, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

const Forbidden = () => {
  const appStatus = useRecoilValue(appStatusState);
  const navigate = useNavigate();

  const handleGoToPrevious = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Meta title={`Forbidden`} />
      <Header hasLeftButton={false} hasRightButton={appStatus.isLoggedIn} />
      <PageContainer>
        <ErrorScreen type={"403"} handleGoToPrevious={handleGoToPrevious} />
      </PageContainer>
    </Container>
  );
};

export default Forbidden;
