import {
  BAEMIN_FIND_ACCOUNT,
  COUPANG_FIND_ID,
  COUPANG_FIND_PW,
  YOGIYO_FIND_ID,
  YOGIYO_FIND_PW,
} from "@earlypay/shared";
import { DeliveryCode, DeliveryNames } from "@earlypay/shared/typings";
import { HStack, Image, Input, Text, VStack } from "@ui/components/atoms";
import { ActionButton, PageHeader } from "@ui/components/layouts";
import { AccountInput } from "@ui/components/organisms/AccountInput";
import { getPlatformImage } from "@ui/components/templates/DeliveryAccountModal/utils";
import "@ui/styles/index.scss";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import styles from "./DeliveryAccountModal.module.scss";
import { DeliveryAccountModalProps } from "./DeliveryAccountModal.types";

/**
 * `DeliveryAccountModal` 는 배달앱 계정을 등록 및 수정, 삭제할 수 있는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <DeliveryAccountModal
 *   visible={true}
 *   onClose={onClose}
 *   platform={"BAEMIN"}
 * />
 * ```
 */
export const DeliveryAccountModal = forwardRef<
  HTMLElement,
  DeliveryAccountModalProps
>(function DeliveryAccountModal(
  {
    className,
    as,
    platform = DeliveryCode.BAEMIN,
    visible,
    onClose,
    loading,
    isDeletable = true,
    hasTitle = true,
    handleAddDeliveryAccount,
    handleDeleteDeliveryAccount,
    handleEditDeliveryAccount,
    oldId,
    value,
    isEditableId = true,
    disabled,
    handleChange,
    ...rest
  }: DeliveryAccountModalProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";

  const oldTitle = !isEditableId
    ? "아이디"
    : oldId && `현재 ${DeliveryNames[platform]} 계정`;
  const title = !isEditableId
    ? "비밀번호"
    : oldId && `변경할 ${DeliveryNames[platform]} 계정`;

  const handleUpdateAccount = () => {
    if (!!oldId) {
      return handleEditDeliveryAccount(platform);
    }

    handleAddDeliveryAccount(platform);
  };

  if (!visible) {
    return;
  }

  const FindAccountLink = ({
    label,
    link,
  }: {
    label: string;
    link: string;
  }) => (
    <a href={link} target={"_blank"} style={{ textDecoration: "none" }}>
      <Text bold typo={"body-3"} color={"content-tertiary"}>
        {label}
      </Text>
    </a>
  );

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.DeliveryAccountModal,
        "earlybird-delivery-account-modal",
        className,
      )}
    >
      <PageHeader
        buttonType={"mixed"}
        rightActionLabel={"계정 삭제"}
        hasRightButton={isDeletable}
        handleClickRightButton={handleDeleteDeliveryAccount}
        handleClickLeftButton={onClose}
        hasChat={false}
      ></PageHeader>
      <VStack height={"100%"} padding={20} spacing={16} align={"center"}>
        <Image width={200} height={40} src={getPlatformImage(platform)} />

        <VStack spacing={8}>
          {oldId && <Input readonly value={oldId} title={oldTitle} />}

          <VStack spacing={6}>
            <AccountInput
              title={title}
              value={value}
              name={{
                id: !isEditableId && oldId ? null : "id",
                password: "password",
              }}
              placeholder={{
                id: oldId ? "변경할 아이디 입력" : "아이디 입력",
                password: oldId ? "변경할 비밀번호 입력" : "비밀번호 입력",
              }}
              handleChange={handleChange}
            />
            {platform === DeliveryCode.BAEMIN ? (
              <FindAccountLink
                link={BAEMIN_FIND_ACCOUNT}
                label={"아이디/비밀번호 찾기"}
              />
            ) : (
              <HStack spacing={5}>
                <FindAccountLink
                  link={
                    platform === DeliveryCode.YOGIYO
                      ? YOGIYO_FIND_ID
                      : COUPANG_FIND_ID
                  }
                  label={"아이디 찾기"}
                />
                <Text color={"content-tertiary"} typo={"body-3"}>
                  |
                </Text>
                <FindAccountLink
                  link={
                    platform === DeliveryCode.YOGIYO
                      ? YOGIYO_FIND_PW
                      : COUPANG_FIND_PW
                  }
                  label={"비밀번호 찾기"}
                />
              </HStack>
            )}
          </VStack>
        </VStack>
      </VStack>

      <ActionButton
        type={"button"}
        primaryButtonLabel={oldId ? "변경하기" : "연결하기"}
        onClickPrimaryButton={handleUpdateAccount}
        loading={loading}
        disabledPrimaryButton={disabled}
        primaryButtonProperty={{
          description: oldId
            ? "배달앱 계정 변경 버튼"
            : "배달앱 계정 입력 버튼",
        }}
      />
    </BaseComponent>
  );
});

export default DeliveryAccountModal;
