import {
  getFormattedDateFullKR,
  getFormattedDateKR,
  getFormattedDateWithDash,
} from "@utils/date";
import { useLocation, useNavigate } from "react-router-dom";

export const useSettlement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const prevPath = location.state?.from;

  const filterParams: string = searchParams.get("filter");
  const depositorParams: string = searchParams.get("depositor");
  const typeParams: string = searchParams.get("type");
  const targetParams: string = searchParams.get("target");
  const cardParams: string = searchParams.get("card");
  const deliveryParams: string = searchParams.get("delivery");
  const currentUrl = `${location.pathname}${
    searchParams.toString().length ? `?${searchParams.toString()}` : ""
  }`;

  /** startDate, endDate 쿼리 파라미터 값을 읽어서 정산내역 시작 날짜와 끝 날짜를 반환합니다. */
  const getFormattedSettlementDate = (query: string) => {
    const dateParams = searchParams.get(query);

    // 1. 날짜 형식을 정규표현식을 통해 검증합니다.
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateParams)) {
      return getFormattedDateWithDash();
    }

    // 2. 날짜 객체를 생성하고 유효한 날짜인지 확인합니다.
    const date = new Date(dateParams);
    const timestamp = date.getTime();
    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
      return getFormattedDateWithDash();
    }

    // 3. 각 년도, 월, 일자가 올바른 날짜 포맷인지 확인합니다.
    const [year, month, day] = dateParams.split("-").map(Number);
    if (
      date.getUTCFullYear() !== year ||
      date.getUTCMonth() + 1 !== month ||
      date.getUTCDate() !== day
    ) {
      return getFormattedDateWithDash();
    }

    return searchParams.get(query);
  };

  const startDateParams = getFormattedSettlementDate("startDate");
  const endDateParams = getFormattedSettlementDate("endDate");

  /** 파라미터 값을 가져와서 "OO년 O월 O일" 포맷으로 날짜를 반환하는 함수입니다.  */
  const getFormattedDate = () => {
    const today = new Date();
    const hasDateParams =
      searchParams.has("startDate") || searchParams.has("endDate");

    // 선택한 날짜 파라미터가 존재하는지 확인
    if (hasDateParams) {
      // 선택한 기간이 존재하는지 확인
      if (startDateParams === endDateParams) {
        // 동일한 날짜 선택한 경우
        return getFormattedDateKR(startDateParams, 2);
      }

      const duration = `${getFormattedDateKR(
        startDateParams,
        2,
      )}~${getFormattedDateKR(endDateParams, 2)}`;

      return duration;
    }

    return getFormattedDateKR(today);
  };

  /** 파라미터 값을 가져와서 "O00O년 O월 O일" 포맷으로 날짜를 반환하는 함수입니다.  */
  const getFormattedDateFull = () => {
    const hasDateParams =
      searchParams.has("startDate") || searchParams.has("endDate");

    // 선택한 날짜 파라미터가 존재하는지 확인
    if (hasDateParams) {
      // 선택한 기간이 존재하는지 확인
      if (startDateParams === endDateParams) {
        // 동일한 날짜 선택한 경우
        return getFormattedDateFullKR(startDateParams);
      }

      return `${getFormattedDateFullKR(
        startDateParams,
      )}~${getFormattedDateFullKR(endDateParams)}`;
    }

    const today = new Date();

    return getFormattedDateFullKR(today);
  };

  /** 정산내역 Billing 바텀시트를 종료합니다. */
  const handleCloseBillingBottomSheet = () => {
    searchParams.delete("filter");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return {
    handleCloseBillingBottomSheet,
    getFormattedDateFull,
    getFormattedDate,
    currentUrl,
    searchParams,
    filterParams,
    depositorParams,
    typeParams,
    startDateParams,
    endDateParams,
    targetParams,
    cardParams,
    deliveryParams,
    prevPath,
  };
};

export default useSettlement;
