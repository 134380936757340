import { IconSizes, IconTags } from "@components/atoms/Icon/type";

import { ReactComponent as IconArrowLeft } from "@assets/icons/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "@assets/icons/icon-arrow-right.svg";
import { ReactComponent as IconChevronDown } from "@assets/icons/icon-chevron-down.svg";
import { ReactComponent as IconChevronLeft } from "@assets/icons/icon-chevron-left.svg";
import { ReactComponent as IconChevronRight } from "@assets/icons/icon-chevron-right.svg";
import { ReactComponent as IconChevronUp } from "@assets/icons/icon-chevron-up.svg";
import { ReactComponent as IconEarlypayLogo } from "@assets/icons/icon-earlypay-logo.svg";
import { ReactComponent as IconEarlyPaySymbol } from "@assets/icons/icon-earlypay-symbol.svg";
import { ReactComponent as IconEarlyPayWordmark } from "@assets/icons/icon-earlypay-wordmark.svg";
import { ReactComponent as IconMessage } from "@assets/icons/icon-message.svg";
import { ReactComponent as IconTooltipPointer } from "@assets/icons/icon-tootip-pointer.svg";
import { ReactComponent as IconCloseMark } from "@assets/icons/icon-close-mark.svg";
import { ReactComponent as IconErrorMessage } from "@assets/icons/icon-error-message.svg";
import { ReactComponent as IconKakaoLogo } from "@assets/icons/icon-kakao-logo.svg";
import { ReactComponent as IconWarning } from "@assets/icons/icon-warning.svg";
import { ReactComponent as IconWarningFill } from "@assets/icons/icon-warning-fill.svg";
import { ReactComponent as IconCamera } from "@assets/icons/icon-camera.svg";
import { ReactComponent as IconGallery } from "@assets/icons/icon-gallery.svg";
import { ReactComponent as IconHome } from "@assets/icons/icon-home-solid.svg";
import { ReactComponent as IconCalendar } from "@assets/icons/icon-calendar-solid.svg";
import { ReactComponent as IconUser } from "@assets/icons/icon-user-solid.svg";
import { ReactComponent as IconInvoice } from "@assets/icons/icon-invoice-solid.svg";
import { ReactComponent as IconHeadphones } from "@assets/icons/icon-headphones.svg";
import { ReactComponent as IconLogoEarlypaySignature } from "@assets/icons/icon-logo-earlypay-signature.svg";
import { ReactComponent as IconChevronDownSolid } from "@assets/icons/icon-chevron-down-solid.svg";
import { ReactComponent as IconCircleQuestionMarkSolid } from "@assets/icons/icon-circle-question-mark-solid.svg";
import { ReactComponent as IconSquare } from "@assets/icons/icon-square.svg";
import { ReactComponent as IconSquareCheckSolid } from "@assets/icons/icon-square-check-solid.svg";
import { ReactComponent as IconSnowFlake } from "@assets/icons/icon-snowflake.svg";
import { ReactComponent as IconReload } from "@assets/icons/icon-reload.svg";
import { ReactComponent as IconDiagonalArrows } from "@/assets/icons/icon-diagonal-arrows.svg";

export const changeSizeToNumber = (size: IconSizes) => {
  switch (size) {
    case IconSizes.XXXL:
      return 48;
    case IconSizes.XXL:
      return 40;
    case IconSizes.XL:
      return 32;
    case IconSizes.LG:
      return 28;
    case IconSizes.MD:
      return 24;
    case IconSizes.SM:
      return 20;
    case IconSizes.XS:
      return 16;
    default:
      return 24;
  }
};

export const changeTagToIconComponent = (tag: IconTags) => {
  switch (tag) {
    case IconTags.ARROW_RIGHT:
      return IconArrowRight;
    case IconTags.EARLYPAY_SYMBOL:
      return IconEarlyPaySymbol;
    case IconTags.EARLYPAY_WORDMARK:
      return IconEarlyPayWordmark;
    case IconTags.CHEVRON_RIGHT:
      return IconChevronRight;
    case IconTags.CHEVRON_LEFT:
      return IconChevronLeft;
    case IconTags.CHEVRON_UP:
      return IconChevronUp;
    case IconTags.CHEVRON_DOWN:
      return IconChevronDown;
    case IconTags.EARLYPAY_LOGO:
      return IconEarlypayLogo;
    case IconTags.MESSAGE:
      return IconMessage;
    case IconTags.ARROW_LEFT:
      return IconArrowLeft;
    case IconTags.TOOLTIP_POINTER:
      return IconTooltipPointer;
    case IconTags.CLOSE_MARK:
      return IconCloseMark;
    case IconTags.ERROR_MESSAGE:
      return IconErrorMessage;
    case IconTags.KAKAO_LOGO:
      return IconKakaoLogo;
    case IconTags.WARNING:
      return IconWarning;
    case IconTags.WARNING_FILL:
      return IconWarningFill;
    case IconTags.CAMERA:
      return IconCamera;
    case IconTags.GALLERY:
      return IconGallery;
    case IconTags.HOME_SOLID:
      return IconHome;
    case IconTags.CALENDAR_SOLID:
      return IconCalendar;
    case IconTags.USER_SOLID:
      return IconUser;
    case IconTags.INVOICE_SOLID:
      return IconInvoice;
    case IconTags.HEADPHONES:
      return IconHeadphones;
    case IconTags.LOGO_EARLYPAY_SIGNATURE:
      return IconLogoEarlypaySignature;
    case IconTags.CHEVRON_DOWN_SOLID:
      return IconChevronDownSolid;
    case IconTags.CIRCLE_QUESTION_MARK_SOLID:
      return IconCircleQuestionMarkSolid;
    case IconTags.SQUARE:
      return IconSquare;
    case IconTags.SQUARE_CHECK_SOLID:
      return IconSquareCheckSolid;
    case IconTags.SNOWFLAKE:
      return IconSnowFlake;
    case IconTags.RELOAD:
      return IconReload;
    case IconTags.DIAGONAL_ARROWS:
      return IconDiagonalArrows;
    default:
      return IconEarlyPaySymbol;
  }
};
