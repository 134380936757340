export const PlatformCode = {
  BAEMIN: "BAEMIN",
  COUPANG: "COUPANG",
  YOGIYO: "YOGIYO",
  CARD: "CARD",
};
export type PlatformCodeTypes =
  (typeof PlatformCode)[keyof typeof PlatformCode];

export const PlatformNames: { [key in PlatformCodeTypes]: string } = {
  [PlatformCode.CARD]: "카드",
  [PlatformCode.BAEMIN]: "배달의민족",
  [PlatformCode.YOGIYO]: "요기요",
  [PlatformCode.COUPANG]: "쿠팡이츠",
};

// 플랫폼 계정 상태
// TODO: 백엔드 API 업데이트 이후 일부 필드는 사용하지 않기 때문에 지워야 합니다.
export const PlatformAccountStatusCode = {
  // 레거시 (삭제 예정)
  FULLY_CONNECTED: "FULLY_CONNECTED",
  LOGIN_FAILED: "LOGIN_FAILED",
  CONNECTED_SALES_ONLY: "CONNECTED_SALES_ONLY",
  UNCONNECTED: "UNCONNECTED",
  // --------------------------------------------
  UNREGISTERED: "UNREGISTERED", // 계정 등록 안함
  VALID: "VALID", // 사용 가능
  INVALID: "INVALID", // 로그인 불가
  SUSPENDED: "SUSPENDED", // 사용 정지
  DISABLED: "DISABLED", // 비활성화
};
export type PlatformAccountStatusCodeTypes =
  (typeof PlatformAccountStatusCode)[keyof typeof PlatformAccountStatusCode];

export const PlatformSalesStatusCode = {
  PENDING: "PENDING", // 매출 수집 중
  COMPLETE: "COMPLETE", // 매출 수집 완료
  POSTPONED: "POSTPONED", // 내일 매출 수집 예정
};
export type PlatformSalesStatusCodeTypes =
  (typeof PlatformSalesStatusCode)[keyof typeof PlatformSalesStatusCode];
