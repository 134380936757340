import React from "react";

import { DepositorNames } from "@earlypay/shared/typings";
import { changeTypeToTitle } from "@pages/settlement/utils";
import { changeDepositorToCode } from "@pages/settlement/utils";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Text from "@components/atoms/Text";
import {
  AdditionalDepositAmountList,
  UnderDepositAmountList,
} from "@components/features/settlement/SettlementAmountList";
import { Header, Meta } from "@components/layouts";

import useSettlement from "@hooks/useSettlement";

import { useFetchAdditionalDeposit, useFetchUnderDeposits } from "@apis/hooks";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

export const Detail: React.FC = () => {
  const navigate = useNavigate();
  const {
    depositorParams,
    typeParams,
    startDateParams,
    endDateParams,
    prevPath,
  } = useSettlement();
  const depositor = changeDepositorToCode(depositorParams);
  const title = `${
    depositorParams && depositorParams !== "etc"
      ? `${DepositorNames[depositor]} `
      : ""
  }${changeTypeToTitle(typeParams)} 상세내역`;

  const { data: underDepositData, isLoading: isUnderDepositLoading } =
    useFetchUnderDeposits({
      startDate: startDateParams,
      endDate: endDateParams,
      depositor: depositor,
    });
  const { data: additionalDepositData, isLoading: isAdditionalDepositLoading } =
    useFetchAdditionalDeposit({
      startDate: startDateParams,
      endDate: endDateParams,
    });
  const isLoading = isUnderDepositLoading || isAdditionalDepositLoading;

  const additionalDepositList = additionalDepositData?.items;
  const underDepositList = underDepositData?.items;
  const totalAmounts =
    typeParams === "under-deposit"
      ? underDepositData?.totalAmount
      : additionalDepositData?.totalAmount;

  const handleGoToBack = () => {
    if (!prevPath) {
      return navigate(
        `/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${depositorParams}`,
      );
    }

    return navigate(prevPath);
  };

  return (
    <Container>
      <Meta title={`얼리페이 | 정산내역`} name={title} />
      <Header handleClickLeftButton={handleGoToBack} />
      <TitleWrapper>
        <Text tag={FontTypes.BODY_2} color={ColorsTypes.CONTENT_TERTIARY} bold>
          {title}
        </Text>
        {/** 총 합계를 보여줍니다. */}
        <Text tag={FontTypes.TITLE_3} bold>
          {isLoading ? (
            <Skeleton containerClassName="flex-1" height={28} width={120} />
          ) : (
            changeNumberToMoney(totalAmounts)
          )}
        </Text>
      </TitleWrapper>
      <Divider />
      <Wrapper>
        {/** url params 의 type 값이 "under-deposit"일 경우, 카드 및 배달사 차감금 상세 내역 리스트를 보여줍니다. */}
        {typeParams === "under-deposit" && (
          <UnderDepositAmountList
            list={underDepositList}
            isLoading={isLoading}
          />
        )}
        {/** url params 의 type 값이 "additional-deposit"일 경우, 추가입금 상세 내역 리스트를 보여줍니다.*/}
        {typeParams === "additional-deposit" && (
          <AdditionalDepositAmountList
            list={additionalDepositList}
            isLoading={isLoading}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default Detail;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: scroll;
  cursor: default;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 16px;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  flex-shrink: 0;
`;
