import { FastCalculateWrapper, TitleStack } from "./index.styles";
import React from "react";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes, FontWeightTypes } from "@/@types/ThemesType";

const FastCalculate = () => {
  return (
    <FastCalculateWrapper>
      <TitleStack>
        <Text
          className={"earlypay-landing-title"}
          as="h1"
          tag={FontTypes.TITLE_1}
          weight={FontWeightTypes.SEMI_BOLD}
        >
          최대 14일 걸리던 매출 정산
        </Text>
        <Text
          className={"earlypay-landing-title"}
          as="h1"
          tag={FontTypes.TITLE_1}
          weight={FontWeightTypes.SEMI_BOLD}
          color={ColorsTypes.CONTENT_HIGHLIGHT}
        >
          단 하루만에
        </Text>
      </TitleStack>
      <Text
        tag={FontTypes.BODY_2}
        color={ColorsTypes.CONTENT_SECONDARY}
        center
        as="h4"
      >
        최대 14일 걸리던 배달 매출을 단 하루만에
        <br />
        최대 93% 빠르게 입금
      </Text>
    </FastCalculateWrapper>
  );
};

export default FastCalculate;
