import themes from "@/styles/themes";
import React from "react";
import { IconSizes, IconTags } from "./type";
import { ColorsTypes } from "@/@types/ThemesType";

import {
  changeSizeToNumber,
  changeTagToIconComponent,
} from "@components/atoms/Icon/utils";

export interface IconProps {
  className?: string;
  tag: IconTags;
  color?: ColorsTypes;
  size?: IconSizes;
  width?: number;
  height?: number;
  styles?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = ({
  className,
  tag,
  color = ColorsTypes.CONTENT_PRIMARY,
  size = IconSizes.MD,
  width,
  height,
  styles,
}) => {
  const SvgIcon = changeTagToIconComponent(tag);

  return (
    <SvgIcon
      className={[
        ...(Array.isArray(className) ? className : [className]),
        "earlypay-icon",
      ].join(" ")}
      width={width ? width : changeSizeToNumber(size)}
      height={height ? height : changeSizeToNumber(size)}
      color={themes.colors[color]}
      style={{ ...styles }}
    />
  );
};

Icon.displayName = "Icon";

export default Icon;
