import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Box, Calendar, HStack, Text, VStack } from "@earlybird/ui";

import { changeNumberToMoney } from "@/utils/types";

export interface SalesCalendarProps {
  salesAmount: number;
  selectedDate: Date;
  currentYear: number;
  currentMonth: number;
  startDate: Date;
  endDate: Date;
  loading: boolean;
  sales: { [key: string]: number };
  handleNavigateMonth: (date: Date) => void;
  handleNavigateDate: (date: Date) => void;
}

export const SalesCalendar = ({
  salesAmount,
  selectedDate,
  currentMonth,
  currentYear,
  startDate,
  endDate,
  loading,
  sales,
  handleNavigateMonth,
  handleNavigateDate,
}: SalesCalendarProps) => {
  const formattedDailySales = Object.entries(sales).map(([date, value]) => ({
    date: date,
    amounts: [value],
  }));
  const currentDate = new Date(`${currentYear}-${currentMonth}-1`);

  return (
    <VStack>
      <Calendar
        displayMode={"month"}
        selectedDate={selectedDate}
        currentDate={currentDate}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        dailyContentsType={"amount"}
        dailyAmountContents={formattedDailySales}
        handleNavigateDate={handleNavigateDate}
        handleNavigateDateHeader={handleNavigateMonth}
      >
        <SalesBox>
          <HStack justify={"between"} align={"center"}>
            <Text type={"body-3"} bold color={"content-tertiary"}>
              총 매출액
            </Text>
            {loading ? (
              <Skeleton
                baseColor="#EAEAEA"
                containerClassName="flex-1"
                width={120}
                height={20}
              />
            ) : (
              <Text bold>{changeNumberToMoney(salesAmount)}</Text>
            )}
          </HStack>
        </SalesBox>
      </Calendar>
    </VStack>
  );
};

export default SalesCalendar;

const SalesBox = styled(Box).attrs(() => ({
  width: "100%",
  radius: "md",
  padding: "8px 20px",
  bg: "bg-secondary",
}))``;
