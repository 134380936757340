import React from "react";

import { BillingCode, DepositorCode } from "@earlypay/shared/typings";
import { changeEtcFilterToCode } from "@pages/settlement/Billing/utils";

import {
  EtcAdditionalDepositBottomSheet,
  EtcFinancialInterestBottomSheet,
  EtcServiceFeeBottomSheet,
  UnderDepositBottomSheet,
} from "@components/features/settlement/SettlementBottomSheet";

import useSettlement from "@hooks/useSettlement";

import {
  useFetchEarlypaysEtcDetail,
  useFetchUnderDepositsSummary,
} from "@apis/hooks";

/* 그 외 항목을 클릭하면 해당하는 바텀 시트를 보여줍니다. */
export const EtcBottomSheets: React.FC = () => {
  const { filterParams, startDateParams, endDateParams } = useSettlement();
  const billingCode = filterParams;
  const filter = changeEtcFilterToCode(billingCode);

  /* 선정산 그 외 항목 중 `얼리페이 이용료`, `선정산 이자`, `추가입금` 내역을 호출합니다. */
  const { data: earlypaysEtcDetail, isLoading: isEtcLoading } =
    useFetchEarlypaysEtcDetail(
      {
        startDate: startDateParams,
        endDate: endDateParams,
      },
      filter,
    );
  /* 선정산 그 외 항목 중 `기타 차감금` 내역을 호출합니다. */
  const { data: underDepositsSummary, isLoading: isUnderDepositLoading } =
    useFetchUnderDepositsSummary({
      startDate: startDateParams,
      endDate: endDateParams,
      depositor: DepositorCode.ETC,
    });
  const underDepositsEtcData =
    !isUnderDepositLoading && underDepositsSummary[DepositorCode.ETC];

  return (
    <>
      {billingCode === BillingCode.ETC.SERVICE_FEE && (
        <EtcServiceFeeBottomSheet
          data={earlypaysEtcDetail}
          isLoading={isEtcLoading}
        />
      )}
      {billingCode === BillingCode.ETC.FINANCIAL_INTEREST && (
        <EtcFinancialInterestBottomSheet
          data={earlypaysEtcDetail}
          isLoading={isEtcLoading}
        />
      )}
      {billingCode === BillingCode.ETC.ADDITIONAL_DEPOSIT && (
        <EtcAdditionalDepositBottomSheet
          data={earlypaysEtcDetail}
          isLoading={isEtcLoading}
        />
      )}
      {billingCode === BillingCode.ETC.ETC_UNDER_DEPOSIT && (
        <UnderDepositBottomSheet depositor={DepositorCode.ETC} />
      )}
    </>
  );
};

export default EtcBottomSheets;
