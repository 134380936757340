export const StackDirectionsTypes = {
  ROW: "ROW",
  COL: "COL",
  ROW_REVERSE: "ROW_REVERSE",
} as const;
export type StackDirectionsTypes =
  (typeof StackDirectionsTypes)[keyof typeof StackDirectionsTypes];

export const StackAlignTypes = {
  START: "START",
  END: "END",
  CENTER: "CENTER",
  BETWEEN: "BETWEEN",
  AROUND: "AROUND",
} as const;
export type StackAlignTypes =
  (typeof StackAlignTypes)[keyof typeof StackAlignTypes];

export const StackJustifyTypes = {
  START: "START",
  END: "END",
  CENTER: "CENTER",
  BETWEEN: "BETWEEN",
  STRETCH: "STRETCH",
} as const;
export type StackJustifyTypes =
  (typeof StackJustifyTypes)[keyof typeof StackJustifyTypes];
