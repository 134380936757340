export const ColorsTypes = {
  /** Core */
  BG_PRIMARY: "BG_PRIMARY",
  BG_SECONDARY: "BG_SECONDARY",
  BG_TERTIARY: "BG_TERTIARY",
  BG_PRIMARY_INVERSE: "BG_PRIMARY_INVERSE",
  BG_SECONDARY_INVERSE: "BG_SECONDARY_INVERSE",
  BG_TERTIARY_INVERSE: "BG_TERTIARY_INVERSE",

  /** Core Extention */
  BG_HIGHLIGHT: "BG_HIGHLIGHT",
  BG_NEGATIVE: "BG_NEGATIVE",
  BG_WARNING: "BG_WARNING",
  BG_POSITIVE: "BG_POSITIVE",
  BG_DISABLED: "BG_DISABLED",
  BG_HIGHLIGHT_LIGHT: "BG_HIGHLIGHT_LIGHT",
  BG_NEGATIVE_LIGHT: "BG_NEGATIVE_LIGHT",
  BG_WARNING_LIGHT: "BG_WARNING_LIGHT",
  BG_POSITIVE_LIGHT: "BG_POSITIVE_LIGHT",
  BG_OVERLAY: "BG_OVERLAY",
  BG_ALWAYS_BLACK: "BG_ALWAYS_BLACK",
  BG_ALWAYS_WHITE: "BG_ALWAYS_WHITE",

  /** Core */
  CONTENT_PRIMARY: "CONTENT_PRIMARY",
  CONTENT_SECONDARY: "CONTENT_SECONDARY",
  CONTENT_TERTIARY: "CONTENT_TERTIARY",

  /** Core Extention */
  CONTENT_HIGHLIGHT: "CONTENT_HIGHLIGHT",
  CONTENT_ON_COLOR: "CONTENT_ON_COLOR",
  CONTENT_NEGATIVE: "CONTENT_NEGATIVE",
  CONTENT_WARNING: "CONTENT_WARNING",
  CONTENT_POSITIVE: "CONTENT_POSITIVE",
  CONTENT_DISABLED: "CONTENT_DISABLED",

  /** Core */
  BORDER_DEFAULT: "BORDER_DEFAULT",
  BORDER_SELECTED: "BORDER_SELECTED",
  BORDER_DEFAULT_INVERSE: "BORDER_DEFAULT_INVERSE",
  BORDER_SELECTED_INVERSE: "BORDER_SELECTED_INVERSE",

  /** Core Extention */
  BORDER_HIGHLIGHT: "BORDER_HIGHLIGHT",
  BORDER_NEGATIVE: "BORDER_NEGATIVE",
  BORDER_WARNING: "BORDER_WARNING",
  BORDER_POSITIVE: "BORDER_POSITIVE",
} as const;
export type ColorsTypes = (typeof ColorsTypes)[keyof typeof ColorsTypes];

export const FontWeightTypes = {
  /** Core */
  LIGHT: "LIGHT",
  REGULAR: "REGULAR",
  SEMI_BOLD: "SEMI_BOLD",
} as const;
export type FontWeightTypes =
  (typeof FontWeightTypes)[keyof typeof FontWeightTypes];

export const FontTypes = {
  /** Core */
  DISPLAY_1: "DISPLAY_1",
  DISPLAY_2: "DISPLAY_2",
  DISPLAY_3: "DISPLAY_3",
  TITLE_1: "TITLE_1",
  TITLE_2: "TITLE_2",
  TITLE_3: "TITLE_3",
  SUBTITLE_1: "SUBTITLE_1",
  SUBTITLE_2: "SUBTITLE_2",
  BODY_1: "BODY_1",
  BODY_2: "BODY_2",
  BODY_3: "BODY_3",
  CAPTION_1: "CAPTION_1",
  CAPTION_2: "CAPTION_2",
} as const;
export type FontTypes = (typeof FontTypes)[keyof typeof FontTypes];

export interface ThemeType {
  colors: {
    [key in keyof typeof ColorsTypes]: string;
  };
  typography: {
    [key in keyof typeof FontTypes]: {
      [key in keyof typeof FontWeightTypes]: {
        fontFamily: string;
        fontWeight: number;
        fontSize: number;
        fontStyle: string;
        lineHeight: string;
      };
    };
  };
}
