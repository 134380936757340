/**
 * display-mode를 통해 PWA 설치유저를 추적합니다
 */
export const checkPWA = () => {
  // PWA 유저 접속시의 얼리페이 홈페이지의 GET 요청을 보냅니다.
  if (window.matchMedia("(display-mode: standalone)").matches) {
    const referrerUrl = document.referrer ?? "PWA referrer";
    try {
      fetch("https://earlypay.kr/?utm_source=PWA", {
        method: "GET",
        headers: {
          Referer: referrerUrl,
        },
      });
      // 한국기준 00시에 만료되는 쿠키를 설정합니다.
      const exdate = new Date(new Date().setHours(24, 0, 0, 0));
      document.cookie = "checkPWA=true; expires=" + exdate.toUTCString();
    } catch (err) {
      console.log(err);
    }
  }
};
