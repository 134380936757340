import { css } from "styled-components";

export const slideUpModalMotion = css`
    animation: ${(props: { cssMotion: boolean }) =>
      props.cssMotion ? "upModal 350ms forwards" : "downModal 250ms forwards"};
    animation-timing-function: ease-out;

    @keyframes upModal {
        0% {
            transform: translateY(20%);
        }
        100% {
            transform: translateY(0%);
        }
    }

    @keyframes downModal {
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(20%);
        }
    }
}`;

export const fadeMotion = css`
    visibility: ${({ cssMotion }: { cssMotion: boolean }) =>
      cssMotion ? "visible" : "hidden"};
    opacity: ${({ cssMotion }: { cssMotion: boolean }) => (cssMotion ? 1 : 0)};
    transition: all 0.15s ease-in-out;
}`;

export const floatingContentMotion = css`
  animation: up 350ms ease-in-out;

  @keyframes up {
    0% {
      transform: translateY(3%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 100%;
    }
  }
`;

export const screenModalMotion = css`
  animation: ${(props: { cssMotion: boolean }) =>
    props.cssMotion
      ? "upModal 250ms ease-in-out"
      : "downModal 250ms ease-in-out"};
  visibility: ${({ cssMotion }: { cssMotion: boolean }) =>
    cssMotion ? "visible" : "hidden"};
  opacity: ${({ cssMotion }: { cssMotion: boolean }) => (cssMotion ? 1 : 0)};
  transition: all 0.25s ease-in-out;

  @keyframes upModal {
    0% {
      transform: translateY(3%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes downModal {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(3%);
    }
  }
`;
