import React from "react";
import styled from "styled-components";
import { dataStringSet } from "./dataStringSet";
import Text from "@components/atoms/Text";
import { BillingInfoMessageType } from "./types";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { Link } from "react-router-dom";
import { BillingCode } from "@earlypay/shared/typings";
import Interaction from "@components/atoms/Interaction";
import useSettlement from "@hooks/useSettlement";

export interface BillingInfoMessageProps {
  type: BillingInfoMessageType;
}

export const BillingInfoMessage: React.FC<BillingInfoMessageProps> = ({
  type,
}) => {
  const { depositorParams, currentUrl, startDateParams, endDateParams } =
    useSettlement();
  const filter =
    depositorParams === "baemin"
      ? BillingCode.BAEMIN.CARD_SALES
      : BillingCode.YOGIYO.CARD_SALES;

  // 배달사 매출 안내 메세지일 경우 따로 커스텀한 UI를 보여줍니다.
  if (type === BillingInfoMessageType.DELIVERY_SALES) {
    return (
      <MessageWrapper>
        <TextBox>
          <BoldText li>
            <BoldText>만나서 카드매출</BoldText>
            <InfoText>은 </InfoText>
            <CustomLink
              to={`/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${depositorParams}&filter=${filter}`}
              state={currentUrl}
            >
              <Interaction styles={{ display: "inline" }} type={"DARK"}>
                <LinkText>카드 정산금</LinkText>
              </Interaction>
            </CustomLink>
            <InfoText>에 반영되어 총 매출에서 제외됩니다.</InfoText>
          </BoldText>
        </TextBox>
        <TextBox>
          <BoldText li>
            <BoldText>만나서 현금매출</BoldText>
            <InfoText>
              은 이미 지급되었기 때문에 총 매출에서 제외됩니다.
            </InfoText>
          </BoldText>
        </TextBox>
      </MessageWrapper>
    );
  }

  return (
    <MessageWrapper>
      {dataStringSet[type].length <= 1 ? (
        <InfoText>{dataStringSet[type][0]}</InfoText>
      ) : (
        dataStringSet[type].map((text, index) => {
          return (
            <TextBox key={index}>
              <InfoText li>{text}</InfoText>
            </TextBox>
          );
        })
      )}
    </MessageWrapper>
  );
};

export default BillingInfoMessage;

const MessageWrapper = styled.ul`
  width: 100%;
  border-radius: 12px;
  background: #f4f4f4;
  padding: 12px;
`;

const InfoText = styled(Text).attrs({
  tag: FontTypes.BODY_3,
  color: ColorsTypes.CONTENT_SECONDARY,
})`
  display: ${({ li }: { li: boolean }) => (li ? "outside" : "inside")};
`;

const BoldText = styled(Text).attrs({
  tag: FontTypes.BODY_3,
  color: ColorsTypes.CONTENT_SECONDARY,
  bold: true,
})``;

const TextBox = styled.div`
  display: flex;
  align-items: center;
`;

const LinkText = styled(Text).attrs({
  tag: FontTypes.BODY_3,
  color: ColorsTypes.CONTENT_HIGHLIGHT,
})`
  text-decoration: underline;
  text-underline-offset: 2px;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
`;
