import React from "react";
import { ButtonQty } from "@components/molecules/BottomSheet/type";
import { DepositorCode, TotalSalesTypes } from "@earlypay/shared/typings";
import { SalesAmountList } from "../../SettlementAmountList";
import BottomSheet from "@components/molecules/BottomSheet";
import { useSettlement } from "@hooks/useSettlement";
import useNavigateWithParams from "@hooks/useNavigateParams";

interface CardSalesBottomSheetProps {
  data: TotalSalesTypes;
  isLoading: boolean;
}

/** 카드 총 매출 내역을 바텀 시트에서 보여줍니다. */
export const CardSalesBottomSheet: React.FC<CardSalesBottomSheetProps> = ({
  data,
  isLoading,
}) => {
  const { handleCloseBillingBottomSheet } = useSettlement();
  const { navigateWithParams } = useNavigateWithParams();
  const { startDateParams, endDateParams } = useSettlement();

  const handleGoToHistory = () => {
    navigateWithParams({
      pathname: "/settlement/history",
      query: {
        startDate: startDateParams,
        endDate: endDateParams,
        depositor: "card",
        type: "card",
      },
      state: {
        from: `/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=card&type=card`,
      },
    });
  };

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={"카드 매출"}
      buttonQty={ButtonQty.DUO_HORIZONTAL}
      secondaryButtonText={"닫기"}
      primaryButtonText={"자세히 보기"}
      onClickPrimaryButton={handleGoToHistory}
    >
      <SalesAmountList
        type={DepositorCode.CARD}
        list={data}
        isLoading={isLoading}
      />
    </BottomSheet>
  );
};

export default CardSalesBottomSheet;
