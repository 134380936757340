import React from "react";

import { useWindowSize } from "@earlypay/shared";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { EarlypaySymbolLogoIcon } from "@earlybird/icons";
import { Box, ChatButton, HStack, Icon, Text } from "@earlybird/ui";

import { useFetchApplication } from "@apis/hooks";

export const MainHeader: React.FC = () => {
  const windowSize = useWindowSize();
  const { data: application, isLoading } = useFetchApplication();
  const title = application.store?.title ?? "";

  return (
    <Box bg={"bg-secondary"} width={"100%"}>
      <HStack justify={"between"} align={"center"} padding={"8px 12px"}>
        <HStack
          width={windowSize.width >= 500 ? 380 : "72vw"}
          align={"center"}
          spacing={2}
        >
          <Icon icon={EarlypaySymbolLogoIcon} size={"lg"} />
          {isLoading ? (
            <Skeleton baseColor="#EAEAEA" width={120} height={28} />
          ) : (
            <Title typo={"body-1"} bold>
              {title}
            </Title>
          )}
        </HStack>
        <ChatButton />
      </HStack>
    </Box>
  );
};

export default MainHeader;

const Title = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  width: calc(100% - 36px);
  text-overflow: ellipsis;
  overflow: hidden;
`;
