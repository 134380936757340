/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, keyframes } from "styled-components";

type AnimationCss = {
  _cssMotion: boolean;
};

const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const FadingAnimation = css`
  animation: ${(props: any) => (props.visible ? fadeIn : fadeOut)}
    ${(props: any) => props.delay} 1 forwards;
`;

export const FadedMovingAnimation = css`
  animation: ${(props: any) => (props.visible ? fadeIn : fadeOut)}
    ${(props: any) => props.delay} 1 forwards;
`;

/**
 * modal background 페이드 인 / 아웃 애니메이션 _cssMotion:boolean 을 받습니다
 */
export const fadeBackgroundMotion = css`
  animation: ${(props: AnimationCss) =>
    props._cssMotion
      ? "showCalendarPicker 250ms forwards"
      : "hideCalendarPicker 150ms forwards"};

  @keyframes showCalendarPicker {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hideCalendarPicker {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
      -webkit-transform: none;
      transform: none;
    }
  }
`;

/**
 * modal box 스케일 애니메이션 _cssMotion:boolean 을 받습니다
 */
export const scaleInnerBoxMotion = css`
  animation: ${(props: AnimationCss) =>
    props._cssMotion
      ? "upCalendarPicker 250ms forwards"
      : "downCalendarPicker 150ms forwards"};

  @keyframes upCalendarPicker {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      /* visibility: visible; */
    }
    65% {
      -webkit-transform: scale(1.01);
      transform: scale(1.01);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes downCalendarPicker {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const slideUpModalMotion = css`
  animation: ${(props: AnimationCss) =>
    props._cssMotion ? "downModal 550ms forwards" : "upModal 350ms forwards"};
  animation-timing-function: ease-out;

  @keyframes upModal {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes downModal {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const upModalMotion = css`
  animation: ${(props: AnimationCss) =>
    props._cssMotion ? "upModal 350ms forwards" : "downModal 350ms forwards"};
  animation-timing-function: ease-in-out;

  @keyframes upModal {
    0% {
      transform: translateY(100%);
    }
    10% {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  @keyframes downModal {
    0% {
      transform: translateY(0%);
    }
    to {
      transform: translateY(100%);
    }
  }
`;

export const fadeInMotion = css`
  animation: ${(props: AnimationCss) =>
    props._cssMotion
      ? "hideFadeInModal 150ms forwards"
      : "showFadeInModal 250ms forwards"};
  animation-timing-function: ease-out;

  @keyframes showFadeInModal {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hideFadeInModal {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
