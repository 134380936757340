import React from "react";
import BillingInfoMessage from "../../BillingInfoMessage";
import { BillingInfoMessageType } from "../../BillingInfoMessage/types";
import { EtcAmountList } from "../../SettlementAmountList";
import BottomSheet from "@components/molecules/BottomSheet";
import useSettlement from "@hooks/useSettlement";
import { AdditionalDepositAmountTypes } from "@earlypay/shared/typings";
import { ButtonQty } from "@components/molecules/BottomSheet/type";
import { useNavigate } from "react-router-dom";

export interface EtcAdditionalDepositBottomSheetProps {
  data: AdditionalDepositAmountTypes[];
  isLoading: boolean;
}

/** 그 외 추가입금 항목을 담은 바텀시트를 보여줍니다. */
export const EtcAdditionalDepositBottomSheet: React.FC<
  EtcAdditionalDepositBottomSheetProps
> = ({ data, isLoading }) => {
  const { handleCloseBillingBottomSheet } = useSettlement();
  const navigate = useNavigate();
  const { startDateParams, endDateParams, depositorParams } = useSettlement();

  /** [자세히보기] 버튼을 눌렀을 때 세부 페이지로 이동합니다. */
  const handleGoToDetail = () => {
    navigate(
      `/settlement/billing/detail?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${depositorParams}&type=additional-deposit`,
    );
  };

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={"추가 입금"}
      buttonQty={ButtonQty.DUO_HORIZONTAL}
      secondaryButtonText={"닫기"}
      primaryButtonText={"자세히 보기"}
      onClickPrimaryButton={handleGoToDetail}
    >
      <BillingInfoMessage type={BillingInfoMessageType.ADDITIONAL_DEPOSIT} />
      <EtcAmountList list={data} isLoading={isLoading} />
    </BottomSheet>
  );
};

export default EtcAdditionalDepositBottomSheet;
