import { createQueryKeys } from "@lukemorales/query-key-factory";

export const storeQueryKeys = createQueryKeys("store", {
  fetchCardConnect: () => ["fetchCardConnect"],
  fetchCheckedEarlypaid: (storeId: number) => [
    "fetchCheckedEarlypaid",
    storeId,
  ],
  fetchStoreInfo: (storeId: number) => ["fetchStoreInfo", storeId],
  fetchStoreStatus: (storeId: number, startDate?: string, endDate?: string) => [
    "fetchStoreStatus",
    storeId,
    startDate,
    endDate,
  ],
  fetchStoreDailySales: (
    storeId: number,
    startDate?: string,
    endDate?: string,
  ) => ["fetchStoreDailySales", storeId, startDate, endDate],
  fetchStoreHourlySales: (
    storeId: number,
    startDate: string,
    endDate: string,
    granularity?: string,
  ) => ["fetchStoreHourlySales", storeId, startDate, endDate, granularity],
});
