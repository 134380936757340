import { getFormattedDateKR } from "@utils/date";

export const getSelectedDate = (startDate: string, endDate: string) => {
  const today = new Date();
  const hasDate = startDate.length || endDate.length;
  const isDuration = startDate !== endDate && hasDate;

  if (hasDate) {
    /** 시작 날짜와 끝 날짜가 다른 경우, 선택한 기간을 출력합니다. */
    if (isDuration) {
      const date = `${getFormattedDateKR(startDate, 2)}~${getFormattedDateKR(
        endDate,
        2,
      )}`;
      return date;
    }

    /** 시작 날짜와 끝 날짜가 같은 경우, 선택한 날짜를 출력합니다. */
    return getFormattedDateKR(startDate, 2);
  }

  /** 시작 날짜와 끝 날짜의 값이 없거나 일부 없는 경우, 오늘 날짜를 출력합니다. */
  return getFormattedDateKR(today);
};

/** 선정산 시작일부터 "오늘"까지 month 의 차이를 구합니다. */
export const getMonthDifference = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();

  const year1 = date.getFullYear();
  const month1 = date.getMonth();
  const year2 = today.getFullYear();
  const month2 = today.getMonth();

  const yearDifference = year2 - year1;
  const monthDifference = month2 - month1;

  const totalMonthDifference = yearDifference * 12 + monthDifference + 1;
  const result = totalMonthDifference > 3 ? totalMonthDifference : 3;

  return result;
};

/** 두 개의 날짜 차이를 계산하여 3개월이 넘는지 확인합니다. */
export const isMoreThanThreeMonths = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearDifference = end.getFullYear() - start.getFullYear();
  const monthDifference = end.getMonth() - start.getMonth();
  const dayDifference = end.getDate() - start.getDate();

  if (yearDifference * 12 + monthDifference > 3) {
    return true;
  } else if (yearDifference * 12 + monthDifference === 3 && dayDifference > 0) {
    return true;
  }

  return false;
};
