import React, { useState } from "react";

import { compareAsc } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { XIcon } from "@earlybird/icons";

import Button from "@components/atoms/Button";
import { errorToast } from "@components/atoms/Toast";
import { isMoreThanThreeMonths } from "@components/features/settlement/SettlementDatePicker/utils";
import { Header } from "@components/layouts";
import { getDateButtonLabel } from "@components/organisms/DatePickerModal/utils";
import ScrollDateRangePicker from "@components/organisms/ScrollDateRangePicker";

import { getFormattedDateWithDash } from "@utils/date";

// TODO: Earlybird 디자인 시스템 적용 시, 모달 컴포넌트와 헤더 컴포넌트를 적용할 예정입니다.

export interface DatePickerModalProps {
  onClose: () => void;
  startDate: string;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  minShowMonths?: number;
  activeStartDate?: Date;
}

export const DatePickerModal: React.FC<DatePickerModalProps> = ({
  onClose,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  minShowMonths,
  activeStartDate,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(startDate),
    new Date(endDate),
  ]);
  const buttonLabel = getDateButtonLabel(selectedDates);
  const isButtonDisabled = !(selectedDates.length === 2);

  // TODO: 재사용 가능하도록 함수 독립적으로 분리 필요
  const handleClickDone = () => {
    const start = getFormattedDateWithDash(selectedDates[0]);
    const end = getFormattedDateWithDash(selectedDates[1]);

    if (isMoreThanThreeMonths(start, end)) {
      errorToast("최대 3개월까지 검색 가능합니다.");

      return;
    }
    onClose();
    setStartDate(start);
    setEndDate(end);
    navigate(`${location.pathname}?startDate=${start}&endDate=${end}`);
  };

  /** 선택한 날짜를 기본값 또는 저장되었던 날짜로 초기화합니다. */
  const handleResetDate = () => {
    setSelectedDates([]);
  };

  /** 특정 날짜를 선택할 경우, 호출하는 함수입니다. */
  const handleClickDate = (date: Date) => {
    if (date > new Date()) {
      return;
    }

    if (selectedDates.length === 0) {
      setSelectedDates([date]);
    } else if (selectedDates.length === 1) {
      const newDates = [selectedDates[0], date].sort(compareAsc);
      setSelectedDates(newDates);
    } else {
      setSelectedDates([date]);
    }
  };

  return (
    <Wrapper>
      <Header
        item={"icon-text-text"}
        leftButtonIcon={XIcon}
        handleClickLeftButton={onClose}
        rightActionLabel={"초기화"}
        handleClickRightButton={handleResetDate}
        hasChat={false}
        buttonType={"mixed"}
        title={"날짜 선택"}
      />
      <InnerWrapper>
        <ScrollDateRangePicker
          value={selectedDates}
          onChange={handleClickDate}
          height={"calc(100vh - 172px)"}
          minShowMonths={minShowMonths}
          activeStartDate={activeStartDate}
        />
      </InnerWrapper>
      <ButtonWrapper>
        <Button onClick={handleClickDone} isDisabled={isButtonDisabled}>
          {buttonLabel}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default DatePickerModal;

const Wrapper = styled.div`
  width: 100%;
  max-width: 498px;
  height: 100%;
  background: white;
  overflow: hidden;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 498px;
  height: fit-content;
  position: fixed;
  bottom: 0;
  padding: 12px 20px;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0.05%,
    #fff 19.95%
  );
`;
