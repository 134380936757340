import styled from "styled-components";
import themes from "../../../styles/themes";

export const AccordionWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  &.accordion-collapsed {
    .accordion-header-icon {
      transform: rotate(0deg);
    }
  }

  &.accordion-expanded {
    .accordion-header-icon {
      transform: rotate(180deg);
    }
  }
`;

export const AccordionItem = styled.div`
  width: 100%;
`;

export const AccordionHeader = styled.div`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
  border-bottom: solid 1px ${themes.colors.BORDER_DEFAULT};

  @media screen and (max-width: 1279px) {
    padding: 16px;
  }

  .accordion-title {
    @media screen and (max-width: 1279px) {
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }

  .accordion-header-icon {
    > svg {
      width: 32px;
      height: 32px;

      @media screen and (max-width: 1279px) {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const AccordionIcon = styled.div`
  transition: transform 250ms ease-in-out;
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0 4px 4px;
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  height: 0;
`;

export const AccordionInnerContent = styled.div`
  padding: 24px;
  background-color: ${themes.colors.BG_HIGHLIGHT_LIGHT};
`;
