import { BillingCode, EarlypaysEtcCode } from "@earlypay/shared/typings";

export const changeEtcFilterToCode = (filter: string) => {
  switch (filter) {
    case BillingCode.ETC.SERVICE_FEE:
      return EarlypaysEtcCode.SERVICE_FEE;
    case BillingCode.ETC.FINANCIAL_INTEREST:
      return EarlypaysEtcCode.FINANCIAL_INTEREST;
    case BillingCode.ETC.ADDITIONAL_DEPOSIT:
      return EarlypaysEtcCode.ADDITIONAL_DEPOSIT;
    default:
      return EarlypaysEtcCode.SERVICE_FEE;
  }
};
