import { ButtonSizes, ButtonTypes } from "./type";
import styled from "styled-components";
import { changeSizeToStyleObject, changeTagToStyleObject } from "./utils";
import themes from "@styles/themes";
import { ButtonProps } from "@components/atoms/Button/index";

export const StyledButton = styled.button`
  position: relative;
  width: ${({ width }: { width: number }) =>
    width ? (typeof width === "string" ? width : `${width}px`) : "100%"};
  max-width: ${({ width }: { width: number }) =>
    width ? (width > 328 ? width : `${328}px`) : "100%"};
  background-color: ${({
    tag,
    isLoading,
    disabled,
  }: {
    tag: ButtonTypes;
    isLoading: boolean;
    disabled: boolean;
  }) =>
    isLoading
      ? themes.colors.BG_HIGHLIGHT_LIGHT
      : changeTagToStyleObject(tag, disabled).backgroundColor};
  border: ${({
    tag,
    disabled,
    isLoading,
  }: {
    tag: ButtonTypes;
    isLoading: boolean;
    disabled: boolean;
  }) =>
    isLoading
      ? themes.colors.BG_HIGHLIGHT_LIGHT
      : changeTagToStyleObject(tag, disabled).border};
  border-radius: ${({ size }: { size: ButtonSizes }) =>
    changeSizeToStyleObject(size).rounded}px;
  padding: ${({ size, isLoading }: { size: ButtonSizes; isLoading: boolean }) =>
    `${isLoading ? 0 : changeSizeToStyleObject(size).paddingVertical}px 16px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &:active {
    transform: ${({ disabled }: { disabled: boolean }) =>
      disabled ? "unset" : "scale(0.96)"};
    transition: 0.1s ease-in-out;
  }
`;

export const ButtonWrapper = styled.div<ButtonProps>`
  width: ${(props: ButtonProps) =>
    props.width
      ? typeof props.width === "string"
        ? props.width
        : `${props.width}px`
      : "100%"};
  max-width: ${(props: ButtonProps) =>
    props.width
      ? typeof props.width === "string"
        ? props.width
        : props.width > 328
        ? props.width
        : `${328}px`
      : "100%"};
  border-radius: ${(props: ButtonProps) =>
    changeSizeToStyleObject(props.size).rounded}px;
  position: relative;
  height: fit-content;
  background: transparent;
`;

export const ActiveBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;

  &:hover {
    background: ${({ tag }: { tag: ButtonTypes }) =>
      tag === ButtonTypes.PRIMARY
        ? "rgba(22, 22, 22, 0.08)"
        : "rgba(22, 22, 22, 0.04)"};
    transition: 0.15s ease-in-out;
  }
  &:active {
    background: ${({ tag }: { tag: ButtonTypes }) =>
      tag === ButtonTypes.PRIMARY
        ? "rgba(22, 22, 22, 0.14)"
        : "rgba(22, 22, 22, 0.08)"};
    transition: 0.15s ease-in-out;
  }
`;
