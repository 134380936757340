import { BillingInfoMessageType } from "./types";

export const dataStringSet: {
  [key in BillingInfoMessageType]: string[];
} = {
  CARD_UNDER_DEPOSIT: [
    "카드사에서 입금된 금액이 얼리페이에서 지급한 선정산금보다 적어 회수하지 못한 금액입니다.",
    "해외카드 수수료, 보류 등의 이유로 발생할 수 있어요.",
  ],
  DELIVERY_SALES: [
    "만나서 카드매출은 카드 정산금에 반영되어 총 매출에서 제외됩니다.",
    "만나서 현금매출은 이미 지급되었기 때문에 총 매출에서 제외됩니다.",
  ],
  DELIVERY_UNDER_DEPOSIT: [
    "차감금은 광고비, 환불 등의 사유로 지급 받은 선정산 금액보다 적게 회수되어 차감되는 금액입니다.",
  ],
  SERVICE_FEE: [
    "얼리페이 이용료는 매출 유무와 상관없이 발생합니다. 휴무일 이후 선정산 지급되는 날 합산되어 책정됩니다.",
  ],
  FINANCIAL_FEE: [
    "매일 마감 기준 잔여 선정산 회수금(누적 선정산금 - 총 회수금)에 한해서 일 0.0438% 금융이자가 책정됩니다.",
  ],
  ADDITIONAL_DEPOSIT: [
    "추가 선정산은 일부 매출에 대해 누락된 선정산금을 추가로 입금해 드리는 금액입니다.",
    "기타 추가입금은 선정산 대상에 포함되지 않는 금액이 경남은행 계좌에 입금된 경우 돌려드리는 금액입니다.",
  ],
  ETC_UNDER_DEPOSIT: [
    "기타 차감금은 대출 연계 등의 특별 사유로 차감되는 금액입니다.",
  ],
};
