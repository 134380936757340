import styled from "styled-components";
import themes from "@/styles/themes";
import {
  fadeInMotion,
  slideUpModalMotion,
  upModalMotion,
} from "@styles/animation";

export const Container = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  overflow: visible;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  z-index: 999;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;

  ${fadeInMotion};
`;

export const ContentWrapper = styled.div`
  position: relative;
  background-color: ${themes.colors.BG_PRIMARY_INVERSE};
  padding: ${({ hasCloseButton }: { hasCloseButton: boolean }) =>
    hasCloseButton ? "12px 0 12px 16px" : "12px 16px"};
  display: flex;
  justify-content: center;
  border-radius: 8px;
  height: fit-content;
  width: ${({ width }: { width: number }) => `${width}px` ?? "fit-content"};
`;

export const StyledPointer = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
`;
