import styled from "styled-components";
import themes from "@styles/themes";
import Text from "@components/atoms/Text";
import { FontTypes, FontWeightTypes } from "@/@types/ThemesType";

export const AccumulatedAmountWrapper = styled.div`
  width: 100%;
  background-color: ${themes.colors.BG_SECONDARY};
  padding: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1279px) {
    padding: 64px 16px;
      
    .accumulated-amount-title {
      display: none;
    }
      
    .accumulated-amount-text {
      font-size: 14px !important;
        line-height:20px !important;
    }
    
    .accumulated-amount-caption {
        font-size:12px !important;
        line-height: 18px !important;
    }
  }

    @media screen and (min-width: 1280px) {
        .accumulated-amount-title-mobile {
            display: none;
        }
    }
    }
`;

export const Title = styled(Text).attrs({
  tag: FontTypes.TITLE_1,
  weight: FontWeightTypes.SEMI_BOLD,
})`
  @media screen and (max-width: 1279px) {
    font-size: 28px !important;
    line-height: 34px !important;
  }
`;
