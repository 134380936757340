import React from "react";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import Text from "@components/atoms/Text";
import styled from "styled-components";
import {
  PlatformSalesTagsCode,
  PlatformSalesTagsCodeTypes,
  PlatformSalesTagsNames,
} from "@earlypay/shared/typings";

interface HistoryAmountTagProps {
  type: PlatformSalesTagsCodeTypes;
}

export const HistoryAmountTag: React.FC<HistoryAmountTagProps> = ({ type }) => {
  const color =
    type === PlatformSalesTagsCode.MEET_PAYMENT
      ? ColorsTypes.BG_HIGHLIGHT
      : ColorsTypes.BG_NEGATIVE;
  const background =
    type === PlatformSalesTagsCode.MEET_PAYMENT ? "#E7EFFF" : "#FDE6ED";

  return (
    <TagWrapper background={background}>
      <Text tag={FontTypes.CAPTION_1} color={color}>
        {PlatformSalesTagsNames[type]}
      </Text>
    </TagWrapper>
  );
};

export default HistoryAmountTag;

const TagWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${({ background }: { background: string }) => background};
  cursor: default;
`;
