export const theme = {
  color: {
    /** ------- TODO: 레거시 색상 삭제 ------- */

    // red
    red_00: "#E82639",

    // blue (base)
    blue_00: "#0070FA",
    blue_10: "#5165CC",
    blue_20: "#253AA9",
    blue_30: "#4699FF",

    // white
    white_00: "#FFFFFF",
    white_10: "#F3F4F7",
    white_20: "#F4F5F7",
    white_30: "#F7F7F7",

    // gray
    gray_00: "#E8E8E8",
    gray_10: "#CFD3D6",
    gray_20: "#A9A9A9",
    gray_30: "#B7B7B7",
    gray_40: "#767B83",
    gray_50: "#555555",
    gray_60: "#56585D",

    // black
    black_00: "#1F1F1F",
    black_10: "#1F1F1F",
    black_20: "#333436",
  },
  /** ------- TODO: 레거시 스타일, 추후 삭제 ------- */
  px: {
    10: "0.714rem",
    11: "0.786rem",
    12: "0.857em",
    13: "0.929rem",
    14: "1.000rem",
    15: "1.071rem",
    17: "1.214rem",
    18: "1.286rem",
    20: "1.429rem",
    22: "1.571rem",
    24: "1.714em",
    25: "1.786em",
    28: "2.000rem",
    30: "2.143rem",
    32: "2.286rem",
  },
  /** ------- TODO: 레거시 스타일, 추후 삭제 ------- */
  fontFamily: [
    { class: "modules", fontFamily: "material-symbols-outlined" },
    { class: "poppins", fontFamily: "poppins-regular" },
    { class: "roboto", fontFamily: "roboto-regular" },
  ],
};

export default theme;
