import styled from "styled-components";
import { FadingAnimation } from "@/styles/animation";
import themes from "@styles/themes";

export const FeatureWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 620px;
  background-color: ${themes.colors.BG_PRIMARY};
  padding-top: 80px;
  padding-bottom: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1279px) {
    height: 620px;
    padding: 64px 16px;

    .earlypay-feature-title {
      font-size: 28px !important;
      line-height: 34px !important;
    }

    .earlypay-feature-subtitle {
      font-size: 28px !important;
      line-height: 34px !important;
      text-align: center !important;
    }

    .earlypay-feature-text {
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .earlypay-feature-image {
      display: none;
    }

    .earlypay-feature-caption {
      text-align: center;
    }

    .earlypay-feature-text-wrapper {
      text-align: center;
    }
  }

  @media screen and (min-width: 1280px) {
    .earlypay-feature-image-mobile {
      display: none;
    }
  }
`;

export const FeatureContentWrapper = styled.div`
  position: relative;
  margin-top: 62px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    margin-top: 24px;
  }
`;

export const FeatureContentStack = styled.div`
  /* ${FadingAnimation} */
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0 : 1)};
  visibility: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "hidden" : "visible"};
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: ease-out;
`;

export const FeatureContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 396px;
  gap: 12px;

  @media screen and (max-width: 1279px) {
    width: 100%;
    align-items: center;
  }
`;

export const FeatureTabStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  > div {
    margin-right: 32px;

    @media screen and (max-width: 1279px) {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const TabTitle = styled.div`
  cursor: pointer;

  .feature-tab-title {
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
  }
`;
