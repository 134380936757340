import {
  CardCode,
  DepositorPlatformCode,
  DepositorPlatformCodeTypes,
  findKeyByValue,
} from "@earlypay/shared";

import { upperSnakeCaseToKebab } from "@ui/utils/namingCase";

export const getPlatformImageUrl = (depositor: DepositorPlatformCodeTypes) => {
  const cardImageName = findKeyByValue(CardCode, depositor);

  switch (depositor) {
    case DepositorPlatformCode.BAEMIN:
      return "/brand/platform-baemin.svg";
    case DepositorPlatformCode.YOGIYO:
      return "/brand/platform-yogiyo.svg";
    case DepositorPlatformCode.COUPANG:
      return "/brand/platform-coupang-eats.svg";
    default:
      return `/brand/logo-card-${upperSnakeCaseToKebab(cardImageName)}.svg`;
  }
};
