import styled from "styled-components";
import themes from "../../../styles/themes";

export const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 32px 64px;
  background-color: ${themes.colors.BG_SECONDARY};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    padding: 32px;
  }
`;

export const FooterStack = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

export const FooterContentStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const FooterInfoStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > p {
    line-height: 22px !important;
  }
`;

export const FooterTermStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

export const TermLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noreferrer",
}))`
  text-decoration: none;

  > p {
    text-underline-offset: 0;
  }
`;
