import React, { useEffect, useState } from "react";

import {
  CardCode,
  CardCodeTypes,
  DeliveryCode,
  DeliveryCodeTypes,
  DepositorPlatformNames,
} from "@earlypay/shared/typings";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import Interaction from "@components/atoms/Interaction";
import Text from "@components/atoms/Text";
import BottomSheet from "@components/molecules/BottomSheet";
import { ButtonStates } from "@components/molecules/BottomSheet/type";

import useSettlement from "@hooks/useSettlement";

import { FontTypes } from "@/@types/ThemesType";

import {
  getAllData,
  getFilterLabel,
  getPlatformData,
} from "../SettlementHistoryFilter/utils";
import SettlementHistoryFilterCard from "./item.card";

interface SettlementHistoryFilterProps {
  type: "CARD" | "DELIVERY";
}

export const SettlementHistoryFilter: React.FC<
  SettlementHistoryFilterProps
> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const allData = getAllData(type);
  const selectedData = getPlatformData(type) ?? allData;
  const { searchParams, prevPath } = useSettlement();

  const [isVisibleBottomSheet, setIsVisibleBottomSheet] =
    useState<boolean>(false);
  const [selectedCheckBox, setSelectedCheckBox] =
    useState<string[]>(selectedData);
  const [isDisabled, setIsDisabled] = useState(!selectedCheckBox?.length);

  const isSelectedAll =
    type === "CARD"
      ? selectedCheckBox?.length === Object.keys(CardCode).length
      : selectedCheckBox?.length === Object.keys(DeliveryCode).length;
  const label = getFilterLabel(type);

  /** 정산내역>건별매출 필터 바텀 시트를 닫습니다. */
  const handleCloseBottomSheet = () => {
    setIsVisibleBottomSheet(false);
  };

  /** 정산내역>건별매출 필터 바텀 시트를 엽니다. */
  const handleOpenBottomSheet = () => {
    setIsVisibleBottomSheet(true);
  };

  /** 필터 또는 체크박스를 클릭하였을 경우 호출하는 이벤트 함수 */
  const handleCheckBoxChange = (code: string) => {
    setSelectedCheckBox(prev =>
      prev.includes(code)
        ? prev.filter(item => item !== code)
        : [...prev, code],
    );
  };

  /** [확인] 버튼을 누를 시, 정산내역 필터를 저장합니다. */
  const handleSaveFilter = () => {
    const categoryKey = type === "CARD" ? "card" : "delivery";
    const categoryValue =
      selectedCheckBox.length > 0
        ? selectedCheckBox.join(",").toLowerCase()
        : selectedCheckBox[0].toLowerCase();

    searchParams.set(categoryKey, categoryValue);
    isSelectedAll && searchParams.delete(categoryKey);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      state: { from: prevPath },
    });

    handleCloseBottomSheet();
  };

  /** 체크박스가 하나도 활성화가 안되어 있으면 Disabled 속성을 부여합니다. */
  useEffect(() => {
    setIsDisabled(!selectedCheckBox?.length);
  }, [selectedCheckBox]);

  // 페이지 진입할 때마다 적용된 필터를 반영합니다.
  useEffect(() => {
    setSelectedCheckBox(selectedData);
  }, [location.search, isVisibleBottomSheet]);

  /** 전체 선택 또는 전체 해체를 눌렀을 때, 체크박스의 모든 상태값을 동일하게 조작합니다. */
  const handleClickAll = () => {
    isSelectedAll ? setSelectedCheckBox([]) : setSelectedCheckBox(allData);
  };

  return (
    <>
      {/** 필터 버튼 영역 */}
      <FilterButton onClick={handleOpenBottomSheet}>
        <Text tag={FontTypes.BODY_3} bold>
          {label}
        </Text>
        <AnimationIcon
          rotated={isVisibleBottomSheet}
          tag={IconTags.CHEVRON_DOWN_SOLID}
          size={IconSizes.XS}
        />
      </FilterButton>

      {/** 필터 버튼을 누르면 선택할 수 있는 항목을 보여주는 바텀 시트가 나타납니다. */}
      {isVisibleBottomSheet && (
        <BottomSheet
          headerText={"카드사 선택"}
          onClose={handleCloseBottomSheet}
          primaryButtonText={"확인"}
          onClickPrimaryButton={handleSaveFilter}
          prefixText={isSelectedAll ? "전체 해제" : "전체 선택"}
          onClickPrefixText={handleClickAll}
          buttonStates={
            isDisabled ? ButtonStates.INACTIVE : ButtonStates.ACTIVE
          }
        >
          <FilterWrapper>
            {allData.map(
              (code: CardCodeTypes | DeliveryCodeTypes, index: number) => {
                const platformName = DepositorPlatformNames[code];
                const isSelected = selectedCheckBox.includes(code.toString());

                return (
                  <Interaction key={index} width={"100%"}>
                    <SettlementHistoryFilterCard
                      onChange={() => handleCheckBoxChange(code)}
                      isSelected={isSelected}
                      name={platformName}
                    />
                  </Interaction>
                );
              },
            )}
          </FilterWrapper>
        </BottomSheet>
      )}
    </>
  );
};

export default SettlementHistoryFilter;

const FilterButton = styled.button`
  width: fit-content;
  height: 32px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f4f4f4;
  gap: 4px;

  /** TODO: interaction 스타일을 따로 정의해서 재사용 가능하게 하면 좋을 것 같습니다. */
  &:hover {
    background: rgba(22, 22, 22, 0.08);
    transition: 0.15s ease-in-out;
  }
  &:active {
    background: rgba(22, 22, 22, 0.14);
    transition: 0.15s ease-in-out;
  }
  &:active {
    transform: scale(0.96);
    transition: 0.1s ease-in-out;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AnimationIcon = styled(Icon)`
  transition: transform 0.25s ease-in-out;
  cursor: pointer;
  transform: ${({ rotated }: { rotated: boolean }) =>
    rotated ? "rotate(180deg)" : "rotate(0deg)"};
`;
