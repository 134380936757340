/*
 * 얼리페이 서비스 관련 약관을 가져오는 API 함수입니다.
 */
import { getTermListParams } from "@apis/endpoints/policies/types";
import { instance } from "@apis/instance";

export const getTermList = async (params: getTermListParams) => {
  try {
    const { data } = await instance({
      method: "get",
      url: `v1/policies/usage-policy/`,
      params: { ...params },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
