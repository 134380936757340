import styled from "styled-components";
import themes from "@styles/themes";

export const QNASectionWrapper = styled.div<{ tabTitle: string }>`
  position: relative;
  width: 100%;
  padding: 64px;
  background-color: ${themes.colors.BG_PRIMARY};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1279px) {
    padding: 64px 16px;

    .earlypay-ana-title {
      font-size: 28px !important;
      line-height: 34px !important;
    }
  }
`;

export const QNATabStack = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  > div {
    position: relative;
    margin-right: 32px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const TabTitle = styled.div`
  cursor: pointer;

  .qna-tab-title {
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
  }
`;

export const QNAContentWrapper = styled.div`
  position: relative;
  margin-top: 74px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    margin-top: 32px;
  }
`;

export const QNAContentStack = styled.div`
  position: relative;
  margin-top: 26px;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomAccordion = styled.div`
  width: 100%;
  height: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "0px" : "auto"};
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0 : 1)};
  visibility: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "hidden" : "visible"};
  transition: opacity 1s, margin-top 1s, margin-bottom 1s;
  -webkit-transition: opacity 1s, margin-top 1s, margin-bottom 1s;

  .accordion-collapsed {
    height: ${({ disabled }: { disabled: boolean }) =>
      disabled ? "0px" : "100%"};
  }

  .accordion-expanded {
    height: ${({ disabled }: { disabled: boolean }) =>
      disabled ? "0px" : "100%"};
  }

  .accordion-content-text {
    text-underline-offset: 2px;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`;
