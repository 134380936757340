import styled from "styled-components";
import { TextProps } from "./index";

export const StyledText = styled.p<TextProps>`
  display: ${(props: TextProps) =>
    props.li || props.link ? "data-item" : "inline"};
  color: ${({ color }: { color: string }) => color};
  width: auto;
  text-decoration: ${(props: TextProps) =>
    props.underlined ? "underline" : "none"};
  text-decoration-color: ${(props: TextProps) =>
    props.underlined ? props.color : "transparent"};
  text-decoration-thickness: 1px;
  text-align: ${({ center }: { center: boolean }) =>
    center ? "center" : "start"};
  text-underline-offset: ${(props: TextProps) => (props.link ? "1px" : "4px")};
  white-space: pre-wrap;
  list-style: ${({ li }: { li: boolean }) => (li ? "outside" : "none")};
  margin-left: ${({ li }: { li: boolean }) => (li ? "23px" : 0)};
  border: none;
`;
