import styled from "styled-components";
import themes from "@styles/themes";

export const ReviewCardCarouselWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 440px;
  width: 320px;

  &:after,
  &:before {
    background: linear-gradient(
      to bottom,
      white 0%,
      rgba(254, 254, 254, 0) 100%
    );
    content: "";
    height: 40px;
    position: absolute;
    width: 320px;
    z-index: 2;
  }

  &:after {
    right: 0;
    top: 0;
  }
  &:before {
    left: 0;
    bottom: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);

    @media screen and (max-width: 1279px) {
      background: linear-gradient(
        to bottom,
        white 0%,
        rgba(0, 211, 211, 0) 100%
      );
    }
  }

  @media screen and (max-width: 1279px) {
    margin-top: 16px;
  }
`;

export const CarouselTrack = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: calc(196px * 10);
  animation: slide 90000ms linear infinite;
  -webkit-animation: slide 90000ms linear infinite;
  display: flex;

  @-webkit-keyframes slide {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(calc(-196px * 10));
      transform: translateY(calc(-196px * 10));
    }
  }
  @keyframes slide {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(calc(-196px * 10));
      transform: translateY(calc(-196px * 10));
    }
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  width: 320px;
  min-height: 180px;
  margin-top: 8px;
  margin-bottom: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 0px 20px;
  background-color: ${themes.colors.BG_HIGHLIGHT_LIGHT};
  gap: 16px;

  > p {
    &:first-child {
      margin-bottom: 16px;
    }
  }
`;
