export interface ToastOptionProps {
  position: string;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  closeButton: boolean;
  theme: string;
}

export const ToastTags = {
  TOAST: "TOAST",
  SNACK_BAR: "SNACK_BAR",
} as const;
export type ToastTags = (typeof ToastTags)[keyof typeof ToastTags];
