import styled from "styled-components";

import { FlexBox } from "@components/commons/index";

const FullImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SliderBox = styled(FlexBox)`
  height: 110px;

  .slick-list {
    border-radius: 12px;
    height: 100%;

    div {
      height: 100%;
    }
  }
`;

export { FullImg, SliderBox };
