import { lazy } from "react";

const MyIndex = lazy(() => import("@pages/my/MyIndex"));
const MyFaq = lazy(() => import("@pages/my/MyFaq"));
const Feedback = lazy(() => import("@pages/my/Feedback"));
const Notice = lazy(() => import("@pages/my/Notice"));
const InviteRecent = lazy(() => import("@pages/my/InviteRecent"));
const InviteEvent = lazy(() => import("@pages/my/InviteEvent"));
const Info = lazy(() => import("@pages/my/Info"));
const Event = lazy(() => import("@pages/my/Event"));
const CardConnect = lazy(() => import("@pages/my/CardConnect"));
const DeliveryAccount = lazy(() => import("@pages/my/DeliveryAccount"));

export const myRouters = [
  {
    index: true,
    Component: MyIndex,
  },
  {
    path: "invite-event",
    Component: InviteEvent,
  },
  {
    path: "info",
    Component: Info,
  },
  {
    path: "invite",
    Component: InviteRecent,
  },
  {
    path: "delivery-account",
    Component: DeliveryAccount,
  },
  {
    path: "event",
    Component: Event,
  },
  {
    path: "faq",
    Component: MyFaq,
  },
  {
    path: "notice",
    Component: Notice,
  },
  {
    path: "connect",
    Component: CardConnect,
  },
  {
    path: "feedback",
    Component: Feedback,
  },
];
