export const typography = {
  /** display */
  DISPLAY_1: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 60,
      fontStyle: "normal",
      lineHeight: "72px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 60,
      fontStyle: "normal",
      lineHeight: "72px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 60,
      fontStyle: "normal",
      lineHeight: "72px",
    },
  },
  DISPLAY_2: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 48,
      fontStyle: "normal",
      lineHeight: "58px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 48,
      fontStyle: "normal",
      lineHeight: "58px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 48,
      fontStyle: "normal",
      lineHeight: "58px",
    },
  },
  DISPLAY_3: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 42,
      fontStyle: "normal",
      lineHeight: "50px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 42,
      fontStyle: "normal",
      lineHeight: "50px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 42,
      fontStyle: "normal",
      lineHeight: "50px",
    },
  },

  /** title */
  TITLE_1: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 36,
      fontStyle: "normal",
      lineHeight: "44px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 36,
      fontStyle: "normal",
      lineHeight: "44px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 36,
      fontStyle: "normal",
      lineHeight: "44px",
    },
  },
  TITLE_2: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 32,
      fontStyle: "normal",
      lineHeight: "38px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 32,
      fontStyle: "normal",
      lineHeight: "38px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 32,
      fontStyle: "normal",
      lineHeight: "38px",
    },
  },
  TITLE_3: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 28,
      fontStyle: "normal",
      lineHeight: "34px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 28,
      fontStyle: "normal",
      lineHeight: "34px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 28,
      fontStyle: "normal",
      lineHeight: "34px",
    },
  },

  /** subtitle */
  SUBTITLE_1: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 24,
      fontStyle: "normal",
      lineHeight: "32px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 24,
      fontStyle: "normal",
      lineHeight: "32px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 24,
      fontStyle: "normal",
      lineHeight: "32px",
    },
  },
  SUBTITLE_2: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 20,
      fontStyle: "normal",
      lineHeight: "28px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 20,
      fontStyle: "normal",
      lineHeight: "28px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 20,
      fontStyle: "normal",
      lineHeight: "28px",
    },
  },

  /** body */
  BODY_1: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 18,
      fontStyle: "normal",
      lineHeight: "28px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 18,
      fontStyle: "normal",
      lineHeight: "28px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 18,
      fontStyle: "normal",
      lineHeight: "28px",
    },
  },
  BODY_2: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 16,
      fontStyle: "normal",
      lineHeight: "24px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 16,
      fontStyle: "normal",
      lineHeight: "24px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      lineHeight: "24px",
    },
  },
  BODY_3: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 14,
      fontStyle: "normal",
      lineHeight: "20px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 14,
      fontStyle: "normal",
      lineHeight: "20px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 14,
      fontStyle: "normal",
      lineHeight: "20px",
    },
  },

  /** caption */
  CAPTION_1: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 12,
      fontStyle: "normal",
      lineHeight: "18px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 12,
      fontStyle: "normal",
      lineHeight: "18px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 12,
      fontStyle: "normal",
      lineHeight: "18px",
    },
  },
  CAPTION_2: {
    LIGHT: {
      fontFamily: "Pretendard",
      fontWeight: 300,
      fontSize: 10,
      fontStyle: "normal",
      lineHeight: "14px",
    },
    REGULAR: {
      fontFamily: "Pretendard",
      fontWeight: 400,
      fontSize: 10,
      fontStyle: "normal",
      lineHeight: "14px",
    },
    SEMI_BOLD: {
      fontFamily: "Pretendard",
      fontWeight: 600,
      fontSize: 10,
      fontStyle: "normal",
      lineHeight: "14px",
    },
  },
};
