import styled from "styled-components";
import themes from "@styles/themes";

export const FastCalculateWrapper = styled.div`
  width: 100%;
  background-color: ${themes.colors.BG_SECONDARY};
  padding: 100px 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    padding: 64px 16px;

    .earlypay-landing-title {
      font-size: 28px !important;
      line-height: 34px !important;
    }
  }
`;

export const TitleStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
