import React from "react";

import ProtectedServiceApplyRoute from "@routes/utils/ProtectedServiceApplyRoute";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

import ServiceApplyLayout from "../layouts/ServiceApplyLayout";

const ServiceApplyRouteGuard = () => {
  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <ProtectedServiceApplyRoute>
          <ServiceApplyLayout />
        </ProtectedServiceApplyRoute>
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default ServiceApplyRouteGuard;
