import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";

import { BottomPointerIcon } from "@earlybird/icons";
import { HStack, Icon, Text } from "@ui/components/atoms";
import {
  changePlacementPointerToStyle,
  changePlacementWrapperToStyle,
} from "@ui/components/atoms/Tooltip/utils";
import "@ui/styles/index.scss";
import styles from "./Tooltip.module.scss";
import { TooltipProps } from "./Tooltip.types";

/**
 * `Tooltip` 은 형태의 일반적인 버튼으로 스크린의 컨텐츠 영역 또는 하단에 배치하여 스크린 내 주요한 행동을 나타내기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Tooltip
 *   position={"top-center"}
 * />
 * ```
 */
export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  {
    children,
    className,
    as,
    message,
    icon,
    fixVisible = true,
    defaultVisible = true,
    placement = "top-center",
    isVisible = true,
    offset = 0,
    pointerOffset = 0,
    textAlign,
    ...rest
  }: TooltipProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const Wrapper = "span";
  const Pointer = "div";
  const TooltipContent = "div";
  const BaseComponent = as ?? "div";

  return (
    <Wrapper
      {...rest}
      className={classNames(
        styles.TooltipWrapper,
        "earlybird-tooltip-wrapper",
        className,
      )}
    >
      {children}

      <BaseComponent
        ref={forwardedRef}
        className={classNames(styles.Tooltip, "earlybird-tooltip", className)}
        style={changePlacementWrapperToStyle(placement, offset)}
      >
        <Pointer
          className={classNames(styles.TooltipPointer)}
          style={changePlacementPointerToStyle(
            placement,
            pointerOffset ?? offset,
          )}
        >
          <Icon
            icon={BottomPointerIcon}
            color={"content-primary"}
            size={"xs"}
          />
        </Pointer>

        <TooltipContent className={classNames(styles.TooltipContent)}>
          <HStack>
            {icon && (
              <Icon icon={icon} size={"xl"} color={"content-on-color"} />
            )}
            <Text
              typo={"body-3"}
              color={"content-on-color"}
              style={{ textWrap: "nowrap" }}
              center={textAlign === "center"}
            >
              {message}
            </Text>
          </HStack>
        </TooltipContent>
      </BaseComponent>
    </Wrapper>
  );
});

export default Tooltip;
