import { FontTypes, FontWeightTypes } from "@/@types/ThemesType";

export const changeTagToAs = (tag: FontTypes) => {
  switch (tag) {
    case "DISPLAY_1" || "DISPLAY_2" || "DISPLAY_3":
      return "h1";
    case "TITLE_1" || "TITLE_2":
      return "h1";
    case "TITLE_3":
      return "h2";
    case "SUBTITLE_1":
      return "h3";
    case "SUBTITLE_2":
      return "h4";
    case "BODY_1":
      return "h5";
    default:
      return "span";
  }
};

export const changeStringToWeight = (weight: string) => {
  switch (weight) {
    case "bold":
      return FontWeightTypes.SEMI_BOLD;
    case "light":
      return FontWeightTypes.LIGHT;
    default:
      return FontWeightTypes.REGULAR;
  }
};
