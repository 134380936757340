import { createQueryKeys } from "@lukemorales/query-key-factory";

export const platformsQueryKeys = createQueryKeys("platforms", {
  fetchPlatformsAccount: applicationId => [
    "fetchPlatformsAccount",
    applicationId,
  ],
  addPlatformsAccount: applicationId => ["addPlatformsAccount", applicationId],
  updatePlatformsAccount: applicationId => [
    "updatePlatformsAccount",
    applicationId,
  ],
  deletePlatformsAccount: applicationId => [
    "deletePlatformsAccount",
    applicationId,
  ],
  fetchMyPlatformsAccounts: storeId => ["fetchMyPlatformsAccount", storeId],
  fetchMyPlatformsAccount: storeId => ["fetchMyPlatformsAccount", storeId],
  addMyPlatformsAccount: applicationId => [
    "addMyPlatformsAccount",
    applicationId,
  ],
  updateMyPlatformsAccount: applicationId => [
    "updateMyPlatformsAccount",
    applicationId,
  ],
  fetchPlatformsAccountStatus: (storeId: number) => [
    "fetchPlatformsAccountStatus",
    storeId,
  ],
});
