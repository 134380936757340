import styled from "styled-components";
import { changeSizeToNumber } from "@components/atoms/IconButton/utils";
import { IconButtonSizes } from "@components/atoms/IconButton/type";

export const IconButtonWrapper = styled.button`
  width: ${({ size }: { size: IconButtonSizes }) =>
    changeSizeToNumber(size) + 12}px;
  height: ${({ size }: { size: IconButtonSizes }) =>
    changeSizeToNumber(size) + 12}px;
  padding-inline: unset;
  background-color: transparent;
  padding: 6px;
`;
