import { EarlypaysEtcCodeTypes, SettlementRequests } from "@earlypay/shared";
import { createQueryKeys } from "@lukemorales/query-key-factory";

export const earlypayQueryKeys = createQueryKeys("earlypay", {
  fetchAdditionalDeposit: (params: SettlementRequests) => [
    "fetchAdditionalDeposit",
    params,
  ],
  fetchEarlypays: (params: SettlementRequests) => ["fetchEarlypays", params],
  fetchEarlypaysSummary: (params: SettlementRequests) => [
    "fetchEarlypaysSummary",
    params,
  ],
  fetchEarlypaysEtc: (params: SettlementRequests) => [
    "fetchEarlypaysEtc",
    params,
  ],
  fetchEarlypaysEtcDetail: (
    params: SettlementRequests,
    type: EarlypaysEtcCodeTypes,
  ) => ["fetchEarlypaysEtcDetail", params, type],
  fetchTotalSales: (params: SettlementRequests) => ["fetchTotalSales", params],
  fetchUnderDepositsSummary: (params: SettlementRequests) => [
    "fetchUnderDepositsSummary",
    params,
  ],
  fetchUnderDeposits: (params: SettlementRequests) => [
    "fetchUnderDeposits",
    params,
  ],
});
