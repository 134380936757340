import React from "react";

import styled from "styled-components";

import Interaction from "@components/atoms/Interaction";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

import Text from "../Text";

type TabType = { label: string; value: string };

interface TabProps {
  data: TabType[];
  hasHeader?: boolean;
  onClick: (value: string) => void;
  selectedValue: string;
}

export const Tab: React.FC<TabProps> = ({
  data,
  hasHeader = true,
  onClick,
  selectedValue,
}) => {
  return (
    <Wrapper hasHeader={hasHeader}>
      <InnerWrapper type={"DARK"}>
        {data.map((item, index) => {
          const isSelected = selectedValue === item.value;
          const fontColor = isSelected
            ? ColorsTypes.CONTENT_PRIMARY
            : ColorsTypes.CONTENT_TERTIARY;

          return (
            <Interaction key={index} width={"100%"}>
              <Menu onClick={() => onClick(item.value)} isSelected={isSelected}>
                <Text
                  tag={FontTypes.BODY_2}
                  color={fontColor}
                  bold={isSelected}
                >
                  {item.label}
                </Text>
              </Menu>
            </Interaction>
          );
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default Tab;

const Wrapper = styled.div`
  position: sticky;
  width: 100%;
  padding: 24px 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, #fff 27.27%);
  cursor: default;
  z-index: 20;
  top: ${({ hasHeader }: { hasHeader: boolean }) => (hasHeader ? `44px` : 0)};
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  padding: 4px;
  border-radius: 12px;
  background: #e0e0e0;
`;

const Menu = styled.button`
  width: 100%;
  height: 36px;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "#fefefe" : "transparent"};
  transition: background-color 0.3s ease;
  cursor: pointer;
`;
