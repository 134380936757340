import styled from "styled-components";
import { StackProps } from "./index";
import {
  StackAlignTypes,
  StackDirectionsTypes,
  StackJustifyTypes,
} from "@components/atoms/Stack/type";

export const StyledStack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${({ direction }: { direction: StackDirectionsTypes }) =>
    direction};
  gap: ${({ spacing }: { spacing: number }) => spacing}px;
  justify-content: ${({
    center,
    justify,
  }: {
    center: boolean;
    justify: StackJustifyTypes;
  }) => (center ? "center" : justify)};
  height: ${({ height }: { height: string | number }) =>
    typeof height === "string" ? height : `${height}px`};
  width: ${({ width }: { width: string | number }) =>
    typeof width === "string" ? width : `${width}px`};
  align-items: ${({
    center,
    align,
  }: {
    center: boolean;
    align: StackAlignTypes;
  }) => (center ? "center" : align)};
`;
