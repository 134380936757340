import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${(props: { [key: string]: number | null }) =>
    props.maxWidth ? `${props.maxWidth}px` : "none"};
  background: #fff;
  margin: 0 auto;
  height: ${(props: { [key: string]: string }) =>
    props.height !== null
      ? typeof props.height === "string"
        ? props.height
        : props.height + "px"
      : "100%"};
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: solid 1px #e0e0e0;
  border-right: solid 1px #e0e0e0;
`;
