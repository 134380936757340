import {
  MyPlatformAccountRequests,
  PlatformAccountRequests,
} from "@earlypay/shared";

import { instance } from "@apis/instance";

/**
 * 신청서에 등록된 배달앱 계정 목록을 조회하는 API 함수입니다.
 */
export const getPlatformAccounts = async <T>(
  applicationId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/applications/${applicationId}/platform-accounts`,
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 등록하는 API 함수입니다.
 */
export const postPlatformAccount = async <T>(
  applicationId: number,
  payload: PlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `v2/applications/${applicationId}/platform-account`,
    data: { ...payload },
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 수정하는 API 함수입니다.
 */
export const patchPlatformAccounts = async <T>(
  applicationId: number,
  accountId: number,
  payload: PlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `v2/applications/${applicationId}/platform-accounts/${accountId}`,
    data: { ...payload },
  });

  return data;
};

/**
 * 신청서에 등록된 배달앱 계정을 삭제하는 API 함수입니다.
 */
export const deletePlatformAccounts = async <T>(
  applicationId: number,
  accountId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "delete",
    url: `v2/applications/${applicationId}/platform-accounts/${accountId}`,
  });

  return data;
};

/**
 * 마이페이지에서 배달앱 계정을 가져오는 API 함수입니다.
 */
export const getMyPlatformAccounts = async <T>(
  storeId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/stores/${storeId}/platform-account`,
  });

  return data.data;
};

/**
 * 마이페이지에서 특정 배달앱 계정을 가져오는 API 함수입니다.
 */
export const getMyPlatformAccount = async <T>(
  storeId: number,
  accountId: string,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/stores/${storeId}/platform-account/${accountId}`,
  });

  return data;
};

/**
 * 마이페이지에서 새로운 배달앱 계정을 만드는 API 함수입니다.
 */
export const postMyPlatformAccount = async <T>(
  storeId: number,
  payload: MyPlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `v2/stores/${storeId}/platform-account`,
    data: { ...payload },
  });

  return data;
};

/**
 * 마이페이지에서 배달앱 계정을 수정하는 API 함수입니다.
 */
export const patchMyPlatformAccount = async <T>(
  storeId: number,
  accountId: number,
  payload: MyPlatformAccountRequests,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `v2/stores/${storeId}/platform-account/${accountId}`,
    data: { ...payload },
  });

  return data;
};

/**
 * 플랫폼 연동 상태를 조회하는 API 함수입니다.
 */
export const getPlatformAccountStatus = async <T>(
  storeId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/stores/${storeId}/platform-accounts/status`,
  });

  return data;
};
