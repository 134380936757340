import React from "react";

import { BillingCode, ServiceFeeAmountTypes } from "@earlypay/shared/typings";

import BillingInfoMessage from "@components/features/settlement/BillingInfoMessage";
import BottomSheet from "@components/molecules/BottomSheet";

import useSettlement from "@hooks/useSettlement";

import { BillingInfoMessageType } from "../../BillingInfoMessage/types";
import { EtcAmountList } from "../../SettlementAmountList";

export interface EtcServiceFeeBottomSheetProps {
  data: ServiceFeeAmountTypes[];
  isLoading: boolean;
}

/** 그 외 얼리페이 이용료 항목을 담은 바텀시트를 보여줍니다. */
export const EtcServiceFeeBottomSheet: React.FC<
  EtcServiceFeeBottomSheetProps
> = ({ data, isLoading }) => {
  const { handleCloseBillingBottomSheet } = useSettlement();

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={"얼리페이 이용료"}
      primaryButtonText={"확인"}
    >
      <BillingInfoMessage type={BillingInfoMessageType.SERVICE_FEE} />
      <EtcAmountList list={data} isLoading={isLoading} />
    </BottomSheet>
  );
};

export default EtcServiceFeeBottomSheet;
