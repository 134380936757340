import React from "react";

import {
  BillingCode,
  BillingNames,
  EarlypaysCode,
  EarlypaysItemsTypes,
} from "@earlypay/shared/typings";
import { changeDepositorToCode } from "@pages/settlement/utils";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";
import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import Interaction from "@components/atoms/Interaction";
import Text from "@components/atoms/Text";
import { changeTypeToImage } from "@components/features/settlement/SettlementAmountList/utils";

import useSettlement from "@hooks/useSettlement";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes } from "@/@types/ThemesType";

export interface BillingAmountListProps {
  list?: EarlypaysItemsTypes[];
  isLoading: boolean;
}

export const BillingAmountList: React.FC<BillingAmountListProps> = ({
  list,
  isLoading,
}) => {
  return (
    <Wrapper>
      {list?.map((item, index) => {
        const { type, amount } = item;
        const { depositorParams, currentUrl, startDateParams, endDateParams } =
          useSettlement();
        const depositor = changeDepositorToCode(depositorParams);
        const title = BillingNames[BillingCode[depositor][type]];

        /** 선정산 내역 안내 바텀시트를 띄우는 링크를 생성합니다. */
        const billingCode = BillingCode[depositor][type];

        if (type === EarlypaysCode.ETC_UNDER_DEPOSIT && amount === 0) {
          return;
        }

        return (
          <Interaction key={index} width={"100%"}>
            <InnerWrapper
              to={`/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${depositorParams}&filter=${billingCode}`}
              state={{ from: currentUrl }}
            >
              <LabelWrapper>
                <CustomImage
                  tag={changeTypeToImage(depositor, type)}
                  alt={"선정산 금액"}
                  width={44}
                  height={44}
                />
                <Text color={ColorsTypes.CONTENT_SECONDARY} bold>
                  {title}
                </Text>
              </LabelWrapper>
              <AmountWrapper>
                <Text color={ColorsTypes.CONTENT_SECONDARY} bold>
                  {isLoading ? (
                    <Skeleton height={22} width={100} />
                  ) : (
                    changeNumberToMoney(amount)
                  )}
                </Text>
                <Icon
                  tag={IconTags.CHEVRON_RIGHT}
                  size={"MD"}
                  color={ColorsTypes.CONTENT_TERTIARY}
                />
              </AmountWrapper>
            </InnerWrapper>
          </Interaction>
        );
      })}
    </Wrapper>
  );
};

export default BillingAmountList;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const InnerWrapper = styled(Link)`
  width: 100%;
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: transparent;
  text-decoration: none;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomImage = styled(Image)`
  width: 44px;
  height: 44px;
  border-radius: 16px;
  border: ${({ tag }: { tag: string }) =>
    tag === ImageTypes.SETTLEMENT_COUPANG_EATS ? "1px solid #F4F4F4" : "none"};
`;
