import { InfoTypes } from "./types";
import React from "react";
import { ExcludeSalesInfo } from "./InfoContents";

export const getInfoContent = (value: InfoTypes) => {
  switch (value) {
    case InfoTypes.SETTLEMENT_EXCLUDE_SALES:
      return <ExcludeSalesInfo />;
    case InfoTypes.SETTLEMENT_ADDITIONAL_SETTLEMENT:
      return <ExcludeSalesInfo />;
    case InfoTypes.SETTLEMENT_ADDITIONAL_DEPOSIT:
      return <ExcludeSalesInfo />;
    case InfoTypes.SETTLEMENT_ETC:
      return <ExcludeSalesInfo />;
    case InfoTypes.SETTLEMENT_UNDER_DEPOSIT:
      return <ExcludeSalesInfo />;
    default:
      return <></>;
  }
};

export const getInfoTitle = (value: InfoTypes) => {
  switch (value) {
    case InfoTypes.SETTLEMENT_EXCLUDE_SALES:
      return "제외 매출이란?";
    case InfoTypes.SETTLEMENT_ADDITIONAL_SETTLEMENT:
      return "추가 선정산이란?";
    case InfoTypes.SETTLEMENT_ADDITIONAL_DEPOSIT:
      return "기타 추가입금이란?";
    case InfoTypes.SETTLEMENT_ETC:
      return "기타에는 이런 게 포함돼요";
    case InfoTypes.SETTLEMENT_UNDER_DEPOSIT:
      return "기타 미상환금이란?";
    default:
      return "레이블";
  }
};
