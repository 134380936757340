import {
  CardNames,
  DailySalesDetailTypes,
  PlatformCode,
  SalesTagCode,
} from "@earlypay/shared";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Box, HStack, Image, Text, VStack } from "@earlybird/ui";

import { changeNumberToMoney } from "@/utils/types";

import { getPlatformImageUrl } from "./utils";

export interface SalesDetailListProps {
  isLoading: boolean;
  salesList?: DailySalesDetailTypes[];
  cancelCount: number;
  approvalCount: number;
}

export const SalesDetailList = ({
  isLoading,
  salesList,
  cancelCount,
  approvalCount,
}: SalesDetailListProps) => {
  const EmptySection = () => (
    <VStack center spacing={3} padding={"48px 0"}>
      <Image src={"/symbol/empty.svg"} width={36} height={36} />
      <Text color={"content-quaternary"}>매출 내역이 없습니다.</Text>
    </VStack>
  );

  const RenderedSalesDetailBox = ({
    name,
    amount,
    platform,
    depositor,
    tags,
    soldAt,
  }: DailySalesDetailTypes) => {
    const time = new Date(soldAt).toLocaleTimeString("ko-KR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Seoul",
      hour12: false,
    });
    const isCard = platform === PlatformCode.CARD;
    const isCanceled = tags.includes(SalesTagCode.CANCEL);
    const isMeetCard = tags.includes(SalesTagCode.MEET_CARD);
    const tag = isCanceled ? "취소" : "만나서 카드 결제";
    const title = isCard ? `${CardNames[depositor]} ${name}` : name;

    return (
      <HStack justify={"between"} padding={"12px 0"}>
        <HStack spacing={4} width={"auto"}>
          <Box width={44} height={44}>
            <DepositorImage
              className={`${platform}-logo-image`}
              src={getPlatformImageUrl(depositor)}
            />
          </Box>
          <VStack width={"auto"}>
            <Text bold>{title}</Text>
            <Text typo={"caption-1"} color={"content-secondary"}>
              {time}
            </Text>
          </VStack>
        </HStack>

        <VStack width={"auto"} spacing={1} align={"end"}>
          {isCanceled ? (
            <CanceledAmount bold>{changeNumberToMoney(amount)}</CanceledAmount>
          ) : (
            <Text bold>{changeNumberToMoney(amount)}</Text>
          )}
          {(isCanceled || isMeetCard) && (
            <Box
              width={"fit-content"}
              center
              radius={"md"}
              bg={isCanceled ? "bg-negative-light" : "bg-informative-light"}
              padding={"0 6px"}
            >
              <Text
                color={isCanceled ? "content-negative" : "content-highlight"}
                typo={"caption-1"}
              >
                {tag}
              </Text>
            </Box>
          )}
        </VStack>
      </HStack>
    );
  };

  // 리스트 정보 가져오는 중인 경우
  if (isLoading) {
    return (
      <VStack padding={"32px 20px 20px 20px"} spacing={10}>
        <Skeleton width={160} height={24} />
        <VStack spacing={6}>
          {[1, 2, 3, 4, 5].map((index: number) => (
            <Box key={index} width={"100%"}>
              <Skeleton width={"100%"} height={56} />
            </Box>
          ))}
        </VStack>
      </VStack>
    );
  }

  return (
    <VStack padding={"32px 20px 20px 20px"} spacing={8}>
      <HStack align={"center"} justify={"between"}>
        <HStack spacing={3} align={"center"} width={"auto"}>
          <Text color={"content-secondary"}>승인 {approvalCount}건</Text>
          <TextDivider />
          <Text color={"content-secondary"}>취소 {cancelCount}건</Text>
        </HStack>
      </HStack>
      <VStack spacing={6}>
        {salesList?.length ? (
          salesList.map((props: DailySalesDetailTypes, index: number) => (
            <RenderedSalesDetailBox key={index} {...props} />
          ))
        ) : (
          <EmptySection />
        )}
      </VStack>
    </VStack>
  );
};

export default SalesDetailList;

const DepositorImage = styled(Image).attrs(() => ({
  width: 44,
  height: 44,
}))`
  border-radius: var(--earlybird-radius-2xl);

  &:where(.COUPANG-logo-image) {
    border: 1px solid var(--earlybird-color-border-tertiary);
  }
`;

const TextDivider = styled.hr`
  background-color: var(--earlybird-color-border-secondary);
  width: 1px;
  height: 14px;
  border: none;
  margin: 0;
  padding: 0;
`;

const CanceledAmount = styled(Text)`
  text-decoration: line-through;
  text-decoration-thickness: 2px;
`;
