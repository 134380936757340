import { useState } from "react";

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Box, Spinner, Text } from "@earlybird/ui";

import { useFetchStoreHourlySales } from "@/apis/hooks";

interface SalesStats {
  amountSales: number;
  hour: string;
  numSales: number;
}

const TIME_DATA = [
  "0시",
  "1시",
  "2시",
  "3시",
  "4시",
  "5시",
  "6시",
  "7시",
  "8시",
  "9시",
  "10시",
  "11시",
  "12시",
  "13시",
  "14시",
  "15시",
  "16시",
  "17시",
  "18시",
  "19시",
  "20시",
  "21시",
  "22시",
  "23시",
];

const HourlySales = ({ storeId }: { storeId: number }) => {
  const getWeekRanges = () => {
    const today = new Date();
    const day = today.getDay();
    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    const startOfWeek = (date: Date) => {
      const day = date.getDay();
      return new Date(
        date.setDate(date.getDate() - day + (day === 0 ? -6 : 1)),
      );
    };
    const thisWeekStart = startOfWeek(
      new Date(today.setDate(today.getDate() - (day === 1 ? 7 : 0))),
    );
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);
    const thisWeekEnd = new Date(thisWeekStart);
    thisWeekEnd.setDate(thisWeekStart.getDate() + 6);
    const lastWeekEnd = new Date(lastWeekStart);
    lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

    return {
      thisWeek: {
        start: formatDate(thisWeekStart),
        end: formatDate(thisWeekEnd),
      },
      lastWeek: {
        start: formatDate(lastWeekStart),
        end: formatDate(lastWeekEnd),
      },
    };
  };

  function shortDate(dateString: string) {
    const [year, month, day] = dateString.split("-");
    return `${year.slice(-2)}.${month}.${day}`;
  }

  const lastWeek = getWeekRanges().lastWeek;
  const thisWeek = getWeekRanges().thisWeek;

  const { data: lastWeekHourly, isLoading: lastWeekLoading } =
    useFetchStoreHourlySales(storeId, lastWeek.start, lastWeek.end, "1");
  const { data: thisWeekHourly, isLoading: thisWeekLoading } =
    useFetchStoreHourlySales(storeId, thisWeek.start, thisWeek.end, "1");

  const modifiedLastWeekSales = lastWeekHourly?.salesStats;
  const modifiedThisWeekSales = thisWeekHourly?.salesStats;

  // const maxAmountSales = Math.max(
  //   ...(modifiedThisWeekSales || []).map((stat: SalesStats) =>
  //     stat.numSales > 0
  //       ? Number((stat.amountSales / stat.numSales).toFixed())
  //       : 0,
  //   ),
  // );
  // const maxRevenueIndex = modifiedThisWeekSales?.findIndex(
  //   (item: SalesStats) =>
  //     Number((item.amountSales / item.numSales).toFixed()) === maxAmountSales,
  // );

  const series = [
    {
      name: `지난주 (${shortDate(lastWeek.start)} ~ ${shortDate(
        lastWeek.end,
      )})`,
      data: modifiedLastWeekSales?.map((item: SalesStats) =>
        item.numSales > 0
          ? Number((item.amountSales / item.numSales).toFixed())
          : 0,
      ),
    },
    {
      name: `이번주 (${shortDate(thisWeek.start)} ~ ${shortDate(
        thisWeek.end,
      )})`,
      data: modifiedThisWeekSales?.map((item: SalesStats) =>
        item.numSales > 0
          ? Number((item.amountSales / item.numSales).toFixed())
          : 0,
      ),
    },
  ];

  const options: ApexOptions = {
    theme: {
      mode: "light",
    },
    chart: {
      type: "line",
      height: 254,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#D3D3D3", "#4A85FF"],
    xaxis: {
      categories: TIME_DATA,
      labels: {
        rotate: 0,
        formatter: value => {
          const replaceValue = value?.replace("시", "");
          return Number(replaceValue) % 4 === 0 || replaceValue === "23"
            ? `${replaceValue}시`
            : "";
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      itemMargin: {
        horizontal: 12,
        vertical: 4,
      },
    },
    tooltip: {
      x: {
        formatter: (value, { dataPointIndex }) => TIME_DATA[dataPointIndex],
      },
      y: {
        formatter: value => `${Number(value).toLocaleString("ko-KR")}원`,
      },
    },
    // annotations:
    //   maxAmountSales > 0
    //     ? {
    //         points: [
    //           {
    //             x: TIME_DATA[maxRevenueIndex],
    //             y: maxAmountSales,
    //             yAxisIndex: 0,
    //             marker: {
    //               size: 6,
    //               fillColor: "#4A85FF",
    //               strokeColor: "#FFFFFF",
    //               shape: "circle",
    //             },
    //             label: {
    //               text: `최고 ${(maxAmountSales / 10000).toFixed(1)}만원`,
    //               borderColor: "transparent",
    //               style: {
    //                 color: "#0E5CFF",
    //                 fontSize: "12px",
    //                 fontWeight: 600,
    //                 background: "transparent",
    //               },
    //             },
    //           },
    //         ],
    //       }
    //     : {},
  };

  if (lastWeekLoading || thisWeekLoading || !thisWeekHourly || !lastWeekHourly)
    return (
      <Box
        width="100%"
        height="260px"
        radius={"xl"}
        bg="bg-primary"
        shadow={"md"}
        center
      >
        <Spinner />
      </Box>
    );

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box padding="20px 16px 8px 16px" width="100%">
        <Text color="content-primary" weight="semi-bold" typo="body-2">
          시간대별 매출
        </Text>
        <ReactApexChart options={options} series={series} height={254} />
      </Box>
    </Box>
  );
};

export default HourlySales;
