import React, { ReactElement } from "react";

import { ButtonTypes, ButtonSizes, ButtonAlign } from "./type";
import Text from "../Text";
import { noop } from "@/utils/utils";
import Icon from "../Icon";
import { ActiveBackground, ButtonWrapper, StyledButton } from "./index.styled";
import { ColorsTypes, FontTypes, FontWeightTypes } from "@/@types/ThemesType";
import {
  changeSizeToStyleObject,
  changeTagToStyleObject,
} from "@components/atoms/Button/utils";
import { IconTags } from "@components/atoms/Icon/type";
import { Spinner } from "@earlybird/ui";

export interface ButtonProps {
  type?: string;
  className?: string | Array<string>;
  tag?: ButtonTypes;
  align?: ButtonAlign;
  size?: ButtonSizes;
  children: string | ReactElement;
  isDisabled?: boolean;
  width?: number | string;
  onClick?: () => void;
  isLoading?: boolean;
  iconTag?: IconTags;
  iconColor?: ColorsTypes;
  labelTag?: FontTypes;
  labelColor?: ColorsTypes;
  labelWeight?: FontWeightTypes;
  styles?: React.CSSProperties;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      width,
      align = ButtonAlign.TEXT,
      tag = ButtonTypes.PRIMARY,
      size = ButtonSizes.XL,
      children = "레이블",
      isDisabled = false,
      onClick = noop,
      iconColor = ColorsTypes.CONTENT_PRIMARY,
      iconTag,
      labelTag,
      labelWeight = FontWeightTypes.REGULAR,
      labelColor,
      isLoading,
      type,
      styles,
      ...otherProps
    },
    ref,
  ) => {
    const customColor = labelColor
      ? labelColor
      : changeTagToStyleObject(tag).labelColor;
    const customLabelTag = labelTag
      ? labelTag
      : changeSizeToStyleObject(size).labelTag;

    return (
      <ButtonWrapper width={width} size={size} tag={tag} {...otherProps}>
        <StyledButton
          ref={ref}
          {...otherProps}
          className={[
            ...(Array.isArray(className) ? className : [className]),
            "earlypay-button",
          ].join(" ")}
          width={width}
          tag={tag}
          size={size}
          type={type ?? "button"}
          onClick={onClick}
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
          style={{ ...styles }}
        >
          {!isDisabled && !isLoading && <ActiveBackground />}
          {/** 왼쪽 아이콘 + 텍스트 배치했을 경우 */}
          {align === ButtonAlign["ICON_TEXT"] && (
            <Icon tag={iconTag} color={customColor} />
          )}
          {/** 로딩 상태일 여부 체크 */}
          {isLoading ? (
            <Spinner />
          ) : (
            <Text
              bold
              tag={customLabelTag}
              color={isDisabled ? ColorsTypes.CONTENT_DISABLED : customColor}
              weight={labelWeight}
            >
              {children}
            </Text>
          )}
          {/** 텍스트 + 오른쪽 배치했을 경우 */}
          {align === ButtonAlign["TEXT_ICON"] && (
            <Icon tag={iconTag} color={customColor ? customColor : iconColor} />
          )}
        </StyledButton>
      </ButtonWrapper>
    );
  },
);

Button.displayName = "Button";

export default Button;
