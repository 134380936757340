import {
  PlatformSalesFilterCode,
  DeliveryCode,
  DepositorCode,
  DeliveryCodeTypes,
} from "@earlypay/shared/typings";

export const changeDepositorToTitle = (depositor: string) => {
  switch (depositor) {
    case "card":
      return "카드";
    case "baemin":
      return "배달의민족";
    case "yogiyo":
      return "요기요";
    case "coupang":
      return "쿠팡이츠";
    case "etc":
      return "기타";
    default:
      return "카드";
  }
};

export const changeDepositorToCode = (depositor: string) => {
  switch (depositor) {
    case "card":
      return DepositorCode.CARD;
    case "baemin":
      return DepositorCode.BAEMIN;
    case "yogiyo":
      return DepositorCode.YOGIYO;
    case "coupang":
      return DepositorCode.COUPANG;
    case "etc":
      return DepositorCode.ETC;
    default:
      return DepositorCode.CARD;
  }
};

export const changeCodeToPath = (depositor: DepositorCode) => {
  switch (depositor) {
    case DepositorCode.CARD:
      return "card";
    case DepositorCode.BAEMIN:
      return "baemin";
    case DepositorCode.YOGIYO:
      return "yogiyo";
    case DepositorCode.COUPANG:
      return "coupang";
    case DepositorCode.ETC:
      return "etc";
    default:
      return "card";
  }
};
export const changeTypeToTitle = (type: string) => {
  switch (type) {
    case "under-deposit":
      return "차감금";
    case "additional-deposit":
      return "추가입금";
    case "additional-early-settlement":
      return "추가 선정산";
    default:
      return "";
  }
};

export const changeTypeToCode = (type: string) => {
  switch (type) {
    case "card":
      return PlatformSalesFilterCode.CARD;
    case "delivery":
      return PlatformSalesFilterCode.DELIVERY;
    case "canceled":
      return PlatformSalesFilterCode.CANCELED;
    default:
      return PlatformSalesFilterCode.ALL;
  }
};

export const changePathToPlatform = (platform: string) => {
  switch (platform) {
    case "baemin":
      return DeliveryCode.BAEMIN;
    case "yogiyo":
      return DeliveryCode.YOGIYO;
    case "coupang":
      return DeliveryCode.COUPANG;
    default:
      return platform as DeliveryCodeTypes;
  }
};
