import {
  DailyPlatformSalesSummaryParams,
  DailySalesDetailParams,
  DailySalesParams,
  DailySalesSummaryParams,
  SummarySalesParams,
} from "@earlypay/shared/typings";

import { instance } from "@apis/instance";

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 건별 매출 요약 정보를 가져오는 함수입니다.
 * @param params
 */
export const getPlatformSalesSummary = async (params: SummarySalesParams) => {
  const depositors = params?.depositor?.split(",");

  const { data } = await instance({
    method: "get",
    url: `/v2/sales/platform-sales/summary`,
    params: {
      ...params,
      depositor: params?.depositor?.includes("ALL") ? undefined : depositors,
    },
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 건별 매출 내역를 가져오는 함수입니다.
 * @param params
 * @param page
 */
export const getPlatformSales = async (
  params: SummarySalesParams,
  page: number,
) => {
  const depositors = params?.depositor?.split(",");

  const { data } = await instance({
    method: "get",
    url: `/v2/sales/platform-sales`,
    params: {
      page: page,
      ...params,
      depositor: params?.depositor?.includes("ALL") ? undefined : depositors,
    },
  });

  return data;
};

// TODO: 레거시 API 교체
export const getSalesSummary = async (params: {
  start_date: string;
  end_date: string;
  store_id: number;
}) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/sales/summary`,
    params: {
      ...params,
    },
  });

  return data;
};

// 매출 요약 정보를 가져오는 API입니다.
export const getDailySales = async (
  storeId: number,
  params: DailySalesParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/sales/summary`,
    params: {
      ...params,
    },
  });

  return data;
};

// 플랫폼 상태와 연결된 매출 정보를 가져오는 API입니다.
export const getDailyPlatformSalesSummary = async (
  storeId: number,
  params: DailyPlatformSalesSummaryParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/sales/daily/platforms/summary`,
    params: {
      ...params,
    },
  });

  return data;
};

// 일일 매출 요약 정보를 가져오는 API입니다.
export const getDailySalesSummary = async (
  storeId: number,
  params: DailySalesSummaryParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/sales/daily/summary`,
    params: {
      ...params,
    },
  });

  return data;
};

// 일일 매출 상세 정보를 가져오는 API입니다.
export const getDailySalesDetail = async (
  storeId: number,
  params: DailySalesDetailParams,
  page: number,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/sales/daily/detail`,
    params: {
      page: page,
      ...params,
    },
  });

  return data;
};
