import Text from "../Text";
import React from "react";
import {
  Container,
  StyledPointer,
  ContentWrapper,
  TooltipWrapper,
} from "./index.styled";
import { TooltipDirectionTypes } from "./type";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import {
  changeDirectionToPointer,
  changeDirectionToWrapper,
} from "@components/atoms/Tooltip/utils";
import Icon from "@components/atoms/Icon";
import IconButton from "@components/atoms/IconButton";

export interface TooltipProps {
  className?: string | string[];
  children: React.ReactNode;
  direction?: TooltipDirectionTypes;
  hasCloseButton?: boolean;
  width?: number;
  label: string | React.ReactNode;
  bg?: ColorsTypes;
  visible?: boolean;
  onClose?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  label,
  direction = TooltipDirectionTypes.TOP,
  visible = true,
  hasCloseButton = false,
  bg,
  width,
  onClose,
}) => {
  return (
    <Container
      className={[
        ...(Array.isArray(className) ? className : [className]),
        "earlypay-tooltip-container",
      ].join(" ")}
    >
      {children}
      {visible && (
        <TooltipWrapper
          className={"earlypay-tooltip"}
          style={{ ...changeDirectionToWrapper(direction) }}
        >
          <StyledPointer
            className={"earlypay-tooltip-pointer"}
            style={{ ...changeDirectionToPointer(direction) }}
          >
            <Icon
              tag={IconTags.TOOLTIP_POINTER}
              width={16}
              height={8}
              color={bg ? bg : ColorsTypes.BG_PRIMARY_INVERSE}
            />
          </StyledPointer>
          <ContentWrapper
            width={width}
            className={"earlypay-tooltip-content"}
            hasCloseButton={hasCloseButton}
          >
            {typeof label === "string" ? (
              <Text
                tag={FontTypes.CAPTION_1}
                color={ColorsTypes.CONTENT_ON_COLOR}
              >
                {label}
              </Text>
            ) : (
              label
            )}
            {hasCloseButton && (
              <IconButton
                className={"earlypay-tooltip-close-button"}
                icon={IconTags.CLOSE_MARK}
                color={ColorsTypes.CONTENT_ON_COLOR}
                size={IconSizes.SM}
                onClick={onClose}
              />
            )}
          </ContentWrapper>
        </TooltipWrapper>
      )}
    </Container>
  );
};

Tooltip.displayName = "Tooltip";

export default Tooltip;
