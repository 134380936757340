import React from "react";

import { showChannelIOButton } from "@earlypay/shared";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

import { getCookie } from "@utils/cookie";

const GuestRouteGuard: React.FC = () => {
  const location = useLocation();
  const currentQuery = location.search;
  const accessToken = getCookie("earlypay-token");

  if (accessToken) {
    return (
      <Navigate to={`/${currentQuery}`} replace state={{ from: location }} />
    );
  }

  if (location.pathname.includes("landing")) {
    showChannelIOButton();
  }

  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <InitializeServiceRoute>
          <Outlet />
        </InitializeServiceRoute>
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default GuestRouteGuard;
