// 얼리페이 디자인 시스템을 구성하는 원시적인 색상

export const primitivesColors = {
  // Primitives Color
  EARLY_BLUE_10: "#E7EFFF",
  EARLY_BLUE_20: "#B7CEFF",
  EARLY_BLUE_30: "#86ADFF",
  EARLY_BLUE_40: "#4A85FF",
  EARLY_BLUE_50: "#0E5CFF",
  EARLY_BLUE_60: "#0C4ED9",
  EARLY_BLUE_70: "#0A40B2",
  EARLY_BLUE_80: "#08338C",
  EARLY_BLUE_90: "#062566",
  EARLY_BLUE_100: "#031233",

  // "yellow
  YELLOW_10: "#FFF9EC",
  YELLOW_20: "#FFECC5",
  YELLOW_30: "#FFDF9F",
  YELLOW_40: "#FFCF6F",
  YELLOW_50: "#FFBF3F",
  YELLOW_60: "#D9A236",
  YELLOW_70: "#B2862C",
  YELLOW_80: "#8C6923",
  YELLOW_90: "#664C19",
  YELLOW_100: "#33260D",

  // "red
  RED_10: "#FDE6ED",
  RED_20: "#F8B3C9",
  RED_30: "#F380A4",
  RED_40: "#EE4177",
  RED_50: "#E8014A",
  RED_60: "#C5013F",
  RED_70: "#A20134",
  RED_80: "#800129",
  RED_90: "#5D001E",
  RED_100: "#2E000F",

  // "green
  GREEN_10: "#E5F3EB",
  GREEN_20: "#B2DBC4",
  GREEN_30: "#80C39D",
  GREEN_40: "#40A56D",
  GREEN_50: "#00873C",
  GREEN_60: "#007333",
  GREEN_70: "#005F2A",
  GREEN_80: "#004A21",
  GREEN_90: "#003618",
  GREEN_100: "#001B0C",

  // "neutral
  NEUTRAL_WHITE: "#FEFEFE",
  NEUTRAL_BLACK: "#010101",
  NEUTRAL_WHITE_ALPHA_50: "rgba(254, 254, 254, 0.5)",
  NEUTRAL_BLACK_ALPHA_50: "rgba(1, 1, 1, 0.5)",

  NEUTRAL_5: "#F9F9F9",
  NEUTRAL_10: "#F4F4F4",
  NEUTRAL_15: "#EAEAEA",
  NEUTRAL_20: "#E0E0E0",
  NEUTRAL_25: "#D3D3D3",
  NEUTRAL_30: "#C6C6C6",
  NEUTRAL_40: "#A8A8A8",
  NEUTRAL_50: "#828282",
  NEUTRAL_60: "#6F6F6F",
  NEUTRAL_70: "#525252",
  NEUTRAL_80: "#393939",
  NEUTRAL_90: "#262626",
  NEUTRAL_100: "#161616",
} as const;
