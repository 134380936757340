export const ButtonTypes = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  SELECTED: "SELECTED",
  GHOST: "GHOST",
  NEGATIVE: "NEGATIVE",
  DISABLED: "DISABLED",
  SOLID: "SOLID",
  OUTLINED: "OUTLINED",
};
export type ButtonTypes = (typeof ButtonTypes)[keyof typeof ButtonTypes];

export const ButtonAlign = {
  TEXT: "TEXT",
  ICON_TEXT: "ICON_TEXT",
  TEXT_ICON: "TEXT_ICON",
};
export type ButtonAlign = (typeof ButtonAlign)[keyof typeof ButtonAlign];

export const ButtonSizes = {
  XXL: "XXL",
  XL: "XL",
  LG: "LG",
  MD: "MD",
  SM: "SM",
  XS: "XS",
};
export type ButtonSizes = (typeof ButtonSizes)[keyof typeof ButtonSizes];
