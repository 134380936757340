import {
  DepositorConnectedTypes,
  PlatformAccountTypes,
  PlatformAccountsTypes,
  PlatformCode,
  PlatformCodeTypes,
  getCookie,
} from "@earlypay/shared";
import { useQuery } from "@tanstack/react-query";

import {
  getMyPlatformAccount,
  getMyPlatformAccounts,
  getPlatformAccountStatus,
  getPlatformAccounts,
} from "@apis/endpoints/platforms";

import { platformsQueryKeys } from "./queryKeys";

export const useFetchPlatformAccounts = (applicationId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: platformsQueryKeys.fetchPlatformsAccount(applicationId).queryKey,
    queryFn: () => getPlatformAccounts(applicationId),
    select: (data: {
      ok: boolean;
      platformAccounts: PlatformAccountsTypes[];
    }) => data.platformAccounts,
    enabled: !!accessToken && !!applicationId,
    retry: 0,
  });
};

export const useMyFetchPlatformAccounts = (storeId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery<{
    [key: PlatformCodeTypes]: {
      cardConnect?: DepositorConnectedTypes;
      platformAccount?: PlatformAccountTypes;
    };
  }>({
    queryKey: platformsQueryKeys.fetchMyPlatformsAccounts(storeId).queryKey,
    queryFn: () => getMyPlatformAccounts(storeId),
    enabled: !!accessToken && !!storeId,
    retry: 0,
    placeholderData: {
      [PlatformCode.BAEMIN]: { cardConnect: null, platformAccount: null },
      [PlatformCode.COUPANG]: { cardConnect: null, platformAccount: null },
      [PlatformCode.YOGIYO]: { cardConnect: null, platformAccount: null },
    },
  });
};

export const useMyFetchPlatformAccount = (
  storeId: number,
  accountId: string,
) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: platformsQueryKeys.fetchMyPlatformsAccount(storeId).queryKey,
    queryFn: () => getMyPlatformAccount(storeId, accountId),
    enabled: !!accessToken && !!storeId,
    retry: 0,
  });
};

export const useFetchPlatformAccountStatus = (storeId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: platformsQueryKeys.fetchPlatformsAccountStatus(storeId).queryKey,
    queryFn: () => getPlatformAccountStatus(storeId),
    enabled: !!accessToken && !!storeId,
    retry: 0,
  });
};
