import { sentryHook } from "@earlypay/shared/configs";

import { instance } from "@apis/instance";

export interface postUserAuthProps {
  authUid: string;
}

/**
 * 리디렉트에 필요한 JWT 토큰을 발급하는 API 함수입니다.
 */
export const getUsersToken = async <T>(userId: number): Promise<T | null> => {
  const { data } = await instance({
    method: "get",
    url: `v2/users/${userId}/token`,
    params: { userId },
  });

  return data;
};

/**
 * 본인 인증 정보를 전달하는 API 호출 함수입니다.
 * @param payload
 */
export const postUserAuth = async (payload: postUserAuthProps) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/users/auth`,
    data: {
      ...payload,
    },
  });

  return data;
};

/**
 * 친구 추천 내역을 가져오는 api 함수입니다.
 */
export const getRecommendationInfo = async (userId: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/users/${userId}/recommendation-info/`,
  });

  return data;
};
