import React, { useEffect, useRef } from "react";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import styled from "styled-components";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import {
  checkSameMonth,
  checkSelectedDate,
  getSelectedRangeColor,
  generateMonths,
  getTextColor,
} from "@components/organisms/ScrollDateRangePicker/utils";

interface ScrollDateRangePickerProps {
  height?: string | number;
  value: Date[];
  onChange: (date: Date) => void;
  minShowMonths?: number;
  activeStartDate?: Date;
}

export const ScrollDateRangePicker: React.FC<ScrollDateRangePickerProps> = ({
  height = "calc(100vh - 56px)",
  value,
  onChange,
  minShowMonths,
  activeStartDate,
}) => {
  const scrollRef = useRef(null);
  const today = new Date();

  const weeks = ["일", "월", "화", "수", "목", "금", "토"];
  const MIN_SHOW_MONTHS = minShowMonths ?? 3;
  const months = generateMonths(today, MIN_SHOW_MONTHS);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const handleClickDate = (date: Date) => {
    onChange(date);
  };

  return (
    <Wrapper>
      <WeekWrapper>
        {weeks.map((label: string, index: number) => (
          <Text
            key={index}
            tag={FontTypes.BODY_2}
            color={ColorsTypes.CONTENT_SECONDARY}
            center
          >
            {label}
          </Text>
        ))}
      </WeekWrapper>
      <DateListWrapper height={height}>
        {months.map((month, index) => (
          <DateWrapper key={index}>
            <Text bold>
              {format(month.monthStart, "yyyy년 M월", { locale: ko })}
            </Text>
            <DayWrapper>
              {month.days.map(day => {
                const isSameMonths = checkSameMonth(day, month.monthStart);
                const isSelectedDate =
                  isSameMonths && checkSelectedDate(value, day);
                const selectedRangeColorRight =
                  isSameMonths && getSelectedRangeColor(value, day, "right");
                const selectedRangeColorLeft =
                  isSameMonths && getSelectedRangeColor(value, day, "left");

                return (
                  <DayBox
                    key={day.toString()}
                    ref={(el: HTMLDivElement | null) => {
                      if (isSelectedDate) {
                        scrollRef.current = el;
                        return;
                      } else {
                        return;
                      }
                    }}
                    isPastDate={day <= new Date() || day < activeStartDate}
                    isSameMonths={isSameMonths}
                    onClick={() => {
                      isSameMonths &&
                        day >= activeStartDate &&
                        handleClickDate(day);
                    }}
                  >
                    <RightDayBox background={selectedRangeColorRight} />
                    <LeftDayBox background={selectedRangeColorLeft} />
                    <SelectedDate isSelectedDate={isSelectedDate}>
                      <Text
                        tag={FontTypes.BODY_2}
                        color={getTextColor(value, day, activeStartDate)}
                        bold
                        center
                      >
                        {isSameMonths ? format(day, "d") : ""}
                      </Text>
                    </SelectedDate>
                  </DayBox>
                );
              })}
            </DayWrapper>
          </DateWrapper>
        ))}
      </DateListWrapper>
    </Wrapper>
  );
};

export default ScrollDateRangePicker;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  cursor: default;
  background: #fefefe;
  overflow: hidden;
`;

const WeekWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 16px 20px;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eaeaea;
`;

const DateListWrapper = styled.div`
  width: 100%;
  height: ${({ height }: { height: string | number }) =>
    typeof height === "string" ? height : `${height}px`};
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  overflow-y: scroll;
`;

const DateWrapper = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const DayWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const DayBox = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({
    isSameMonths,
    isPastDate,
  }: {
    isSameMonths: boolean;
    isPastDate: boolean;
  }) => (isSameMonths && isPastDate ? "pointer" : "default")};
  background: #fefefe;
`;

const RightDayBox = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  width: 50%;
  height: calc(100% - 8px);
  background: ${({ background }: { background: string }) =>
    background ?? "transparent"};
`;

const LeftDayBox = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  width: 50%;
  height: calc(100% - 8px);
  background: ${({ background }: { background: string }) =>
    background ?? "transparent"};
`;

const SelectedDate = styled.div`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: ${({ isSelectedDate }: { isSelectedDate: boolean }) =>
    isSelectedDate ? "#0E5CFF" : "transparent"};
  border-radius: ${({ isSelectedDate }: { isSelectedDate: boolean }) =>
    isSelectedDate ? "50%" : 0};
`;
