import React from "react";
import styled from "styled-components";
import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

interface EmptyAmountBoxProps {
  label: string;
}

export const EmptyAmountBox: React.FC<EmptyAmountBoxProps> = ({ label }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Image tag={ImageTypes.SETTLEMENT_EMPTY_PAPER} width={36} height={36} />
        <Text tag={FontTypes.BODY_2} color={ColorsTypes.CONTENT_TERTIARY}>
          {label}
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
};

export default EmptyAmountBox;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
