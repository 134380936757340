import mixpanel, { Dict } from "mixpanel-browser";
import { ApplicationTypes } from "../../typings";
import { windows } from "rimraf";

// Mixpanel 초기화
export const initMixpanel = () => {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: import.meta.env.VITE_ENV !== "production",
    persistence: "localStorage",
    track_pageview: window.location.href.includes("service-apply")
      ? false
      : "full-url",
  });
};

// 사용자의 ID 및 프로필 속성을 저장합니다.
export const setMixpanelUser = (application: ApplicationTypes) => {
  if (!mixpanel.people || !application?.id) {
    mixpanel.people.set({
      $isLoggedIn: false,
    });
    return;
  }

  mixpanel.identify(String(application.user.id));
  mixpanel.people.set({
    $userId: application.user.id,
    $storeId: application.store.id,
    $email: application.user.email,
    $name: application.user.name,
    $phone: application.user.phone,
    $gender: application.user.gender,
    $birthdate: application.user.birthdate,
    $isCertified: application.user.isCertified,
    $created: application.user.created,
    $businessNumber: application.businessRegistrationNumber,
    $store: application.store.title,
    $serviceType: application.serviceType,
    $approvedAt: application.approvedAt,
    $stage: application.stage,
    $status: application.status,
    $initialUtm: application.user.inflowPath,
    $isLoggedIn: true,
  });
};

export const trackPageViewMixpanel = (name: string) => {
  if (!mixpanel.people) {
    return;
  }

  // utm 쿼리 파라미터 파싱
  // 현재 페이지 뷰에 머물고 있는 utm을 저장합니다.
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source") || "";
  const utm_medium = urlParams.get("utm_medium") || "";
  const utm_campaign = urlParams.get("utm_campaign") || "";

  mixpanel.track_pageview({
    "Page Name": name,
    "UTM Source": utm_source,
    "UTM Medium": utm_medium,
    "UTM Campaign": utm_campaign,
  });
};

export const trackEventMixpanel = (name: string, property?: Dict) => {
  if (!mixpanel.people) {
    return;
  }

  mixpanel.track(name, property);
};

export const resetMixpanel = () => {
  mixpanel.reset();
};
