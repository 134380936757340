import styled from "styled-components";
import { fadeMotion } from "@styles/animations";
import Skeleton from "react-loading-skeleton";

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }: { objectFit: string }) => objectFit};
  ${fadeMotion}
`;

export const LoadingImage = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  ${fadeMotion}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: ${({ width }: { width: string | number }) =>
    typeof width === "string" ? width : `${width}px`};
  height: ${({ height }: { height: string | number }) =>
    typeof height === "string" ? height : `${height}px`};
  max-width: ${({ maxWidth }: { maxWidth: string | number }) =>
    typeof maxWidth === "string" ? maxWidth : `${maxWidth}px`};
  max-height: ${({ maxHeight }: { maxHeight: string | number }) =>
    typeof maxHeight === "string" ? maxHeight : `${maxHeight}px`};
  min-width: ${({ minWidth }: { minWidth: string | number }) =>
    typeof minWidth === "string" ? minWidth : `${minWidth}px`};
  min-height: ${({ minHeight }: { minHeight: string | number }) =>
    typeof minHeight === "string" ? minHeight : `${minHeight}px`};
`;
