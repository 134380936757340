import React, { Suspense } from "react";

import { Outlet } from "react-router-dom";

import { Box, Container, Spinner } from "@earlybird/ui";

export const ServiceApplyLayout: React.FC = () => {
  return (
    <Suspense
      fallback={
        <Box width={"100%"} height={"100vh"} center>
          <Spinner />
        </Box>
      }
    >
      <Container>
        <Outlet />
      </Container>
    </Suspense>
  );
};

export default ServiceApplyLayout;
