import { ImageTypes } from "./type";

const baseUrl = import.meta.env.VITE_S3_URI;

export const changeStringToImage = (label: ImageTypes) => {
  switch (label) {
    case "MAIN_DEPOSITORS":
      return `${baseUrl}/image/common/main-depositors.png`;
    case "SATISFYING":
      return `${baseUrl}/image/common/satisfying2x.png`;
    case "SOSO":
      return `${baseUrl}/image/common/soso2x.png`;
    case "UNSATISFYING":
      return `${baseUrl}/image/common/unsatisfying2x.png`;
    case "UNFILL_SATISFYING":
      return `${baseUrl}/image/common/satisfying-gray2x.png`;
    case "UNFILL_SOSO":
      return `${baseUrl}/image/common/soso-gray2x.png`;
    case "UNFILL_UNSATISFYING":
      return `${baseUrl}/image/common/unsatisfying-gray2x.png`;
    case "LANDING_FEATURE_1":
      return `${baseUrl}/image/landing/landing-feature-1.svg`;
    case "LANDING_FEATURE_2":
      return `${baseUrl}/image/landing/landing-feature-2.svg`;
    case "LANDING_FEATURE_3":
      return `${baseUrl}/image/landing/landing-feature-3.svg`;
    case "LANDING_FEATURE_4":
      return `${baseUrl}/image/landing/landing-feature-4.svg`;
    case "SERVICE_APPLY_INTRO_1":
      return `${baseUrl}/image/service-apply/intro-1.svg`;
    case "SERVICE_APPLY_INTRO_2":
      return `${baseUrl}/image/service-apply/intro-2.svg`;
    case "SERVICE_APPLY_INTRO_3":
      return `${baseUrl}/image/service-apply/intro-3.svg`;
    case "SERVICE_APPLY_CARD":
      return `${baseUrl}/image/service-apply/card.png`;
    case "SERVICE_APPLY_DELIVERY":
      return `${baseUrl}/image/service-apply/delivery.png`;
    case "SERVICE_APPLY_DELIVERY_CARD":
      return `${baseUrl}/image/service-apply/delivery-card.png`;
    case "SERVICE_APPLY_AUTH":
      return `${baseUrl}/image/service-apply/auth.svg`;
    case "SERVICE_APPLY_IDENTIFICATION":
      return `${baseUrl}/image/service-apply/identification.svg`;
    case "IDENTIFICATION_ONBOARDING_1":
      return `${baseUrl}/image/service-apply/identification-onboarding-1.svg`;
    case "IDENTIFICATION_ONBOARDING_2":
      return `${baseUrl}/image/service-apply/identification-onboarding-2.svg`;
    case "IDENTIFICATION_ONBOARDING_3":
      return `${baseUrl}/image/service-apply/identification-onboarding-3.svg`;
    case "IDENTIFICATION_ONBOARDING_4":
      return `${baseUrl}/image/service-apply/identification-onboarding-4.svg`;
    case "SETTLEMENT_BAEMIN":
      return `${baseUrl}/image/settlement/baemin.svg`;
    case "SETTLEMENT_CARD_ANGLE":
      return `${baseUrl}/image/settlement/card-angle.svg`;
    case "SETTLEMENT_COUPANG_EATS":
      return `${baseUrl}/image/settlement/coupang-eats.svg`;
    case "SETTLEMENT_YOGIYO":
      return `${baseUrl}/image/settlement/yogiyo.svg`;
    case "SETTLEMENT_ETC":
      return `${baseUrl}/image/settlement/etc.svg`;
    case "SETTLEMENT_LOGO_BC":
      return `${baseUrl}/image/settlement/logo-bc.svg`;
    case "SETTLEMENT_LOGO_HANA":
      return `${baseUrl}/image/settlement/logo-hana.svg`;
    case "SETTLEMENT_LOGO_HYUNDAI":
      return `${baseUrl}/image/settlement/logo-hyundai.svg`;
    case "SETTLEMENT_LOGO_KB":
      return `${baseUrl}/image/settlement/logo-kb.svg`;
    case "SETTLEMENT_LOGO_LOTTE":
      return `${baseUrl}/image/settlement/logo-lotte.svg`;
    case "SETTLEMENT_LOGO_SAMSUNG":
      return `${baseUrl}/image/settlement/logo-samsung.svg`;
    case "SETTLEMENT_LOGO_SHINHAN":
      return `${baseUrl}/image/settlement/logo-shinhan.svg`;
    case "SETTLEMENT_LOGO_NH":
      return `${baseUrl}/image/settlement/logo-nh.svg`;
    case "SETTLEMENT_LOGO_WOORI":
      return `${baseUrl}/image/settlement/logo-woori.svg`;
    case "SETTLEMENT_MONEYBAG_GREEN":
      return `${baseUrl}/image/settlement/moneybag-green.svg`;
    case "SETTLEMENT_MONEYBAG_WARNING":
      return `${baseUrl}/image/settlement/moneybag-warning.svg`;
    case "SETTLEMENT_RECEIPT_EARLYPAY":
      return `${baseUrl}/image/settlement/receipt-earlypay.svg`;
    case "SETTLEMENT_RECEIPT_MONEYBAG":
      return `${baseUrl}/image/settlement/receipt-moneybag.svg`;
    case "SETTLEMENT_SPEAKER":
      return `${baseUrl}/image/settlement/speaker.svg`;
    case "SETTLEMENT_EMPTY_PAPER":
      return `${baseUrl}/image/settlement/empty-paper.svg`;
    case "SETTLEMENT_BAEMIN_ONE":
      return `${baseUrl}/image/settlement/baemin-one.svg`;
    case "SETTLEMENT_QUESTION_MARK_IN_CIRCLE":
      return `${baseUrl}/image/settlement/question-mark-in-circle.svg`;
    case "BUSINESS_LICENSE":
      return `${baseUrl}/image/service-apply/business-license.webp`;
    case "LICENSE_VERIFY_GUIDE_1":
      return `${baseUrl}/image/service-apply/license-verify-guide-1.webp`;
    case "LICENSE_VERIFY_GUIDE_2":
      return `${baseUrl}/image/service-apply/license-verify-guide-2.webp`;
    case "LICENSE_VERIFY_GUIDE_3":
      return `${baseUrl}/image/service-apply/license-verify-guide-3.webp`;
    case "LICENSE_VERIFY_GUIDE_4":
      return `${baseUrl}/image/service-apply/license-verify-guide-4.webp`;
    case "CARD_READER":
      return `${baseUrl}/image/service-apply/card-reader.webp`;
    default:
      return;
  }
};
