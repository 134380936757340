import { SnowflakeIcon } from "@earlybird/icons";
import {
  COMMON_IME_CONTROL_KEYS,
  useKeyboardActionLockerWhileComposing,
} from "@earlypay/shared/hooks";
import { isNil } from "@earlypay/shared/src/utils/types";
import { HStack, Icon, IconButton, Text, VStack } from "@ui/components/atoms";
import "@ui/styles/index.scss";
import { toString } from "@ui/utils/string";
import classNames from "classnames";
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import styles from "./Input.module.scss";
import { InputProps, InputRef } from "./Input.types";

/**
 * `Input` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Input
 *   placeholder="placeholder"
 *   onChange={onChange}
 *   readonly
 *   helpText="헬프 텍스트입니다."
 *   errorMessage="에러 메세지입니다."
 *   required
 * >
 * </Input>
 * ```
 */
export const Input = forwardRef<InputRef, InputProps>(function Input(
  {
    className,
    as,
    type = "text",
    size = "md",
    disabled = false,
    readonly = false,
    placeholder,
    title,
    helpText,
    required,
    leadingIcon,
    trailingIcon,
    value,
    autoFocus = true,
    errorMessage,
    onClickTrailingIcon,
    suffix,
    onClickSuffix,
    maxLength,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    onKeyUp,
    autoComplete = "off",
    ...rest
  }: InputProps,
  forwardedRef,
) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const focusTimeout = useRef<ReturnType<Window["setTimeout"]>>();
  const blurTimeout = useRef<ReturnType<Window["setTimeout"]>>();

  const BaseComponent = as ?? "input";
  const isLarge = size === "lg";
  const isActive = !readonly && !disabled;
  const costumedValue = isNil(value) ? undefined : toString(value);
  const {
    handleKeyDown: handleKeyDownWrappedWithComposingLocker,
    handleKeyUp: handleKeyUpWrappedWithComposingLocker,
  } = useKeyboardActionLockerWhileComposing({
    keysToLock: COMMON_IME_CONTROL_KEYS,
    onKeyDown,
    onKeyUp,
  });

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        !(
          (e.key >= "0" && e.key <= "9") ||
          e.key === "Backspace" ||
          e.key === "Delete" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight" ||
          e.key === "Tab"
        ) &&
        e.ctrlKey &&
        e.metaKey &&
        type === "number" &&
        isActive
      ) {
        e.preventDefault();
      }

      if (isActive && handleKeyDownWrappedWithComposingLocker) {
        handleKeyDownWrappedWithComposingLocker(e);
      }
    },
    [type, isActive, handleKeyDownWrappedWithComposingLocker],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (!isActive && handleKeyUpWrappedWithComposingLocker) {
        handleKeyUpWrappedWithComposingLocker(event);
      }
    },
    [isActive, handleKeyUpWrappedWithComposingLocker],
  );

  const focus = useCallback(() => {
    clearTimeout(focusTimeout.current);
    focusTimeout.current = window.setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  const blur = useCallback(() => {
    clearTimeout(blurTimeout.current);
    blurTimeout.current = window.setTimeout(() => {
      inputRef.current?.blur();
    }, 0);
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isActive && onChange) {
        if (type !== "number") {
          onChange(e);
          return;
        }

        const value = e.target.value;
        if (!/^\d*$/.test(value)) {
          e.target.value = value.replace(/[^0-9]/g, "");
        }
        onChange(e);
      }
    },
    [type, isActive, onChange],
  );

  const getDOMNode = useCallback(() => inputRef.current, []);
  const handle = useMemo(
    (): InputRef => ({
      focus,
      blur,
      getDOMNode,
    }),
    [focus, blur, getDOMNode],
  );

  useImperativeHandle(forwardedRef, () => handle);

  const RenderedTitle = () => {
    return (
      <HStack padding={"0 4px"} align={"center"}>
        <Text
          typo={isLarge ? "body-3" : "caption-1"}
          color={"content-tertiary"}
          bold
        >
          {title}
        </Text>
        {required && (
          <Icon icon={SnowflakeIcon} size={"xs"} color={"content-negative"} />
        )}
      </HStack>
    );
  };

  const RenderedLeadingIcon = () => (
    <Icon
      size={isLarge ? "md" : "sm"}
      color={disabled ? "content-disabled" : "content-primary"}
      icon={leadingIcon}
    />
  );

  const RenderedMessage = () => (
    <HStack padding={"0 4px"}>
      {errorMessage ? (
        <Text typo={isLarge ? "body-2" : "body-3"} color={"content-negative"}>
          {errorMessage}
        </Text>
      ) : (
        <Text typo={isLarge ? "body-2" : "body-3"} color={"content-tertiary"}>
          {helpText}
        </Text>
      )}
    </HStack>
  );

  const RenderedSuffix = () => {
    return (
      <button
        className={classNames(
          styles.SuffixButton,
          disabled && styles[`disabled`],
        )}
        type={"button"}
        onClick={onClickSuffix}
      >
        <Text
          typo={isLarge ? "subtitle-2" : "body-2"}
          color={disabled ? "content-disabled" : "content-tertiary"}
        >
          {suffix}
        </Text>
      </button>
    );
  };

  const focusCallback = useCallback(() => {
    if (autoFocus) {
      focus();
    }
  }, [autoFocus, focus]);

  useEffect(() => {
    focusCallback();
  }, [focusCallback]);

  const RenderedTrailingIcon = () => (
    <IconButton
      size={isLarge ? "sm" : "xs"}
      icon={trailingIcon}
      disabled={disabled}
      onClick={onClickTrailingIcon}
    />
  );

  return (
    <VStack spacing={2}>
      {title && <RenderedTitle />}
      <HStack
        className={classNames(
          styles.InputWrapper,
          styles[`size-${size}`],
          styles["focused"],
          errorMessage && styles[`error`],
          readonly && styles[`readonly`],
          disabled && styles[`disabled`],
          "earlybird-input-wrapper",
          className,
        )}
        align={"center"}
        spacing={3}
        onMouseDown={focus}
      >
        {leadingIcon && <RenderedLeadingIcon />}

        <BaseComponent
          ref={inputRef}
          className={classNames(
            styles.Input,
            styles[`size-${size}`],
            disabled && styles[`disabled`],
            "earlybird-input",
            className,
          )}
          style={{
            fontSize: isLarge ? "20px" : "16px",
            lineHeight: isLarge ? "20px" : "16px",
          }}
          type={type === "number" ? "text" : type}
          value={costumedValue}
          placeholder={placeholder}
          maxLength={maxLength}
          readOnly={readonly}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autoComplete}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          {...rest}
        />
        {suffix && <RenderedSuffix />}
        {trailingIcon && <RenderedTrailingIcon />}
      </HStack>

      {(helpText || errorMessage) && <RenderedMessage />}
    </VStack>
  );
});

export default Input;
