import React from "react";
import styled from "styled-components";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { changeNumberToMoney } from "@utils/types";
import Skeleton from "react-loading-skeleton";
import { DefaultAmountListTypes } from "@earlypay/shared/typings";

export interface DefaultAmountListProps {
  list: DefaultAmountListTypes[];
  isLoading: boolean;
}

export const DefaultAmountList: React.FC<DefaultAmountListProps> = ({
  list,
  isLoading,
}) => {
  /** 스켈레톤 UI */
  if (isLoading) {
    return (
      <Wrapper>
        {[1, 2, 3].map(index => (
          <InnerWrapper key={index}>
            <Skeleton width={100} height={18} />
            <Skeleton width={140} height={18} />
          </InnerWrapper>
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {list.map((item, index) => (
        <InnerWrapper key={index}>
          <SalesWrapper>
            <Text
              tag={FontTypes.BODY_2}
              bold
              color={ColorsTypes.CONTENT_TERTIARY}
            >
              {item.type}
            </Text>
            <Text
              tag={FontTypes.BODY_2}
              bold
              color={ColorsTypes.CONTENT_SECONDARY}
            >
              {changeNumberToMoney(item.amount)}
            </Text>
          </SalesWrapper>
        </InnerWrapper>
      ))}
    </Wrapper>
  );
};

export default DefaultAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SalesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
