import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Box, HStack, Spinner, Text, VStack } from "@earlybird/ui";

import { useFetchStoreDailySales } from "@/apis/hooks";

interface SaleStats {
  amountSales: number;
  numSales: number;
  date: string;
  perPlatforms: {
    amountSales: number;
    platform: "CARD" | "BAEMIN" | "YOGIYO" | "COUPANG" | "ETC";
    numSales: number;
    delta: {
      amount_sales: number;
      numSales: number;
    };
  }[];
}

// interface StoreDaily {
//   summary: {
//     amountSales: number;
//     numSales: number;
//   };
//   salesStats: SaleStats[];
// }

const DailySales = ({ storeId }: { storeId: number }) => {
  function getLastWeekDates() {
    const today = new Date();

    // 어제의 날짜를 구합니다.
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const last7DaysDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(yesterday);
      date.setDate(yesterday.getDate() - i);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      last7DaysDates.push(`${year}-${month}-${day}`);
    }

    // 배열을 최근 날짜부터 과거 날짜 순서로 반환하기 때문에, 반대로 정렬합니다.
    last7DaysDates.reverse();

    return {
      firstDay: last7DaysDates[0],
      lastDay: last7DaysDates[6],
    };
  }

  const startDate = getLastWeekDates().firstDay;
  const endDate = getLastWeekDates().lastDay;

  const { data: storeSale, isLoading: dailyLoading } = useFetchStoreDailySales(
    storeId,
    startDate,
    endDate,
  );
  const reverseSalesStats = storeSale?.salesStats
    ?.slice()
    .reverse() as SaleStats[];

  const platformsToKorean = (platform: string) => {
    switch (platform) {
      case "BAEMIN":
        return "배달의민족";
      case "CARD":
        return "카드";
      case "COUPANG":
        return "쿠팡";
      case "YOGIYO":
        return "요기요";
      default:
        return "기타";
    }
  };

  // 실제 데이터가 존재하는 플랫폼만 추출
  const platforms = Array.from(
    new Set(
      reverseSalesStats?.flatMap(stat =>
        stat.perPlatforms.map(p => p.platform),
      ),
    ),
  );

  const series = platforms.map(platform => ({
    name: platformsToKorean(platform), // 플랫폼 이름을 한글로 변환하여 legend에 표시
    data: reverseSalesStats.map(day => {
      const platformData = day.perPlatforms.find(p => p.platform === platform);
      return {
        x: day.date, // 날짜를 x축 값으로 사용
        y: platformData ? platformData.amountSales : 0, // amountSales 값이 없으면 0으로 설정
        numSales: platformData ? platformData.numSales : 0, // numSales 값이 없으면 0으로 설정
      };
    }),
  }));

  const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
  const xaxisLabels = reverseSalesStats?.map(day => {
    const date = new Date(day.date);
    return weekdays[date.getDay()]; // 해당 날짜의 요일을 가져옴
  });

  const options: ApexOptions = {
    theme: {
      mode: "light",
    },
    chart: {
      type: "bar",
      width: 244,
      toolbar: {
        show: false,
      },
      stacked: true,
      animations: {
        enabled: false,
      },
    },
    colors: ["#4A85FF", "#40C999", "#FFCF6F", "#E0E0E0"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: {
          total: {
            enabled: true,
            offsetY: -2,
            style: {
              fontSize: "10px",
              fontWeight: 600,
              color: "#525252",
            },
            formatter: function (val) {
              const manUnit = Math.floor(Number(val) / 10000);
              return `${manUnit}만`;
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const numSales =
            w.config.series[seriesIndex].data[dataPointIndex].numSales;
          const manUnit = value.toLocaleString("ko-KR");
          return `${manUnit}원 (${numSales}건)`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: xaxisLabels,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      labels: {
        colors: "#525252",
      },
    },
  };

  if (dailyLoading)
    return (
      <Box
        width="100%"
        height="300px"
        radius={"xl"}
        shadow={"md"}
        bg="bg-primary"
        center
      >
        <Spinner />
      </Box>
    );

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      padding="0 0 16px 0"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box width="100%">
        <Box padding="20px 16px 8px" width="100%">
          <Text color="content-primary" weight="semi-bold" typo="body-2">
            최근 1주일 매출
          </Text>
          <HStack justify="between" align="center">
            <Text color="content-primary" weight="semi-bold" typo="subtitle-1">
              {Number(storeSale?.summary.amountSales).toLocaleString("ko-KR")}원
            </Text>
            <Text color="content-tertiary" weight="regular" typo="body-2">
              주문 총 {storeSale?.summary.numSales}건
            </Text>
          </HStack>
        </Box>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={260}
        />
      </Box>
    </Box>
  );
};

export default DailySales;
