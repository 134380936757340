import { useQuery } from "@tanstack/react-query";

import {
  getCardConnect,
  getCheckedEarlypaid,
  getStoreDailySales,
  getStoreHourlySales,
  getStoreInfo,
  getStoreStatus,
} from "@apis/endpoints/stores";

import { storeQueryKeys } from "./queryKeys";

export const useFetchCardConnect = () => {
  return useQuery({
    queryKey: storeQueryKeys.fetchCardConnect().queryKey,
    queryFn: () => getCardConnect(),
    retry: 0,
  });
};

export const useFetchCheckedEarlypaid = (storeId: number) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchCheckedEarlypaid(storeId).queryKey,
    queryFn: () => getCheckedEarlypaid(storeId),
    enabled: !!storeId,
    retry: 0,
  });
};

export const useFetchStoreInfo = (storeId: number) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchStoreInfo(storeId).queryKey,
    queryFn: () => getStoreInfo(storeId),
    enabled: !!storeId,
    retry: 0,
  });
};

export const useFetchStoreStatus = (
  storeId: number,
  startDate?: string,
  endDate?: string,
) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchStoreStatus(storeId, startDate, endDate)
      .queryKey,
    queryFn: () => getStoreStatus(storeId, startDate, endDate),
    enabled: !!storeId && !!startDate,
    retry: 0,
  });
};

export const useFetchStoreDailySales = (
  storeId: number,
  startDate?: string,
  endDate?: string,
) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchStoreDailySales(storeId, startDate, endDate)
      .queryKey,
    queryFn: () => getStoreDailySales(storeId, startDate, endDate),
    enabled: !!storeId && !!startDate && !!endDate,
    retry: 0,
  });
};

export const useFetchStoreHourlySales = (
  storeId: number,
  startDate: string,
  endDate: string,
  granularity?: string,
) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchStoreHourlySales(
      storeId,
      startDate,
      endDate,
      granularity,
    ).queryKey,
    queryFn: () =>
      getStoreHourlySales(storeId, startDate, endDate, granularity),
    enabled: !!storeId && !!startDate && !!endDate,
    retry: 0,
  });
};
