import { EarlypaysEtcCodeTypes, SettlementRequests } from "@earlypay/shared";

import { instance } from "@apis/instance";

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 그 외 내역 중, 추가입금 상세 정보를 가져오는 함수입니다.
 * @param params
 */
export const getAdditionalDeposit = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: "/service/settlement/etc/ADDITIONAL_DEPOSIT/detail",
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 내역을 가져오는 함수입니다.
 * @param params
 */
export const getEarlypays = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/early-pays`,
    params: params,
  });

  return data.items;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 그 외 정보를 가져오는 함수입니다.
 * @param params
 */
export const getEarlypaysEtc = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/etc`,
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 그 외 상세 정보를 가져오는 함수입니다.
 * @param params
 * @param type
 */
export const getEarlypaysEtcDetail = async (
  params: SettlementRequests,
  type: EarlypaysEtcCodeTypes,
) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/etc/${type}`,
    params: params,
  });

  return data.items;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 내역을 가져오는 함수입니다.
 * @param params
 */
export const getEarlypaysSummary = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/early-pays/summary`,
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 총 매출 정보를 가져오는 함수입니다.
 * @param params
 */
export const getTotalSales = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/total-sales`,
    params: params,
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 차감금 정보를 가져오는 함수입니다.
 * @param params
 */
export const getUnderDeposits = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/under-deposits`,
    params: params,
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 차감금 요약 정보를 가져오는 api 함수입니다.
 * @param params
 */
export const getUnderDepositsSummary = async (params: SettlementRequests) => {
  const { data } = await instance({
    method: "get",
    url: `/service/settlement/under-deposits/summary`,
    params: params,
  });

  return data.data;
};
