import React from "react";

import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";
import { Outlet } from "react-router-dom";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

const PublicRouteGuard: React.FC = () => {
  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <InitializeServiceRoute>
          <Outlet />
        </InitializeServiceRoute>
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default PublicRouteGuard;
