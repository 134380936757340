import {
  PlatformAccountStatusCode,
  PlatformAccountStatusCodeTypes,
  PlatformCode,
  PlatformSalesStatusCode,
  PlatformSalesStatusCodeTypes,
  checkSameDay,
  getYesterday,
} from "@earlypay/shared";

export const getPlatformTitle = (status: PlatformAccountStatusCodeTypes) => {
  switch (status) {
    case "LOGIN_FAILED":
      return "연결이 끊겼어요";
    case "CONNECTED_SALES_ONLY":
      return "???원";
    case "UNCONNECTED":
      return "???원";
    case "UNREGISTERED":
      return "???원";
    case "INVALID":
      return "연결이 끊겼어요";
    case "SUSPENDED":
      return "???원";
    case "DISABLED":
      return "???원";
    case "POSTPONED":
      return "내일부터 확인이 가능해요";
    default:
      return "수집 중이에요";
  }
};

export const getPlatformImageUrl = (platform: string, isDisabled: boolean) => {
  switch (platform) {
    case PlatformCode.CARD:
      return isDisabled
        ? "/symbol/card-angle-blackandwhite.svg"
        : "/symbol/card-angle.svg";
    case PlatformCode.BAEMIN:
      return isDisabled
        ? "/brand/platform-baemin-blackandwhite.svg"
        : "/brand/platform-baemin.svg";
    case PlatformCode.YOGIYO:
      return isDisabled
        ? "/brand/platform-yogiyo-blackandwhite.svg"
        : "/brand/platform-yogiyo.svg";
    case PlatformCode.COUPANG:
      return isDisabled
        ? "/brand/platform-coupangeats-blackandwhite.svg"
        : "/brand/platform-coupang-eats.svg";
    default:
      return "/brand/platform-baemin.svg";
  }
};

export const getPlatformMessageColor = (
  status: PlatformAccountStatusCodeTypes,
  isActive: boolean,
) => {
  if (isActive) {
    return "content-primary";
  }

  switch (status) {
    case "FULLY_CONNECTED":
      return "content-primary";
    case "DISABLED":
      return "content-primary";
    case "SUSPENDED":
      return "content-primary";
    case "LOGIN_FAILED":
      return "content-negative";
    case "INVALID":
      return "content-negative";
    case "CONNECTED_SALES_ONLY":
      return "content-disabled";
    case "UNCONNECTED":
      return "content-disabled";
    case "UNREGISTERED":
      return "content-disabled";
    default:
      return "content-primary";
  }
};

export const getPlatformStatus = ({
  salesStatus,
  status,
  date,
}: {
  status: PlatformAccountStatusCodeTypes; // 플랫폼 계정 상태
  salesStatus: PlatformSalesStatusCodeTypes; // 플랫폼 매출 수집 상태
  date: Date; // 선택한 날짜
}) => {
  // 비활성화 UI (매출만 가능, 선정산은 중지 | 연동 안됨 | 매출과 선정산 모두 중지)
  // 오늘 기준으로 `어제` 날짜일 때만 무조건 true 반환.
  const isYesterday = checkSameDay(getYesterday(), date);
  const isBlocked =
    (status === PlatformAccountStatusCode.CONNECTED_SALES_ONLY ||
      status === PlatformAccountStatusCode.UNCONNECTED ||
      status === PlatformAccountStatusCode.UNREGISTERED ||
      status === PlatformAccountStatusCode.SUSPENDED ||
      status === PlatformAccountStatusCode.DISABLED) &&
    isYesterday;

  // 로그인 불가
  // 오늘 기준으로 `어제` 날짜일 때만 무조건 true 반환.
  const isLoginFailed =
    status ===
      (PlatformAccountStatusCode.LOGIN_FAILED ||
        PlatformAccountStatusCode.INVALID) && isYesterday;

  // 매출 수집 중
  const isPending = salesStatus === PlatformSalesStatusCode.PENDING;

  // 내일 매출 수집 예정
  const isPostponed =
    !isBlocked && salesStatus === PlatformSalesStatusCode.POSTPONED;

  // 매출 수집 완료
  const isComplete = salesStatus === PlatformSalesStatusCode.COMPLETE;

  // 특정 상태에 해당하지 않고, 집계된 매출 금액을 보여줄 수 있는 경우
  // 오늘 기준으로 `어제` 날짜가 아니면 무조건 true 반환.
  const isActive =
    (status ===
      (PlatformAccountStatusCode.FULLY_CONNECTED ||
        PlatformAccountStatusCode.VALID) &&
      isComplete) ||
    !isYesterday;

  // 플랫폼 연동하러 가기 비활성 여부
  // 배달사를 선택한 유저 중에서 {isBlocked} 상태 중, 계정 상태가 UNREGISTERED 상태여야만 플랫폼 연동이 가능합니다. 그 외의 경우는 고객센터에 문의가 필요합니다.
  // 오늘 기준으로 `어제` 날짜일 때만 무조건 true 반환합니다.
  const isDisableConnectedPlatform =
    (status === PlatformAccountStatusCode.CONNECTED_SALES_ONLY ||
      status === PlatformAccountStatusCode.UNCONNECTED ||
      status === PlatformAccountStatusCode.DISABLED ||
      status === PlatformAccountStatusCode.SUSPENDED) &&
    isYesterday;

  const statusCode = !isYesterday
    ? PlatformAccountStatusCode.VALID
    : isPostponed
    ? "POSTPONED"
    : status;

  return {
    isBlocked,
    isPending,
    isPostponed,
    isLoginFailed,
    isActive,
    isDisableConnectedPlatform,
    statusCode,
  };
};
