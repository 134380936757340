import * as Sentry from "@sentry/react";
import axios from "axios";
import qs from "qs";

import { changeToken } from "@utils/changeToken";
import { deleteCookie, getCookie } from "@utils/cookie";

const instance = axios.create({
  baseURL:
    import.meta.env.VITE_ENV !== "local"
      ? import.meta.env.VITE_EARLY_API
      : "/api",
  timeout: 20000,
  headers: {
    authorization: ``,
    "content-type": "application/json;charset=UTF-8",
  },
  // 배열을 사용하여 각 값을 별도의 파라미터를 직렬화합니다.
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

// axios request interceptors
instance.interceptors.request.use(
  function (req) {
    const config = req;
    /** 사용자 토큰을 헤더에 추가합니다. */
    const userToken = getCookie("earlypay-token");
    config.headers.authorization = userToken && `token ${userToken}`;

    Sentry.setContext("API Request Detail", config);

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  },
);

// axios response interceptors
instance.interceptors.response.use(
  function (response) {
    Sentry.setContext("API Response Detail", response);

    return response;
  },
  async function (error) {
    // 오류 처리 로직 추가
    if (error.response) {
      // 서버가 응답을 보낸 경우
      console.error("API Error:", error.response.data);

      // accessToken 이 만료되거나 로그인 오류가 있을 경우, 로그아웃 후에 로그인 페이지로 돌아갑니다.
      if (
        error.response?.data?.status_code === 401 ||
        error.response?.status === 401
      ) {
        localStorage.clear();
        changeToken("");
        deleteCookie("EARLYPAY_STORE_ID");
        deleteCookie("kakao-token");
        deleteCookie("earlypay-token");
        deleteCookie("users-id");
        return (window.location.href = `${
          import.meta.env.VITE_EARLY_BASE_URL
        }/login`);
      }
    } else if (error.request) {
      // 요청이 전송되었으나 응답이 없는 경우
      console.error("API Error: No response received");
    } else {
      // 다른 오류
      console.error("API Error:", error.message);
    }

    return Promise.reject(error);
  },
);

export { instance };
