import { Billing, Detail, Elist, History, Settlement } from "@pages/settlement";

export const settlementRouters = [
  {
    index: true,
    Component: Settlement,
  },
  {
    path: "billing",
    children: [
      {
        index: true,
        Component: Billing,
      },
      {
        path: "detail",
        children: [
          {
            index: true,
            Component: Detail,
          },
          {
            path: "elist",
            Component: Elist,
          },
        ],
      },
    ],
  },
  {
    path: "history",
    Component: History,
  },
];
