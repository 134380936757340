import React from "react";

import { TERMS_PRIVACY_POLICY } from "@earlypay/shared";

import { ColorsTypes } from "@/@types/ThemesType";

import Icon from "../../atoms/Icon";
import { IconTags } from "../../atoms/Icon/type";
import Text from "../../atoms/Text";
import {
  FooterContentStack,
  FooterInfoStack,
  FooterStack,
  FooterTermStack,
  FooterWrapper,
  TermLink,
} from "./index.styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterStack>
        <FooterContentStack>
          <Icon
            tag={IconTags.EARLYPAY_LOGO}
            width={90}
            height={16}
            color={ColorsTypes.CONTENT_TERTIARY}
          />
          <Text tag="BODY_3" weight="SEMI_BOLD" color="CONTENT_TERTIARY">
            (주)얼리페이
          </Text>
        </FooterContentStack>

        <FooterInfoStack>
          <Text tag={"CAPTION_1"} weight="SEMI_BOLD" color="CONTENT_TERTIARY">
            대표자: 장환성 | 사업자 등록번호: 389-86-02190 | 주소:서울특별시
            마포구 마포대로 122, 12층
          </Text>
          <Text tag="CAPTION_1" weight="SEMI_BOLD" color="CONTENT_TERTIARY">
            고객센터: 1522-6911 | 카카오채널: @earlypay
          </Text>
        </FooterInfoStack>

        <FooterTermStack>
          <TermLink href={`/terms/EP02`}>
            <Text tag="CAPTION_1" underlined color="CONTENT_TERTIARY">
              서비스이용약관
            </Text>
          </TermLink>
          <TermLink href={TERMS_PRIVACY_POLICY}>
            <Text
              tag="CAPTION_1"
              underlined
              color="CONTENT_TERTIARY"
              styles={{ fontWeight: 700 }}
            >
              개인정보처리방침
            </Text>
          </TermLink>
        </FooterTermStack>
      </FooterStack>
    </FooterWrapper>
  );
};

export default Footer;
