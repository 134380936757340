import styled from "styled-components";

export const AccordionOutBox = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  /* padding: 22px 18px; */
  transition: all 0.2s;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ToggleTitle = styled.div`
  width: 100%;
  display: ${(props: { [key: string]: string }) =>
    props._custom ? "block" : "flex"};
  justify-content: space-between;
  align-items: center;
  padding: ${(props: { [key: string]: string }) =>
    props._custom ? "0px" : "0 24px"};
`;

export const ToggleInner = styled.div`
  transition: all 0.1s ease-in-out;
  height: 0px;
  overflow: hidden;
  width: 100%;
`;
