import React from "react";
import styled from "styled-components";

export interface InteractionProps {
  children: React.ReactNode;
  type?: "LIGHT" | "DARK";
  rounded?: "SM" | "MD" | "LG";
  width?: number | string;
  styles?: React.CSSProperties;
}

export const Interaction: React.FC<InteractionProps> = ({
  children,
  type = "LIGHT",
  rounded = "MD",
  styles,
  width,
}) => {
  return (
    <Wrapper type={type} rounded={rounded} width={width} style={{ ...styles }}>
      <InnerWrapper width={width}>{children}</InnerWrapper>
    </Wrapper>
  );
};

export default Interaction;

const Wrapper = styled.div`
  width: ${({ width }: { width: string | number }) =>
    width ? (typeof width === "string" ? width : `${width}px`) : "fit-content"};
  height: fit-content;
  background: transparent;
  border-radius: ${({ rounded }: { rounded: "SM" | "MD" | "LG" }) =>
    rounded === "SM" ? "4px" : rounded === "MD" ? "8px" : "12px"};

  &:hover {
    background: ${({ type }: { type: "LIGHT" | "DARK" }) =>
      type === "LIGHT" ? "rgba(22, 22, 22, 0.04)" : "rgba(22, 22, 22, 0.08)"};
    transition: 0.15s ease-in-out;
  }
  &:active {
    background: ${({ type }: { type: "LIGHT" | "DARK" }) =>
      type === "LIGHT" ? "rgba(22, 22, 22, 0.08)" : "rgba(22, 22, 22, 0.14)"};
    transition: 0.15s ease-in-out;
  }
`;

const InnerWrapper = styled.div`
  display: inline;
  width: ${({ width }: { width: string | number }) =>
    width ? (typeof width === "string" ? width : `${width}px`) : "fit-content"};
  height: auto;

  &:active {
    transform: scale(0.96);
    transition: 0.1s ease-in-out;
  }
`;
