import { getFormattedDateKR } from "@utils/date";
import { isSameYear } from "date-fns";

export const checkSameYear = (date: Date) => {
  return isSameYear(new Date(), date);
};

export const getDateButtonLabel = (selectedDates: Date[]) => {
  const defaultText = "날짜를 선택해주세요";

  if (selectedDates.length === 1) {
    const requiredText = `기간을 선택해주세요`;

    return requiredText;
  } else if (selectedDates.length === 2) {
    const startDate = checkSameYear(selectedDates[0])
      ? getFormattedDateKR(selectedDates[0], 2).slice(4)
      : getFormattedDateKR(selectedDates[0], 2);
    const endDate = checkSameYear(selectedDates[1])
      ? getFormattedDateKR(selectedDates[1], 2).slice(4)
      : getFormattedDateKR(selectedDates[1], 2);

    if (startDate === endDate) {
      const selectedDateText = `${startDate}`;
      return selectedDateText;
    }

    const selectedDateRangeText = `${startDate} ~ ${endDate}`;

    return selectedDateRangeText;
  }

  return defaultText;
};
