import styled, { css } from "styled-components";
import theme from "@styles/theme";

export const TextStyle = css`
  color: ${theme.color.black_00};
  font-weight: ${(props: { [key: string]: string }) => props._bold && "700"};
  font-weight: ${(props: { [key: string]: string }) => props._medium && "500"};
  text-align: ${(props: { [key: string]: string }) =>
    props._center && "center"};
  font-size: ${theme.px[18]};
  line-height: 1.3;
  transition: all 0.15s;
`;

export const DefaultTextStyle = styled.p`
  ${TextStyle}
`;

export const TitleTextStyle = styled.h3`
  ${TextStyle}
  font-size: ${theme.px[22]};
  font-weight: 500;
`;

export const SubTitleTextStyle = styled.p`
  ${TextStyle}
  color:${theme.color.gray_40};
  font-size: ${theme.px[15]};
  font-weight: 400;
  line-height: 22px;
`;
