import React, { Suspense } from "react";

import { Outlet } from "react-router-dom";

import { Box, Container, Spinner } from "@earlybird/ui";

import { BottomNav } from "@components/layouts";

export const MainLayout: React.FC = () => {
  return (
    <Suspense
      fallback={
        <Box width={"100%"} height={"100vh"} center>
          <Spinner />
        </Box>
      }
    >
      <Container>
        <Outlet />
        <BottomNav />
      </Container>
    </Suspense>
  );
};

export default MainLayout;
