import React from "react";

import { ColorsTypes } from "@/@types/ThemesType";

import Button from "../../atoms/Button";
import { ButtonSizes, ButtonTypes } from "../../atoms/Button/type";
import Icon from "../../atoms/Icon";
import { IconTags } from "../../atoms/Icon/type";
import {
  CustomButtonWrapper,
  HeaderWrapper,
  LandingHeader,
  LogoStack,
} from "./index.styled";

export interface LandingHeaderProps {
  handleClickTrialButton: (title?: string, position?: string) => void;
}

const LadingHeader = (props: LandingHeaderProps) => {
  return (
    <LandingHeader>
      <HeaderWrapper>
        <LogoStack>
          <Icon
            className="earlypay-landing-header-logo"
            tag={IconTags.EARLYPAY_LOGO}
            color={ColorsTypes.BG_HIGHLIGHT}
            width={143}
            height={32}
          />
        </LogoStack>
        <CustomButtonWrapper>
          <Button
            className="header-login-button"
            onClick={() => props.handleClickTrialButton("로그인", "헤더")}
            width="auto"
            tag={ButtonTypes.OUTLINED}
            size={ButtonSizes.SM}
          >
            로그인
          </Button>
          <Button
            className="header-trial-button"
            onClick={() =>
              props.handleClickTrialButton("한 달 무료 이용하기", "헤더")
            }
            width="auto"
            size={ButtonSizes.SM}
          >
            한 달 무료 이용하기
          </Button>
        </CustomButtonWrapper>
      </HeaderWrapper>
    </LandingHeader>
  );
};

export default LadingHeader;
