import { typography } from "./typography";
import { lightColors } from "./lightColors";
import { ThemeType } from "@/@types/ThemesType";

const themes: ThemeType = {
  colors: lightColors,
  typography: typography,
};

export default themes;
