import {
  DailyPlatformSalesSummaryParams,
  DailySalesDetailParams,
  DailySalesParams,
  DailySalesSummaryParams,
  SummarySalesParams,
} from "@earlypay/shared";
import { createQueryKeys } from "@lukemorales/query-key-factory";

export const salesQueryKeys = createQueryKeys("sales", {
  fetchPlatformSales: (params: SummarySalesParams) => [
    "fetchPlatformSales",
    params,
  ],
  fetchPlatformSalesSummary: (params: SummarySalesParams) => [
    "fetchPlatformSalesSummary",
    params,
  ],
  fetchDailySales: (params: DailySalesParams) => ["fetchDailySales", params],
  fetchDailyPlatformSales: (params: DailyPlatformSalesSummaryParams) => [
    "fetchDailyPlatformSales",
    params,
  ],
  fetchDailySalesSummary: (params: DailySalesSummaryParams) => [
    "fetchDailySalesSummary",
    params,
  ],
  fetchDailySalesDetail: (params: DailySalesDetailParams) => [
    "fetchDailySalesDetail",
    params,
  ],
});
