import { IconButtonSizes } from "./type";

export const changeSizeToNumber = (size: IconButtonSizes) => {
  switch (size) {
    case IconButtonSizes.XL:
      return 48;
    case IconButtonSizes.LG:
      return 40;
    case IconButtonSizes.MD:
      return 32;
    case IconButtonSizes.SM:
      return 24;
    case IconButtonSizes.XS:
      return 16;
    default:
      return 24;
  }
};
