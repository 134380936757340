import React from "react";
import { Wrapper } from "./index.styled";

interface ContainerProps {
  children: React.ReactNode;
  maxWidth?: number | null;
  height?: number | string | null;
  className?: string | Array<string>;
}

const Container = ({
  children,
  maxWidth,
  height,
  className,
}: ContainerProps) => {
  const windowHeight = window.innerHeight;

  return (
    <Wrapper
      className={[
        ...(Array.isArray(className) ? className : [className]),
        "earlypay-container",
      ].join(" ")}
      maxWidth={maxWidth}
      height={height ? height : windowHeight}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
