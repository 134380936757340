import { changeNumberToAmountKR } from "@earlypay/shared";
import { Text, VStack } from "@ui/components/atoms";
import Skeleton from "react-loading-skeleton";
import { DailyContentsTypes } from "./CalendarDates.types";

export const DailyContents = ({
  type,
  amounts,
  loading,
}: {
  type: DailyContentsTypes;
  amounts?: number[];
  loading: boolean;
}) => {
  if (loading) return <Skeleton width={30} height={12} />;

  if (type === "amount") {
    return (
      <VStack center>
        {
          <Text
            center
            style={{ color: "#4A85FF", fontSize: 12, fontWeight: 500 }}
          >
            {amounts?.length === 1 && changeNumberToAmountKR(amounts[0])}
          </Text>
        }
        {
          <Text
            center
            style={{ color: "#EE4177", fontSize: 12, fontWeight: 500 }}
          >
            {amounts?.length === 2 && changeNumberToAmountKR(amounts[1])}
          </Text>
        }
      </VStack>
    );
  }

  return null;
};

export default DailyContents;
