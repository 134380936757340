import TagManager from "react-gtm-module";

// GoogleTagManager 초기화
export const initGTM = () => {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID,
    dataLayer: {
      userId: "001",
      userProject: "earlyPay",
    },
  };
  TagManager.initialize(tagManagerArgs);
};
