import React from "react";

import {
  PlatformSalesSummaryCode,
  PlatformSalesSummaryNames,
  PlatformSalesSummaryTypes,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import Text from "@components/atoms/Text";
import SettlementHistoryFilter from "@components/features/settlement/SettlementHistoryFilter";
import InfoBottomSheet from "@components/organisms/InfoBottomSheet";
import { InfoTypes } from "@components/organisms/InfoBottomSheet/types";

import { useSettlement } from "@hooks/useSettlement";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

interface SettlementHistoryInfoBoardProps {
  list?: PlatformSalesSummaryTypes;
  isLoading: boolean;
}

export const SettlementHistoryInfoBoard: React.FC<
  SettlementHistoryInfoBoardProps
> = ({ list, isLoading }) => {
  const { getFormattedDateFull } = useSettlement();
  const { typeParams } = useSettlement();
  const date = getFormattedDateFull();

  if (!list || isLoading) {
    return (
      <Wrapper>
        <TitleWrapper>
          <Skeleton containerClassName="flex-1" width={160} height={18} />
          <Skeleton containerClassName="flex-1" width={100} height={26} />
        </TitleWrapper>
        <Divider />
        <InnerWrapper>
          {[1, 2, 3, 4].map(index => (
            <SummaryBox key={index}>
              <Skeleton containerClassName="flex-1" height={18} width={100} />
              <Skeleton containerClassName="flex-1" height={18} width={140} />
            </SummaryBox>
          ))}
        </InnerWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/** 날짜와 총 매출 금액, 플랫폼 필터를 보여줍니다. */}
      <TitleWrapper>
        <Text tag={FontTypes.BODY_2} bold>
          {date}
        </Text>
        <AmountWrapper>
          <Text tag={FontTypes.SUBTITLE_1} bold>
            {changeNumberToMoney(list.totalAmount)}
          </Text>

          {/** filter 파라미터의 값이 "card"일 경우, 카드 필터를 보여줍니다. */}
          {typeParams === "card" && <SettlementHistoryFilter type={"CARD"} />}

          {/** filter 파라미터의 값이 "delivery"일 경우, 배달사 필터를 보여줍니다. */}
          {typeParams === "delivery" && (
            <SettlementHistoryFilter type={"DELIVERY"} />
          )}
        </AmountWrapper>
      </TitleWrapper>
      <Divider />

      {/** 총 매출 | 수수료 | 제외 매출 | 취소 매출 */}
      <InnerWrapper>
        {list.items.map((item, index) => {
          const textColor =
            index === 0
              ? ColorsTypes.CONTENT_SECONDARY
              : ColorsTypes.CONTENT_NEGATIVE;

          return (
            <SummaryBox key={index}>
              <LabelWrapper>
                <Text
                  tag={FontTypes.BODY_2}
                  color={ColorsTypes.CONTENT_TERTIARY}
                >
                  {PlatformSalesSummaryNames[item.type]}
                </Text>
                {item.type === PlatformSalesSummaryCode.EXCLUDE_SALES && (
                  <InfoBottomSheet type={InfoTypes.SETTLEMENT_EXCLUDE_SALES} />
                )}
              </LabelWrapper>
              <Text tag={FontTypes.BODY_2} color={textColor}>
                {changeNumberToMoney(item.amount)}
              </Text>
            </SummaryBox>
          );
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default SettlementHistoryInfoBoard;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 24px 20px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaeaea;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SummaryBox = styled.div`
  display: flex;
  height: 24px;
  justify-content: space-between;
  align-items: center;
`;

const LabelWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const AmountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
