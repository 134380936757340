import React, { Suspense, lazy } from "react";

import styled from "styled-components";

import { Box, Spinner } from "@earlybird/ui";

import { Meta } from "@components/commons";
import SettlementDatePicker from "@components/features/settlement/SettlementDatePicker";

import MainHeader from "@/components/layouts/MainHeader";

const LazyVocEarlypayBoard = lazy(
  () => import("@components/features/feedback/VocEarlypayBoard"),
);
const LazyEarlySettlementBoard = lazy(
  () => import("@components/features/settlement/EarlySettlementBoard"),
);

export const Settlement: React.FC = () => {
  return (
    <>
      <Meta title="얼리페이 | 정산내역" name={"정산내역 요약"} />
      <Container maxWidth={500}>
        <MainHeader />
        <Wrapper>
          <InnerWrapper>
            {/** 정산 내역 날짜 조회 버튼 */}
            <SettlementDatePicker />

            {/** 선정산 요약 정보 */}
            <Suspense
              fallback={
                <Box width={"100%"} height={522} center>
                  <Spinner />
                </Box>
              }
            >
              <LazyEarlySettlementBoard />
            </Suspense>

            {/** 얼리페이 VOC 수집 */}
            <Suspense
              fallback={
                <Box width={"100%"} height={174} center>
                  <Spinner />
                </Box>
              }
            >
              <LazyVocEarlypayBoard />
            </Suspense>
          </InnerWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default Settlement;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  overflow: scroll;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: #f4f4f4;
  padding: 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  gap: 12px;
`;
