import { useEffect, useState } from "react";

import { InfiniteQueryObserverResult } from "@tanstack/react-query";

export interface useInfiniteScrollProps {
  threshold?: number;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult>;
}

export const useInfiniteScroll = ({
  threshold = 0.1,
  hasNextPage,
  fetchNextPage,
}: useInfiniteScrollProps) => {
  const [target, setTarget] = useState<HTMLDivElement | null | undefined>(null);
  const observerCallback: IntersectionObserverCallback = entries => {
    entries.forEach(entry => {
      // target 이 화면에 관찰되고, 다음페이지가 있다면 다음페이지를 호출합니다.
      if (entry.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });
  };

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(observerCallback, {
      threshold,
    });

    observer.observe(target);

    return () => observer.unobserve(target);
  }, [observerCallback, threshold, target]);

  return { setTarget };
};
