import { useLazyImageLoader } from "@earlypay/shared/hooks";
import { getPixelValue } from "@earlypay/shared/src/utils/types";
import { getS3BaseUrl } from "@ui/components/atoms/Image/utils";
import "@ui/styles/index.scss";
import { changeRadiusToClassName } from "@ui/utils/className";
import classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./Image.module.scss";
import { ImageProps } from "./Image.types";

/**
 * `Image` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Image
 *   src="/brand/sample.png"
 *   alt="얼리페이 이미지"
 *   radius="lg"
 *   width={240}
 *   height={240}
 * />
 * ```
 */
export const Image = forwardRef<HTMLElement, ImageProps>(function Image(
  {
    className,
    as,
    src,
    url,
    alt = "",
    width,
    height,
    fill,
    circle,
    radius,
    onLoad,
    sizes,
    onError,
    loading,
    objectFit = "contain",
    placeholder,
    ...rest
  }: ImageProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const WrapperComponent = "div";
  const BaseComponent = as ?? "img";
  const w = getPixelValue(fill ? "100%" : width);
  const h = getPixelValue(fill ? "100%" : height);

  const style = {
    width: w,
    height: h,
  };

  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const ref = useRef(null);
  const isLoaded = useLazyImageLoader(ref);
  const image = isLoaded ? (src ? getS3BaseUrl(src) : url) : null;

  const onLoadImager = () => {
    setPlaceholderVisible(false);
    onLoad && onLoad();
  };

  const onErrorImager = () => {
    setPlaceholderVisible(false);
    onError();
  };

  useLayoutEffect(() => {
    if (image) {
      setPlaceholderVisible(true);
    }
  }, [image]);

  return (
    <WrapperComponent
      ref={ref}
      className={classNames(styles.ImageWrapper, "earlybird-image-wrapper")}
      style={style}
    >
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.Image,
          circle && styles.circle,
          radius && changeRadiusToClassName(radius),
          styles[`object-fit-${objectFit}`],
          image ? styles[`visible`] : styles[`hidden`],
          "earlybird-image",
          className,
        )}
        style={style}
        src={image}
        alt={alt}
        sizes={sizes}
        loading={loading}
        onLoad={onLoadImager}
        onError={onErrorImager}
      ></BaseComponent>
      <div
        className={classNames(
          styles.PlaceholderImage,
          "earlybird-placeholder-image",
          placeholderVisible ? styles[`visible`] : styles[`hidden`],
          radius && changeRadiusToClassName(radius),
        )}
        style={style}
      />
    </WrapperComponent>
  );
});

export default Image;
