import { atom } from "recoil";
import { v1 } from "uuid";

export const appStatusState = atom({
  key: `AppStatusState/${v1()}`,
  default: {
    promptInstall: null,
    isLoggedIn: false,
  },
});
