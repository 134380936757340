import React from "react";
import { PriceWrapper, PriceTitleStack } from "./index.styled";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes, FontWeightTypes } from "@/@types/ThemesType";

const PriceSection = () => {
  return (
    <PriceWrapper>
      <PriceTitleStack>
        <Text
          className="earlypay-landing-title"
          as="h2"
          tag={FontTypes.TITLE_1}
          weight={FontWeightTypes.SEMI_BOLD}
        >
          이 모든 서비스가
        </Text>
        <Text
          className="earlypay-landing-title"
          as="h3"
          tag={FontTypes.TITLE_1}
          weight={FontWeightTypes.SEMI_BOLD}
          color={ColorsTypes.CONTENT_HIGHLIGHT}
        >
          하루 2,000원*
        </Text>
      </PriceTitleStack>
      <Text
        className="earlypay-landing-content-text"
        center
        tag={FontTypes.BODY_2}
        color={ColorsTypes.CONTENT_SECONDARY}
        as="h5"
      >
        선정산 및 매출리포트, 책임보험까지
        <br />이 모든 것을 하루 이천 원에 누려보세요
      </Text>
      <Text
        tag={FontTypes.CAPTION_1}
        weight={FontWeightTypes.LIGHT}
        color={ColorsTypes.CONTENT_TERTIARY}
      >
        *부가세 별도
      </Text>
    </PriceWrapper>
  );
};

export default PriceSection;
